<template>
	<div>
		<!-- 弹窗start -->
		<div id="popupMask">
			<div class="popupContent">
				<div class="modifyTitleStyle">
					用户ID:{{patientID}}
				</div>
				<div class="modifyContentStyle">
					<div class="formbox">
						<div class="formtext">姓名</div>
						<div class="forminputBox"><input type="text" v-model="xm" class="forminput"/></div>
						<div class="formtext">性别:</div>
						<div class="forminputBox"><input type="text" v-model="xb" class="forminput"/></div>
						<div class="formtext">患病类型:</div>
						<div class="forminputBox"><input type="text" v-model="hblx" class="forminput"/></div>
						<div class="formtext">身高:</div>
						<div class="forminputBox"><input type="text" v-model="sg" class="forminput"/></div>
						<div class="formtext">体重:</div>
						<div class="forminputBox"><input type="text" v-model="tz" class="forminput"/></div>
						<div class="formtext">BMI:</div>
						<div class="forminputBox"><input type="text" v-model="bmi2" class="forminput"/></div>
						<div class="formtext">出生日期:</div>
						<div class="forminputBox"><input type="date" v-model="csnyr" class="forminput" style="margin-bottom: 10%;"/></div>
					</div>
					<div class="formbox">
						<div class="formtext">治疗方案:</div>
						<div class="forminputBox"><input type="text" v-model="zlfaxz" class="forminput"/></div>
						<div class="formtext">CGM类型:</div>
						<div class="forminputBox"><input type="text" v-model="cgmlx" class="forminput"/></div>
						<div class="formtext">控制要求:</div>
						<div class="forminputBox"><input type="text" v-model="xtkzyqxz" class="forminput"/></div>
						<div class="formtext">是否饮酒:</div>
						<div class="forminputBox"><input type="text" v-model="sfyj" class="forminput"/></div>
						<div class="formtext">并发症:</div>
						<div class="forminputBox"><input type="text" v-model="bfz" class="forminput"/></div>
						<div class="formtext">电话:</div>
						<div class="forminputBox"><input type="text" v-model="phone" class="forminput"/></div>
						<div class="formtext">确诊日期:</div>
						<div class="forminputBox"><input type="date" v-model="qzrq" class="forminput" style="margin-bottom: 10%;"/></div>
					</div>
				</div>
				<div class="modifyButtonStyle">
					<button @click="modifyData" class="srchbuttonm">确认</button>
					<button @click="closePopup" class="srchbuttonm">返回</button>
				</div>
			</div>
		</div>
		<!-- 弹窗end -->
		<div class="managePageNavbarBanner">
			<div class="navbarManageLeft">
				<input type="button" class="allButton" @click="xlsdatatakeoutAll(page.pageData)" value="全部导出" />
				<input type="button" class="selectedButton" @click="xlsdatatakeoutCheckbox(perPatientInfo)" :value="'导出所选(' + perPatientInfo.length + ')'" />
			</div>
			<div class="navbarManageRight">
				待查询编号：
				<div class="searchStyle">
					<input type="text" v-model="patientID" class="searchInputStyle" placeholder="请输入待查询ID/姓名" @keyup.enter="srchrequest" />
					<input type="button" class="searchButtonStyle" value="搜索" @click="srchrequest"/>
				</div>
			</div>
		</div>
		<div class="managePageArticalBanner">
			<div class="scrollStyle">
				<table v-if="xlsdata" class="tableStyle">
					<tbody>
						<tr class="tableTitleStyle">
							<th style="text-align: center;">全选</th>
							<th>ID</th>           
							<th>姓名</th>
							<th>性别</th>
							<th>年龄</th>
							<th>患病类型</th>
							<th>病程</th>
							<th>身高</th>
							<th>体重</th>
							<th>BMI</th>
							<th>治疗方案</th>
							<th>CGM类型</th>
							<th>控制要求</th>
							<th>是否饮酒</th>
							<th>并发症</th>
							<th>电话</th>
							<th>操作</th>
						</tr>
						<tr class="tableContentStyle"  v-for="(item,index) in xlsdata" :key="index">
							<td style="text-align: center;"><input class="checkboxStyle" type="checkbox" :value="item" v-model="perPatientInfo"/></td>
							<td>{{item.brID}}</td>        
							<td>{{item.xm}}</td>
							<td>{{item.xb}}</td>
							<td>{{item.csnyr}}</td>
							<td>{{item.hblx}}</td>
							<td>{{item.qzrq}}</td> 
							<td>{{item.sg}}</td>
							<td>{{item.tz}}kg</td>
							<td>{{item.bmi}}</td>
							<td>{{item.zlfaxz}}</td>
							<td>{{item.cgmlx}}</td>
							<td>{{item.xtkzyqxz}}</td>
							<td>{{item.sfyj}}</td>
							<td>{{item.bfz}}</td>
							<td>{{item.phone}}</td>
							<td>
								<!-- <button class="buttonStyle" @click="$router.push('/chart/modify/' + item.brID)">修改</button> -->
								<!-- 弹窗start -->
								<button class="buttonStyle" @click="openPopup(item.brID)">修改</button>
								<!-- 弹窗end -->
								<button class="buttonStyle">监测</button>
								<button class="buttonStyle">评估</button>
							</td>
						</tr>
					</tbody>
				</table>
				<h2 style="font-family: 'Times New Roman';padding: 6px 10px;font-weight: 600;color: #504da6;" v-else>Please waiting ...</h2>
			</div>
			<div class="pagenav">
				<div class="pagenavLeft">
					<span>单页显示</span>
					<select class="searchselect" name="pagesize" v-model="singlePage">
					  <option v-for="index in page.pageMax" :key="index" :value="index">{{index}}</option>           
					</select>
					<span>条数据</span>
				</div>
				<div class="pagenavMiddle">
					<ul class="pageul">
						<li class="pageli" @click="pageinFront">
							&laquo;
						</li>
						<li v-for="(pageCurr,index) in pages" :key="index" :class="(pageCurr === page.pageNo ? 'pageliactive' : 'pageli')" @click="pageJump(pageCurr,page.pageData)">
							{{pageCurr}}
						</li>
						<li class="pageli" @click="pageforBehind">
							&raquo;
						</li>
					</ul>
				</div>
				<div class="pagenavRight">
					<input class="servletJumpStyle" type="button" value="跳转" @click="pageJumpTo"/>至第
					<input class="servletStyle" type="number" v-model="page.pageAim" />页
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { request } from '../network/request.js'
	import { export_json_to_excel } from '../vendor/Export2Excel.js'
	export default{
		name:"Manage",
		data(){
			return{
				// 弹窗数据start
				selectedPatiendId:'',
				xm:'',
				password:'',
				xb:'',
				hblx:'',
				sg:'',
				tz:'',
				bmi:'',
				zlfaxz:'',
				cgmlx:'',
				xtkzyqxz:'',
				sfyj:'',
				bfz:'',
				phone:'',
				qzrq:'2020-11-11',
				csnyr:'2020-11-11',
				rcyy:'',
				// 弹窗数据end
				data:2412,
				jsonarr: [{"bldx_ID":1020001,"blxsmc":"变压器档","blzdmc":"byqd","id":3},
						  {"bldx_ID":1020001,"blxsmc":"电压","blzdmc":"byqdy","id":4},
						  {"bldx_ID":1020001,"blxsmc":"变压器油温","blzdmc":"byqyw","id":5},
						  {"bldx_ID":1030001,"blxsmc":"电抗器档","blzdmc":"dkqd","id":6},
						  {"bldx_ID":1030001,"blxsmc":"电抗","blzdmc":"dkqdk","id":7},
						  {"bldx_ID":1030001,"blxsmc":"电抗器油温","blzdmc":"dkqyw","id":8},
						  {"bldx_ID":1010001,"blxsmc":" 分闸次数","blzdmc":"fzcs","id":1},
						  {"bldx_ID":1010001,"blxsmc":"真空开关","blzdmc":"zkkg","id":2}],
				xlsdata:'',
				patientID:'',
				bl_Id:'',
				ysID:'',
				ysName:'',
				// 周浩辉start
				// user:'',
				perPatientInfo:[],
				// 周浩辉end
				singlePage:15,       // 单页的条数->下拉菜单的绑定属性
				page:{
					pageNo:1,      // 页码
					pageTotal:1,   // 总页数
					pageAim:1,     // 直接跳转页码
					rows:'',       // 数据总条数，在数据查找函数中赋值
					pageSize:15,   // 每页展示数据条数
					pageData:'',   // 整个页面的数据
					pageMax:50     // 可设置的每页最多数据条数
				}

			}
		},
		computed:{
			// 弹窗数据start
			bmi2: function () {
				if(this.bmi !== ''){
					return this.bmi
				}else{
					var data = this.tz / ((this.sg/100)*(this.sg/100))
					data = JSON.stringify(data)
					var result = '';
					for (var i = 0; i < data.length; i++) {
					  if (data[i] === '.') {
						result = result + data[i];
						result = result + data[i + 1];
						return result;
					  } else {
						result = result + data[i]
					  }
					}
					return result;		
				}
			},
			// 弹窗数据end
			pages: function () {
				// return function(pageNo){
					if(this.page.pageTotal <= 11){
						var pagesArr = []
						for(var i=0;i<this.page.pageTotal;i++){
							pagesArr[i] = i+1;
						}
						return pagesArr;
					}
					else{
						if((6<=this.page.pageNo) && (this.page.pageNo<=this.page.pageTotal-5)){
							return [1,'...',this.page.pageNo-3,this.page.pageNo-2,this.page.pageNo-1,this.page.pageNo,this.page.pageNo+1,this.page.pageNo+2,this.page.pageNo+3,'...',this.page.pageTotal];       
						}
						// else if((this.page.pageNo<=6) && (this.page.pageNo>=this.page.pageTotal-5)){       //该条件适用于total小于12的情况，若total大于等于12则无需使用该条件判断，（ 6 + total至total-5 = 12个）
						// 	return [1,'...',this.page.pageNo-1,this.page.pageNo,this.page.pageNo+1,this.page.pageNo+2,'...',this.page.pageTotal]
						// }
						else if((this.page.pageNo<6) && (this.page.pageNo<this.page.pageTotal-5)){
							return [1,2,3,4,5,6,'...',this.page.pageTotal-3,this.page.pageTotal-2,this.page.pageTotal-1,this.page.pageTotal];
						}
						else if((this.page.pageNo>this.page.pageTotal-5) && (this.page.pageNo>6)){
							return [1,2,3,'...',this.page.pageTotal-5,this.page.pageTotal-4,this.page.pageTotal-3,this.page.pageTotal-2,this.page.pageTotal-1,this.page.pageTotal];
						}
					}
					// 需注意避免出现某种情况使得点击‘。。。’旁的按钮时，页码按钮不更新（无法跳出当前条件导致return的内容不变）
					// 临界条件：6和this.page.pageTotal至this.page.pageTotal-5的个数的和（临界条件前后个数的和）要大于等于能够进入该条件的this.page.pageTotal值
				// }
			}
		},
		watch: {
		  // 如果 `pagesize` 发生改变，这个函数就会运行
		  singlePage: function (newVal,oldVal) {
		    // console.log(this.patientID)
			// this.gettimeStapbyId();
			this.page.pageSize = this.singlePage;
			this.pageJump(1,this.page.pageData);
		  }
		},
		methods:{
			// 弹窗start
			//吴佳琪2021-5-8添加，令出生年月日与确诊日期进行显示。
			dateFormat(originaltime){
			  const date = new Date(originaltime)
			     var Y = date.getFullYear() + '-';
			     var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
				 var D2 = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
				 return (Y + M + D2)
			},
			patientdatarequest(){
				if(this.selectedPatiendId){
					let response = ''
					request({
						url:'/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/' + this.selectedPatiendId,/* 修改新的接口框架，并将接口的数据地址改进 2021-4-16 吴佳琪*/
						method:'get'
					}, res => {
							// console.log(res.data.qzrq)
							// console.log(res.data.csnyr)
							this.xm = res.data.data.res.xm
							this.xb = res.data.data.res.xb
							this.hblx = res.data.data.res.hblx
							this.sg = res.data.data.res.sg
							this.tz = res.data.data.res.tz
							// this.bmi = res.data.bmi
							this.zlfaxz = res.data.data.res.zlfaxz
							this.cgmlx = res.data.data.res.cgmlx
							this.xtkzyqxz = res.data.data.res.xtkzyqxz
							this.sfyj = res.data.data.res.sfyj
							this.bfz = res.data.data.res.bfz
							this.phone = res.data.data.res.phone
							this.qzrq=this.dateFormat(res.data.data.res.qzrq)
							this.csnyr= this.dateFormat(res.data.data.res.csnyr)//吴佳琪2021-5-8 修改，进行年月日的修改，去除时分秒。
							this.rcyy = res.data.data.res.rcyy 
							this.password = res.data.data.res.passWord
							// for(var i=0;i<res.data.length;i++){
							// 	res.data[i].csnyr = this.timestap_ageCac(this.xlsdata[i].csnyr)
							// 	res.data[i].qzrq = this.timestap_bcCac(this.xlsdata[i].qzrq)
							// }
			
					},err => {
						console.log(err)
					})
					// this.res = response
					// this.span = this.res.zzsj
				}
				else{
					// console.log('未成功获取到病人ID')
				}
			},
			modifyData(){
				const url = '/xuetangjiance/xuetangjiance/sys-patient-user/updatagrxx'/*  修改新的接口框架，并将接口的数据地址改进 2021-4-16 吴佳琪*/
				// console.log(url)
				let chushengrq = new Date(this.csnyr)
				chushengrq = new Date(chushengrq)
				
				let quezhenrq = new Date(this.qzrq)
				quezhenrq = new Date(quezhenrq)
				
				let postdata = {
					"brID":this.selectedPatiendId,
					"xm": this.xm,
					"passWord":this.password,
					"phone":this.phone,
					"csnyr":chushengrq.getTime(),
					"sg":this.sg,
					"tz":this.tz,
					"bmi":this.bmi2,
					"xb":this.xb,
					"hblx":this.hblx,
					"cgmlx":this.cgmlx,
					"qzrq":quezhenrq.getTime(),
					"zlfaxz":this.zlfaxz,
					"xtkzyqxz":this.xtkzyqxz,
					"sfyj": this.sfyj,
					"bfz": this.bfz,
					"rcyy": this.rcyy,
					//新加的变量
					 "jtzl": "否",
					 "zlfasfbh": "否",
				}
				// console.log('ppp')
				axios({
				       url,
				       method : 'post',
				       ContentType : 'application/json',
				       data : postdata
				    }).then(response => {
						// console.log(response.data)
						if(response.data.success === true){   /* 此处的获取值进行书写*/
							alert('修改成功，可前往用户查询页面查看')
						}
				    }).catch(error => {
				      // console.log(error)
				    });
			},
			openPopup(e) {
				// console.log('打开',e);
				this.selectedPatiendId = e;
				this.patientdatarequest();
				// console.log(document.getElementsByClassName('box'));
				// document.getElementsByClassName('box')[0].style.display = 'block';
				document.getElementById('popupMask').style.display = 'block';
				
				let m = function(e){e.preventDefault();};
				document.body.style.overflow='hidden';
				document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
			},
			closePopup() {
				// console.log('关闭');
				document.getElementById('popupMask').style.display = 'none';
				
				let n =function(e){e.preventDefault();};
				document.body.style.overflow='';//出现滚动条
				document.removeEventListener("touchmove",n,{ passive:true });
			},
			// 弹窗end
			consoledata(){
				// console.log(this.data)
			},
			xlsdatarequest(){
				this.xlsdata = '';
				let response = ''
				request({
					// 周浩辉start
					// http://182.92.104.159:8080/xuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/{doctorID}
					// url:'/xuetangjiance/cxbrxxbydoctorid/' + this.ysID,
					url:'/xuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/' + this.ysID,
					// 周浩辉end
					method:'get'
				}, res => {
					// console.log(res)
					// 周浩辉start
					// // this.xlsdata = res.data
					// for(var i=0;i<res.data.length;i++){
					// 	res.data[i].csnyr = this.timestap_ageCac(res.data[i].csnyr)
					// 	res.data[i].qzrq = this.timestap_bcCac(res.data[i].qzrq)
					// }
					// this.page.rows = res.data.length
					// this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
					// // console.log(res.data)
					// this.page.pageData = res.data
					// this.pageJump(1 , res.data)
					
					const selectallpatient = res.data.data.res
					for(var i=0;i<selectallpatient.length;i++){// 吴佳琪修改，将获得的数据长度地址改动，递进错误2021-5-8
						selectallpatient[i].csnyr = this.timestap_ageCac(selectallpatient[i].csnyr)
						selectallpatient[i].qzrq = this.timestap_bcCac(selectallpatient[i].qzrq)
					}
					this.page.rows = selectallpatient.length
					this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
					// console.log(res.data)
					this.page.pageData = selectallpatient
					this.pageJump(1 , selectallpatient)
					// 周浩辉end
				},err => {
					console.log(err)
				})
				// this.res = response
				// this.span = this.res.zzsj
			},
			srchrequest(){
				this.xlsdata = '';
				if(this.patientID){
					let response = ''
					request({
						// 周浩辉start
						// http://182.92.104.159:8080/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxxwithdIDandpID/10001/20001
						// url:'/xuetangjiance/cxbrxxbydoctoridandpatientid/' + this.ysID + '/' + this.patientID,
						url:'/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxxwithdIDandpID/' + this.ysID + '/' + this.patientID,
						// 周浩辉end
						method:'get'
					}, res => {
						// console.log(res)
						
						// 周浩辉start
						// if(res.data.length){
						// 	this.xlsdata = ''
						// 	// this.xlsdata = res.data
						// 	this.page.pageData = res.data
						// 	this.page.rows = res.data.length
						// 	this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
						// 	for(var i=0;i<res.data.length;i++){
						// 		res.data[i].csnyr = this.timestap_ageCac(res.data[i].csnyr)
						// 		res.data[i].qzrq = this.timestap_bcCac(res.data[i].qzrq)
						// 	}
						// 	this.pageJump(1 , res.data)
						// }
						// else{
						// 	alert("患者ID或姓名输入有误，请检查后重新输入")
						// }
						const srchrequest = res.data.data.res
						if(srchrequest.length){
							this.xlsdata = ''
							// this.xlsdata = res.data
							this.page.pageData = srchrequest
							this.page.rows = srchrequest.length
							this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
							for(var i=0;i<srchrequest.length;i++){
								srchrequest[i].csnyr = this.timestap_ageCac(srchrequest[i].csnyr)
								srchrequest[i].qzrq = this.timestap_bcCac(srchrequest[i].qzrq)
							}
							this.pageJump(1 , srchrequest)
						}
						else{
							alert("患者ID或姓名输入有误，请检查后重新输入")
						}
						// 周浩辉end
					},err => {
						console.log(err)
					})
					// this.res = response
					// this.span = this.res.zzsj
				}
				else{
					// alert("患者ID不能为空")
					this.xlsdatarequest()
				}

			},
			pageJump(index,data){
				if(index === '...'){
					return ;
				}
				this.page.pageNo = index
				this.xlsdata = []
				this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
				var tdata = data || ""
				var xlsdata = []
				var begin = (index - 1) * this.page.pageSize
				var lastpageSize = this.page.rows % this.page.pageSize                                 // 取余函数
				if(index === this.page.pageTotal){
					if(lastpageSize === 0){
						for(var i=0;i<this.page.pageSize;i++){
							xlsdata[i] = tdata[begin+i]
							
							// xlsdata[i].csnyr = this.timestap_ageCac(xlsdata[i].csnyr)
							// xlsdata[i].qzrq = this.timestap_bcCac(xlsdata[i].qzrq)
						}
						this.xlsdata = xlsdata
					}
					else{
						for(var j=0;j<lastpageSize;j++){
							xlsdata[j] = tdata[begin+j]
							
							// xlsdata[j].csnyr = this.timestap_ageCac(xlsdata[j].csnyr)
							// xlsdata[j].qzrq = this.timestap_bcCac(xlsdata[j].qzrq)
						}
						this.xlsdata = xlsdata
						// console.log(this.xlsdata)
					}
				}
				else{
					for(var k=0;k<this.page.pageSize;k++){
						xlsdata[k] = tdata[begin+k]
						
						// xlsdata[k].csnyr = this.timestap_ageCac(xlsdata[k].csnyr)
						// xlsdata[k].qzrq = this.timestap_bcCac(xlsdata[k].qzrq)
					}
					this.xlsdata = xlsdata
				}
			},
			pageJumpTo(){
				if(this.page.pageAim % 1 !== 0){
					alert("输入的页码不符合规范")
				}
				else if(this.page.pageAim>=1 && this.page.pageAim<=this.page.pageTotal){
					this.page.pageNo = this.page.pageAim
					this.page.pageNo = this.page.pageNo - 1 + 1
					this.pageJump(this.page.pageNo,this.page.pageData);
				}
				else{
					alert("页码不在范围内")
				}
			},
			pageinFront(){
				if(this.page.pageNo === 1){
					alert('当前页为首页')
					return
				}
				else{
					this.page.pageNo--
					var pageNo = this.page.pageNo
					this.pageJump(pageNo,this.page.pageData)
				}
			},
			pageforBehind(){
				if(this.page.pageNo === this.page.pageTotal){
					alert('当前页为尾页')
					return
				}
				else{
					this.page.pageNo++
					var pageNo = this.page.pageNo
					this.pageJump(pageNo,this.page.pageData)
				}
			},
			timestap_ageCac(originaltime) {
			  const date1 = new Date(originaltime)
			  const date2 = new Date()
			  var Y1 = date1.getFullYear();
			  var M1 = date1.getMonth() + 1 
			  var Y2 = date2.getFullYear();
			  var M2 = date2.getMonth() + 1 
			
			  return (Y2 - Y1)  
			},
			timestap_bcCac(originaltime) {
			  const date1 = new Date(originaltime)
			  const date2 = new Date()
			  var Y1 = date1.getFullYear();
			  var M1 = date1.getMonth() + 1 
			  var Y2 = date2.getFullYear();
			  var M2 = date2.getMonth() + 1 
			  var y = Y2 - Y1
			  var m = M2 - M1
			  if(m<0){
				  m = 12 + m
				  y--
				  if(y===0){
					return(m + '个月')  
				  }
				  return(y + '年' + m + '个月')
			  }
			  else{
				  if(y===0){ 
					if(m===0){
						return('不足一个月')
					}
					return(m + '个月')
				  }
				  else{
					  if(m===0){
						  return(y + '年')
					  }
					  return(y + '年' + m + '个月')
				  }
			  } 
			},
			// 周浩辉start
			// xlsdatatakeout(data){
			// 	if(this.xlsdata && this.page.pageData){
			// 		this.export2Excel(data);
			// 	}
			// 	else{
			// 		alert('当前没有数据')
			// 	}
			// },
			// 导出全部
			xlsdatatakeoutAll(data){
				if(this.page.pageData.length !== 0){
					this.export2Excel(data);
				}
				else{
					alert('当前没有数据')
				}
			},
			// 导出所选
			xlsdatatakeoutCheckbox(data){
				if(this.page.pageData !== 0){
					if(this.perPatientInfo.length !== 0) {
						this.export2Excel(data);
					} else {
						alert('请选择病人')
					}
				}
				else{
					alert('当前没有数据')
				}
			},
			// 周浩辉end
			export2Excel(listdata) {
				require.ensure([], () => {
					const tHeader = ['ID', '姓名', '性别','年龄','患病类型','病程','身高','体重','BMI','治疗方案','CGM类型','控制要求','是否饮酒','并发症','日常用药','电话'] // 对应表格输出的中文title
					const filterVal = ['brID','xm','xb','csnyr','hblx','qzrq','sg','tz','bmi','zlfaxz','cgmlx','xtkzyqxz','sfyj','bfz','rcyy','phone'] // 对应表格输出的数据
					// this.days.forEach(val => {
					// tHeader.push(val.name)
					// filterVal.push(val.value)
					// })
					const list = listdata // 表格data
					const data = this.formatJson(filterVal, list)
					export_json_to_excel(tHeader, data, '数据表') // 对应下载文件的名字
				})
			},
			exportData(){
				const { export_json_to_excel } = require('../vendor/Export2Excel');
				const tHeader = ['列1', '列2', '列3','列4'];
				const filterVal = ['bldx_ID','blxsmc','blzdmc','id'];
				const list = this.jsonarr;
				const data = this.formatJson(filterVal, list);
				export_json_to_excel(tHeader, data, 'fileName');
			},
		 
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => v[j]))
			},
			requestDoctorId(){
			
					this.bl_Id = this.getCookie("bl_Id")
					this.ysID = this.getCookie("ysID")
					this.ysName = this.getCookie("ysName")
			
			},
			getCookie(cname){
			  var name = cname + "=";
			  var ca = document.cookie.split(';');
			  for(var i=0; i<ca.length; i++)
			  {
			    var c = ca[i].trim();
			    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
			  }
			  return "";
			}
		},
		mounted() {
			// this.consoledata();
			this.requestDoctorId();
			this.xlsdatarequest();
		}
	}
</script>

<style scoped="scoped" lang="less">
	// 弹窗start
	#popupMask {
		display: none;
		position: fixed;
		top: 60px;
		left: 220px;
		right: 0;
		bottom: 0;
		background-color: rgba(185, 185, 185, 0.8);
		z-index: 99;
		.popupContent {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 80%;
			height: 500px;
			// margin: 110px auto;
			background-color: #FFFFFF;
			border: 1px solid #b9b9b9;
			overflow-y: auto;
			.modifyTitleStyle {
				width: 100%;
				padding: 57px 0 39px 0;
				text-align: center;
				font-size: 36px;
				font-weight: bold;
				color: #504DA6;
			}
			.modifyContentStyle {
				text-align: center;
				.formbox{
					display: inline-block;
					width: 40%;
					text-align: left;
					font-size: 20px;
					color: #35384D;
					border: 1px solid #504DA6;
					box-sizing: border-box;
					&:nth-of-type(1) {
						border-right: none;
					}
					.formtext {
						height: 79px;
						padding-left: 27px;
						line-height: 79px;
						background-color: #EDEDF6;
					}
					.forminputBox{
						position: relative;
						height: 79px;
						background-color: #FAFAFA;
						.forminput {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translateX(-50%) translateY(-50%);
							width: 85%;
							height: 50px;
							padding-left: 15px;
							border: 1px solid #504DA6;
							border-radius: 8px;
						}
					}
				}
			}
			.modifyButtonStyle {
				margin-top: 29px;
				text-align: center;
				.srchbuttonm{
					width: 167px;
					height: 40px;
					margin-left: 29px;
					margin-bottom: 75px;
					background-color: #504da6;
					font-size: 20px;
					color: #FAFAFA;
					border: 1px solid #707070;
					border-radius: 17px;
					cursor: pointer;
					&:nth-of-type(2) {
						background-color: #FFFFFF;
						color: #35384D;
					}
				}
			}
		}
	}
	// 弹窗end
	.managePageNavbarBanner {
		position: relative;
		width: 95%;
		height: 60px;
		margin: 90px auto 30px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		.navbarManageLeft {
			position: absolute;
			left: 20px;
			top: 15px;
			.allButton, .selectedButton {
				padding: 0 10px;
				height: 30px;
				margin-left: 20px;
				background: rgba(245, 245, 250, 0.4);
				font-size: 14px;
				color: #8181A5;
				border: 1px solid #504DA6;
				border-radius: 4px;
				cursor: pointer;
			}
			
		}
		.navbarManageRight{
			position: absolute;
			right: 20px;
			top: 15px;
			font-size: 16px;
			color: #707070;
			.searchStyle {
				display: inline-block;
				box-sizing: border-box;
				border-radius: 15px;
				background-color: #EFF0F2;
				&::before {
					font-family: 'iconfont';
					content: '\e6d6';
					margin: 0 15px;
					color: #747678;
				}
				.searchInputStyle {
					width: 200px;
					height: 30px;
					border: none;
					background-color: #EFF0F2;
				}
				.searchButtonStyle {
					width: 70px;
					height: 30px;
					background: #504DA6;
					border: 1px solid #504DA6;
					border-radius: 0px 15px 15px 0px;
					font-size: 14px;
					color: #FFFFFF;
					cursor: pointer;
				}
			}
		}
	}
	
	.managePageArticalBanner {
		width: 95%;
		// height: 550px;
		margin: 0 auto;
		padding: 10px 16px 10px 30px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;
		.scrollStyle {
			height: 400px;
			padding-right: 14px;
			overflow-y: auto;
			overflow-x: hidden;
			/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/  
			&::-webkit-scrollbar  {
				width: 8px;
			}  
			/*定义滚动条轨道 内阴影+圆角*/  
			&::-webkit-scrollbar-track {
			    border-radius: 3px;  
			    background-color: #F2F3F3;  
			}  
			/*定义滑块 内阴影+圆角*/  
			&::-webkit-scrollbar-thumb {  
			    border-radius: 3px;
			    background-color: #504DA6;  
			}
			.tableStyle {
				width: 100%;
				.tableTitleStyle {
					height: 60px;
					border-bottom: 2px solid #F2F2F2;
					th {
						text-align: left;
						font-size: 14px;
						color: #9E9EA8;
					}
				}
				.tableContentStyle {
					height: 60px;
					color: #35384D;
					border-bottom: 2px solid #F2F2F2;
					&:nth-of-type(2n+1) {
						background-color: #f7f7fb;
					}
					.checkboxStyle  {
						width: 18px;
						height: 18px;
						-webkit-appearance:none;
						border: 1px solid #504DA6;
						border-radius: 4px;
						outline: none;
						cursor: pointer;
						&:checked {
							background-color: #504DA6;
						}
						&:checked::before {
							font-family: 'iconfont';
							content: '\e65d';
							color: #FFFFFF;
							font-size: 18px;
						}
					}
					.buttonStyle {
						padding: 2px 6px;
						background: rgba(220, 219, 237, 0.5);
						font-size: 14px;
						color: #8181A5;
						border: 1px solid #504DA6;
						border-radius: 4px;
						cursor: pointer;
						&:nth-of-type(2) {
							margin: 0 4px;
						}
					}
				}
			}
		}
		.pagenav {
			width: 100%;
			margin: 60px 0 50px 0;
			font-size: 14px;
			color: #707070;
			.pagenavLeft {
				display: inline-block;
				width: 20%;
				.searchselect {
					width: 60px;
					margin: 0 10px;
					padding-left: 8px;
					background: #F5F5FA;
					border: 1px solid #504DA6;
					border-radius: 4px;
					color: #8181A5;
					font-weight: normal;
				}
			}
			.pagenavMiddle {
				display: inline-block;
				width: 60%;
				.pageul {
					display: flex;
					justify-content: center;
					cursor: pointer;
					.pageli, .pageliactive {
						// display: inline-block;
						padding: 5px 9px;
						background-color: #FFFFFF;
						border: none;
						text-align: center;
						color: #1C1D21;
						border-radius: 6px;
					}
					.pageli:hover{
						background-color: #efeeef;
					}
					.pageliactive {
						background-color: #504DA6;
						color: #FFFFFF;
						border-radius: 6px;
					}
				}
			}
			.pagenavRight {
				display: inline-block;
				width: 20%;
				.servletJumpStyle {
					width: 44px;
					height: 24px;
					background-color: #ededf6;
					border: 1px solid #504DA6;
					border-radius: 4px;
					color: #707070;
					cursor: pointer;
				}
				.servletStyle {
					width: 44px;
					height: 24px;
					padding-left: 10px;
					background: #F5F5FA;
					border: 1px solid #504DA6;
					border-radius: 4px;
					color: #8181A5;
					box-sizing: border-box;
				}
			}
		}
	}
</style>

export const baseRoleList = [
	{
		id: '1',
		icon: 'cgmIcon',
		path: "/chart/check",
		label: "数据质量分析",
		child:[],
		roleName:'超级管理员,医生'
	},
	
	{
		id: '2',
		icon: 'searchIcon',
		path: "/chart/precisesrch",
		label: "患者信息管理",
		child:[],
		roleName:'超级管理员,医生'
		
	},

	// {
	// 	id: '3',
	// 	icon: 'searchIcon',
	// 	path: "/chart/doctorManage",
	// 	label: "医生管理",
	// 	child:[],
	// 	roleName:'超级管理员'
	// },
	// {
	// 	id: '4',
	// 	icon: 'cgmIcon',
	// 	path: "/chart/sysDictionary",
	// 	label: "部门管理",
	// 	child:[],
	// 	roleName:'超级管理员'
	// },
]

export const UseState = [
	{
		id: '0',
		label: "启用",
		color:'green',
	},
	{
		id: '1',
		label: "禁用",
		color:'red'
	}
]
export const isDrink = [
	{
		id: '是',
		label: "是",
		color:'red',
	},
	{
		id: '否',
		label: "否",
		color:'green'
	}
]
export const SexOption = [
	{
		id: '1',
		label: "男",
		color:'green',
	},
	{
		id: '2',
		label: "女",
		color:'red'
	}
]

// 降糖类型
export const diseaseTypeOption = [
	{
		id: '1',
		label: "1型糖尿病",
		color:'green',
	},
	{
		id: '2',
		label: "2型糖尿病",
		color:'red'
	},
	{
		id: '3',
		label: "特殊类型",
		color:'red'
	}
]

// 月份
export const MonthInfo = [
	{
		id: '01',
		label: "一月",
	},
	{
		id: '02',
		label: "二月",
	},
	{
		id: '03',
		label: "三月",
	},
	{
		id: '04',
		label: "四月",
	},
	{
		id: '05',
		label: "五月",
	},
	{
		id: '06',
		label: "六月",
	},
	{
		id: '07',
		label: "七月",
	},
	{
		id: '08',
		label: "八月",
	},
	{
		id: '09',
		label: "九月",
	},
	{
		id: '10',
		label: "十月",
	},
	{
		id: '11',
		label: "十一月",
	},
	{
		id: '12',
		label: "十二月",
	}
]

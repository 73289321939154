
// import { mapGetters } from "vuex";


export const pageMixin = {
	//  Mixin(混入)将组件的公共逻辑或者配置提取出来，哪个组件需要用到时，
	// 直接将提取的这部分混入到组件内部即可。
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,
      loading: false,
      id: "",
      disabled: true,
      tableData: [],
      ids: [],
      code: "",
      dataforExport: [],
      fileName: "",
    }
  },
  computed: {
	// 计算属性是写在实例的computed选项中的。computed计算属性，本质是属性，不是函数。
	// 计算属性中也可以进行一些更加复杂的操作;计算属性会进行缓存，如果多次使用时，计算属性只会调用一次。
    // ...mapGetters(["btn_list"]),
    deleteDisabled() {
      return this.ids.length === 0;
    },
  },
  methods: {
    // val 为选中数据的集合?,ids?
    handleSelectionChange(value) {
      if (value.length !== 1) {
        this.disabled = true;
      } else {
        this.disabled = false;
        this.id = value[0].id;
        this.code = value[0].code;
      }
      this.ids = value
      this.dataforExport = value
    },
    async handleCurrentChange(value) {
      this.currentPage = value;
      await this.init();   //lhh
    },
    async handleSizeChange(value) {
      this.pageSize = value;
	  this.currentPage = 1;    //lhh
      await this.init();
    },
   //  async search() {
   //    this.currentPage = 1;
   //    await this.init();
	  // // await 操作符用于等待一个Promise 对象。它只能在异步函数 async function 中使用。
	  // // 返回 Promise 对象的处理结果。如果等待的不是 Promise 对象，则返回该值本身。
	  // // await 表达式会暂停整个 async 函数的执行进程并出让其控制权，只有当其等待的基于 promise 的
	  // //异步操作被兑现或被拒绝之后才会恢复进程。promise 的解决值会被当作该 await 表达式的返回值。
   //  },
   
  }
}

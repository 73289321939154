<template>
  <div id="chec" ref="checc">
    <div class="cgmCheckBox">
      <div class="cgmCheckBoxLeft">
        <div class="personalMessageBanner">
          <div class="personalMessageItem"><span class="itemTitle">姓名 : </span>{{userNameParase}}
          </div>
          <div class="personalMessageItem"><span class="itemTitle">ID : </span>{{selectedPatientObj.brID}}
          </div>
          <div class="personalMessageItem"><span class="itemTitle">年龄 : </span>{{selectedPatientObj.nl}}
          </div>
          <div class="personalMessageItem"><span class="itemTitle">性别 : </span>{{selectedPatientObj.xb}}
          </div>
		  <!-- <div class="personalMessageItem"><span class="itemTitle">性别 : </span>
		  <span v-if="selectedPatientObj.xb==1">男</span>
		  <span v-if="selectedPatientObj.xb==2">女</span>
		  </div> -->
          <div class="personalMessageItem"><span class="itemTitle">类型 : </span>{{selectedPatientObj.hblx}}
          </div>
          <!-- <div class="personalMessageItem"><span class="itemTitle">病程 : </span>{{selectedPatientObj.courseOfDisease}}
          </div> -->
        </div>
        <div class="cgmChartBanner">
          <div class="timeSelected">
            <span :class="activeclass[0]" @click="reqChange(12)">12</span>
            <span :class="activeclass[1]" @click="reqChange(24)">24</span>
            <span :class="activeclass[2]" @click="reqChange(48)">48</span>
          </div>
          <div id="checkchart" class="checkcharts"></div>
        </div>
        <div class="cgmResult">
          <div class="dataQualityBanner">
            <h2 class="dataQualityTitle">数据质量情况</h2>
            <div class="dataQualityContent">其中信号缺失停滞<span>{{dataQuality[1]}}</span></div>
            <div class="dataQualityContent">数据质量<span>{{dataQuality[2]}}</span></div>
            <!-- <div class="dataQualityChart">检测正确率{{dataQuality[0]}}</div> -->
            <div class="dataQualityChart">
              <el-progress type="circle" :percentage="dataQuality[0]" :stroke-width="13" color="#504DA6"
                           stroke-linecap="square">
                <template #default="{ percentage }">
                  <span class="percentage-value">{{ percentage }}</span>
                  <div class="percentage-label">检测正确率</div>
                </template>
              </el-progress>
            </div>
            <div class="tableBox">
              <table class="dataQualityAssessTable">
                <tr>
                  <th>正确率</th>
                  <th>数据质量</th>
                </tr>
                <tr>
                  <td> 高于90% </td>
                  <td>数据质量较好</td>
                </tr>
                <tr>
                  <td>70%-90%</td>
                  <td>数据质量一般</td>
                </tr>
                <tr>
                  <td> 低于70% </td>
                  <td>数据质量较差</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="cgmUseAdviceBanner">
            <h2 class="cgmUseAdviceTitle">数据分析</h2>
            <!-- <div class="dataQualityAssessBanner">
              <h2 class="dataQualityAssessTitle">CGM使用建议</h2> -->
            <!-- 2023.1.9王昕卓添加表格 -->
            <!-- <div class="cgmUseAdviceContent" v-for="(item,index) in suggestion" :key="index">
              {{item}}
            </div> -->
            <div class="tableBox">
              <table class="dataQualityAssessTable">
                <tr>
                  <th>血糖控制指标</th>
                  <th>结果</th>
                  <th>日间结果</th>
                  <th>夜间结果</th>
                </tr>
                <tr>
                  <td>Number of hypoglycemia(低血糖事件次数)</td>
                  <td>{{HypoCount}}</td>
                  <td>{{dayHypoCount}}</td>
                  <td>{{nightHypoCount}}</td>
                </tr>
                <tr>
                  <td>TBR(<3.9mmol/L)</td>
                  <td>{{TBR*100}}%</td>
                  <td>{{dayTBR*100}}%</td>
                  <td>{{nightTBR*100}}%</td>
                  <!-- <td>A%</td> -->
                </tr>
                <tr>
                  <td>TIR(3.9～10.0 mmol/L)</td>
                  <td>{{TIR*100}}%</td>
                  <td>{{dayTIR*100}}%</td>
                  <td>{{nightTIR*100}}%</td>
                </tr>
                <tr>
                  <td>TAR(>10.0mmol/L)</td>
                  <td>{{TAR*100}}%</td>
                  <td>{{dayTAR*100}}%</td>
                  <td>{{nightTAR*100}}%</td>
                </tr>
                <tr>
                  <td>GMI(血糖管理指标)</td>
                  <td>{{GMI}}%</td>
                  <td>{{dayGMI}}%</td>
                  <td>{{nightGMI}}%</td>
                </tr>
                <tr>
                  <td>MSG(平均葡萄糖水平)</td>
                  <td>{{MSG}}mmol/L</td>
                  <td>{{dayMSG}}mmol/L</td>
                  <td>{{nightMSG}}mmol/L</td>
                </tr>
                <tr>
                  <td>CV(血糖变异系数)</td>
                  <td>{{CV*100}}%</td>
                  <td>{{dayCV*100}}%</td>
                  <td>{{nightCV*100}}%</td>
                </tr>
                <tr>
                  <td>SD(葡萄糖标准差)</td>
                  <td>{{SD}}mmol/L</td>
                  <td>{{daySD}}mmol/L</td>
                  <td>{{nightSD}}mmol/L</td>
                </tr>
              </table>
            </div>
          </div>
          <!--					<div class="dataQualityAssessBanner">
                      <h2 class="dataQualityAssessTitle">数据质量评价规则</h2>
                      <br>
                    </div>-->
        </div>
      </div>
      <div class="cgmCheckBoxRight">
        <div class="patientSelectBanner">
          <div class="patientSelectTitle" style="width: 25%;">患者: </div>
          <el-select v-model="selectedPatientObj.brID" filterable style="width: 80%;" placeholder="请选择"
                     @change="optionChange" :loading="loadingPatientUser">
            <!-- <el-option label="请选择" value="" /> -->
            <el-option v-for="item in patientList" :key="item.brID"
                       :label="item.name2+'【'+item.brID+'('+item.xb+')】'" :value="item.brID">
              <span style="float: left;">{{ item.xm }}</span>
              <span
                  style="float: right; color: #8492a6; font-size: 13px ;margin-right: 10px;width: 20px;">{{ item.xb }}
							</span>
              <span
                  style="float: right; color: #8492a6; font-size: 13px; margin-right: 20px;">{{ item.brID }}</span>
            </el-option>
          </el-select>

        </div>
        <div class="dataQueryBanner">
          <h2 class="dataQueryTitle">日期选择</h2>
          <div class="buttonList">
            <input type="button" class="buttonItem" @click="updataCourseByHand" value="刷新病程" />
            <input type="button" class="buttonItem" @click="changeLine" value="查询" />
          </div>

          <v-date-picker class="dataQueryCalendar" mode="dateTime" v-model="dateRange"
                         :disabled-dates='{ days:disabledDates }' is-range ref="calendar" />
        </div>
        <div class="doctorMessageBanner">
          <div class="checkButtonBox">
            <input type="button" class="checkButton" value="分析" />
            <input type="button" class="checkButton" value="导出" @click="pdfClick" />
          </div>
          <h2 class="doctorMessageTitle">查看医师<span>工号-{{this.$store.state.doctorID}}</span></h2>
          <h2 class="doctorMessageTitle">检测时间<span>{{date}}</span></h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import echarts from 'echarts'
	import axios from 'axios'
	// import { request } from '../network/request.js'
	import request from '@/utils/request'
	import html2canvas from 'html2canvas'
	import JsPDF from 'jspdf'

	import {
		GetAllPatientUserList,
		GetPatientUserCGMTimeData,
		GetCGMyichangjianceForlinechart,
		Getyichangtimestamp
	} from '@/api/patient.js'

	import {
		getTime,
		getAge,
		timeParas,
		getFromateTime,
		nameParas
	} from '../utils/index.js'
	import {
		diseaseTypeOption,
		SexOption,
		isDrink,
		MonthInfo
	} from '../common/resource.js'

	// import circleProgressBar from '@/src/components/circleprogressbar.vue';
	export default {
		name: 'page2',
		data() {
			// const start = new Date(2020, 0, 6);
			// const end = new Date(2020, 0, 10);
			return {
				first:true,

				globalLoading: null,
				dateRange: {
					// start: new Date(2020, 0, 1),
					//       end: new Date(2020, 0, 5)
					start: undefined, //
					end: undefined,
				},
				dates: new Date('2018-05-05'),

				disabledDates: [6, 8, 15],
				selectedPatientObj: {},

				patientUserId: '',
				patientList: [],
				loadingPatientUser: false,

				visibleDataTime: [],
				dockerObj: null,
				calendar: null,
				// dates: {
				// 	start: new Date(2019, 0, 14),
				// 	end: new Date(2019, 0, 18)
				// },
				userNameParase: '',
				
				MSG: '',
				SD: '',
				GMI: '',
				CV: '',
				TAR: '',
				TBR: '',
				HypoCount: '',
				TIR: '',

        dayMSG: '',
        daySD: '',
        dayGMI: '',
        dayCV: '',
        dayTAR: '',
        dayTBR: '',
        dayHypoCount: '',
        dayTIR: '',
        nightMSG: '',
        nightSD: '',
        nightGMI: '',
        nightCV: '',
        nightTAR: '',
        nightTBR: '',
        nightHypoCount: '',
        nightTIR: '',


				remID:'',
				remback:false,
				bl_Id: '',
				brName: '',
				brXb: '',
				type: '',
				csnyr: '',
				qzrq: '',
				spantime: '12',
				ymd1: '',
				ymd2: '',
				hms1: '',
				hms2: '',
				begintime: '',
				endtime: '',
				brID: '20001',
				doctorID: '',
				// 2022.12.10
				// phone: '',
				val: 24,
				// val: 12,
				// 2022.11.30王昕卓修改血糖值时间段
				activeclass: ['changebtn', 'atclick', 'changebtn'],
				// activeclass: ['atclick', 'changebtn', 'changebtn'],
				dataQuality: [0, '--', '--'],
				suggestion: ['Please wait...'],
				myVar01: '',
				myVar02: '',
				ysID: '',
				ysName: '',
				percentage: 10,
				date: '',
				params: {
					Id: 20001,
					age: 16,
					name: "wangxiaoming",
					bc: "5年2个月",
					hblx: '1型糖尿病'
				}
			}
		},
		components: {
			// circleProgressBar
		},
		beforeCreate() {

		},

		async created() {

			this.dockerObj = this.$store.state.user.userObj;
			this.globalLoading = this.$loading({
				lock: true,
				text: '数据加载中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.1)'
			});
			let param = {
				"DoctorId": this.dockerObj.userId,
				"UserId": null,
				"Name": "",
				"Phone": "",
			}
			this.loadingPatientUser = true;

			// 2022.11.18王昕卓修改接口形式,更换新接口
			this.globalLoading.close();
			this.loadingPatientUser = false;
			// 2022.12.10
			// this.phone = this.$store.state.phone;
			this.doctorID = this.$store.state.doctorID;
			// console.log(this.doctorID)
			const {data} = await request({
				// 2023.3.21王昕卓添加doctorID
				url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/' + this.doctorID,
				// 2022.12.10
				// url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/' + this.phone,
				method:'get'
			});
				let result = data.res
				if (result) {
					this.patientList = result;
					if (this.patientList && this.patientList.length > 0) {
						this.patientList.forEach(item => {
							item.name2 = nameParas(item.xm)
							if (item.sex) {
								let index = SexOption.findIndex(T => {
									return T.id == item.sex
								})
								if (index >= 0) {
									item.xb = SexOption[index].label
								} else {
									item.xb = item.sex
								}
							}
							if (item.xb==1) {
								item.xb='男'
							} else {
								item.xb='女'
							}
						})
						// this.optionChange(this.patientList[6].brID);
						// 2022.12.1王昕卓修改页面跳转
						if (this.$store.state.patientID == 0) {
							// console.log(this.$store.state.isback)
							if (this.$store.state.isback) {
								// console.log(this.$store.state.currentID);
								this.optionChange(this.$store.state.currentID);
							} else {
								this.optionChange(this.patientList[0].brID);
							}
						}
						else {
							this.optionChange(this.$store.state.patientID);
							if (this.$store.state.connectstate) {
								// this.optionChange(this.$store.state.patientID);
								this.pdfClick();
								this.handlestatereset();
								// console.log("重置后state:",this.$store.state.connectstate);
							}
							this.handleIDreset();
							// console.log("重置后ID:",this.$store.state.patientID);
						}
						
						
					}
				
			}
			
			
			// request({
			// 	url: '/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/10001',
			// 	method:'get'
			// },res => {
			// 	console.log(res)
			// 	let result = res.data.data.res
			// 	if (result) {
			// 		this.patientList = result;
			// 		if (this.patientList && this.patientList.length > 0) {
			// 			this.patientList.forEach(item => {
			// 				item.name2 = nameParas(item.name)
			// 				if (item.sex) {
			// 					let index = SexOption.findIndex(T => {
			// 						return T.id == item.sex
			// 					})
			// 					if (index >= 0) {
			// 						item.xb = SexOption[index].label
			// 					} else {
			// 						item.xb = item.sex
			// 					}
			// 				}
			// 			})
			// 			this.optionChange(this.patientList[1].brID);
				
			// 		}
			// 	}
			// }
			// )
			
			// let result = await GetAllPatientUserList(param);
			// this.globalLoading.close();
			// this.loadingPatientUser = false;
			// if (result.data) {
			// 	this.patientList = result.data;
			// 	if (this.patientList && this.patientList.length > 0) {
			// 		this.patientList.forEach(item => {
			// 			item.name2 = nameParas(item.name)
			// 			if (item.sex) {
			// 				let index = SexOption.findIndex(T => {
			// 					return T.id == item.sex
			// 				})
			// 				if (index >= 0) {
			// 					item.sexName = SexOption[index].label
			// 				} else {
			// 					item.sexName = item.sex
			// 				}
			// 			}
			// 		})
			// 		this.optionChange(this.patientList[0].patientId);

			// 	}
			// }


		},

		mounted() {
// console.log('mounted');

			this.showTime();
			// this.layuiRender();

		},

		watch: {

			// 如果 `patientID` 发生改变，这个函数就会运行
			brID: function(newVal, oldVal) {

				this.dataInitialization(); //加载折线图
			}
		},
		beforeDestroy() {
			if (this.globalLoading) {
				this.globalLoading.close();
			}
		},
		methods: {
			handlebackstate(){
				this.$store.commit('backstate');
			},
			handlerembrID(brID){
				this.$store.commit('remcurrentID',brID); 
				return this.$store.state.remcurrentID
			},	
			handlestatereset(){
				this.$store.commit('statereset');
			},
			handleIDreset(){
				this.$store.commit('IDreset');
			},
			changeLine() {

				this.begintime = undefined
				this.endtime = undefined
				if (!this.dateRange.start) {

					this.$message({
						type: "error",
						message: "无效的时间范围!",
					});
					return

				}
				this.begintime = this.dateRange.start
				this.endtime = this.dateRange.end
				this.dynamicChart(this.val);
			},

			async updataCourseByHand() {

				if(!this.selectedPatientObj.brID){
					this.$message({
						type: "error",
						message: "暂无患者数据",
					});
					return
				}
				await this.updataCourse();
				this.$message({
					type: "success",
					message: "刷新完成!",
				});
			},
			

			async updataCourse() {

				const text = document.querySelector('.vc-title').innerText
				let temp = text.split(' ')
				let tempTime = '';
				let year = '1001';
				let month = '01';
				if (temp && temp.length >= 2) {
					year = temp[1]
					let index = MonthInfo.findIndex(item => {
						return item.label == temp[0]
					})
					if (index >= 0) {
						month = MonthInfo[index].id
					}
				}
				tempTime = year + '-' + month 




				let param2 = {
					// "Id": this.selectedPatientObj.patientId,
					"Id": 'br' + this.selectedPatientObj.brID + 'cgm',
					"Time": tempTime
				}
// console.log("获取时间：",param2);
				
				// 2022.11.28王昕卓修改接口
				Promise.all([
					await request({
						url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/syscontrolassess/getbrcgmsjbmy',
						method: 'post',
						data:{
							"brID": this.selectedPatientObj.brID,
							"time": tempTime
						}
					})
				]).then(async (result) => {
				
				// Promise.all([
				// 	await GetPatientUserCGMTimeData(param2),

				// ]).then(async (result) => {

					// 2022.11.28王昕卓修改接口
					// if (result[0].data) {
					// 	this.disabledDates = result[0].data\
					// console.log(result[0].data.res)
					if (result[0]) {
						this.disabledDates = result[0].data.res
 
					}

				});
			},


			async optionChange(value) {
				let index = this.patientList.findIndex(item => {
					return item.brID == value
				})

				if (index >= 0) {
					this.selectedPatientObj = JSON.parse(JSON.stringify(this.patientList[index]))
					this.brID = this.selectedPatientObj.brID
					// if (this.selectedPatientObj.birthday) {
					// 	this.selectedPatientObj.ages = getAge(this.selectedPatientObj.birthday)
					// }

					this.userNameParase = nameParas(this.selectedPatientObj.xm)

					// if (this.selectedPatientObj.courseOfDisease) {
					// 	this.selectedPatientObj.courseOfDisease = timeParas(this.selectedPatientObj.courseOfDisease,
					// 		'time');
					// }
					if (this.selectedPatientObj.hblx) {
						let index2 = diseaseTypeOption.findIndex(T => {
							return T.id == this.selectedPatientObj.hblx
						})
						if (index2 >= 0) {
							this.selectedPatientObj.hblx = diseaseTypeOption[index2].label
						} else {
							this.selectedPatientObj.hblx = this.selectedPatientObj.hblx
						}
					}


					// this.updataCourse();
					// 更新病程日历
				}

				// this.globalLoading.close();
				this.$forceUpdate()

			},

			dynamicChart(val) {
				var obj = this.theme
				var echarts = require('echarts');
				// echarts.registerTheme('vintage' , obj);
				// var myChart3 = echarts.init(document.getElementById('checkchart'),'vintage');
				var myChart3 = echarts.init(document.getElementById('checkchart'));

				// 初始 option
				// 2022.12.2王昕卓修改加载文字
				// myChart3.showLoading();
				myChart3.showLoading({
					text: '数据正在分析，请耐心等待...',
					maskColor: '#ffffff',
					textColor: '#404a59',
					});
				// 绘制图表
				myChart3.clear();
				myChart3.setOption({

					title: {
						text: '血糖值(mmol/L)',
						textStyle: {
							color: '#35384D',
							fontSize: 20
						},
						top: '24px',
						left: '4%'
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#FFFFFF',
						textStyle: {
							color: '#35384D'
						},
						padding: [
							10, // 上
							10, // 右
							20, // 下
							10, // 左
						],
						extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
					},
					legend: {
						bottom: '16px'
					},
					grid: {
						top: 70,
						left: '25px',
						right: '25px',
						bottom: '60px',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						},
						top: '38px',
						right: '41px'
					},
					xAxis: {
						type: 'category',
						// 是否让线条和坐标轴有缝隙
						boundaryGap: true,
						data: [],
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						// name: 'Glucose Measurements(mmg/dL)',
						nameTextStyle: {
							verticalAlign: 'bottom',
							align: 'left'
						},
						// min: '0',
						// max: '25',
						nameRotate: 0,
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						},
						// 坐标轴在 grid 区域中的分隔线
						splitLine: {
							show: true,
							lineStyle: {
								color: '#F7F9FB'
							}
						},
					},
					series: [

					]
				});
				// 让图表跟随屏幕自动的去适应
				window.addEventListener("resize", function() {
					myChart3.resize();
				})
				// let sdtime1 = new Date(this.endtime)
				// sdtime1 = new Date(sdtime1)
				// let startTime = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - this.val) //小时
				// startTime = new Date(startTime)
				// // startTime = this.dateFormat(startTime)
				// this.begintime = this.dateFormat(startTime)

				this.requestData(myChart3, val);
			},
			
			async requestData(obj, val) {
				// 2022.11.24王昕卓修改接口
				// const url = '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart'
		
				// let sdtime1 = new Date().setHours(new Date().getHours() + 8)
				let sdtime1 = new Date(this.endtime)
				sdtime1 = new Date(sdtime1)
				// console.log(sdtime1 + '-' + this.endtime)
				let sdtime2 = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - 12) //小时
				sdtime2 = new Date(sdtime2)
				let sdtime3 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 1) //1天
				sdtime3 = new Date(sdtime3)
				// sdtime3 = sdtime3.setHours(sdtime3.getHours() + 8)
				// sdtime3 = new Date(sdtime3)
				let sdtime4 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 2) //1周
				sdtime4 = new Date(sdtime4)
				// sdtime4 = sdtime4.setHours(sdtime4.getHours() + 8)
				// sdtime4 = new Date(sdtime4)
				let sdtimen = new Date()
				let startTime = new Date()
				if (val === 12) {
					startTime = new Date(sdtime2)
					// console.log(this.endtime + '-' + startTime)
				} else if (val === 24) {
					startTime = new Date(sdtime3)
					// console.log(sdtime1 + '-' + startTime)
				} else if (val === 48) {
					startTime = new Date(sdtime4)
					// console.log(sdtime1 + '-' + startTime)
				}


				let ttt1 = new Date(this.begintime)
				let ttt2 = new Date(this.endtime)

				if (ttt1.getTime() == NaN || ttt2.getTime() == NaN) {
					this.$message({
						type: "error",
						message: "日期错误!",
					});

					return
				}
				let postdata2 = {
					// "bl_ID": this.selectedPatientObj.patientId,
					// "begintime": ttt1.getTime(), //2021-5-8 进行修改接口的post数据，将标准时间改为时间戳
					// "br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
					// "endtime": ttt2.getTime()
					"begintime": 1167810699000,
					"br_ID": "br20005cgm",
					"endtime": 1667810699000
				}

				// 2022.11.22王昕卓更改接口
				
				
				// 	await request({
				// 		url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
				// 		method: 'post',
				// 		data: 'postdata2'
				// 	})
				// ]).then(result => {
				// const {CGMycjc, CGMcjctj, CGMjlsj, CGMjl} = await request({
				// 	url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
				// 	method: 'post',
				// 	data: 'postdata2'
				// });
				// console.log(CGMycjc, CGMcjctj, CGMjlsj, CGMjl)
				
				// request({
				// 	url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
				// 	method: 'post'
				// },res => {
				// 	console.log(res)
				// 	let result = res
				
				// Promise.all([
				// 	await request({
				// 		url: '/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
				// 		method : 'post',
				// 		ContentType : 'application/json',
				// 		data : postdata2
				// 	})
				// ]).then(result => {
					


				Promise.all([
					await request({
						url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
						method:'post',
						data:{
								"begintime": ttt1.getTime(),
								"br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
								"endtime": ttt2.getTime()
							}
					})
				]).then(result => {
					// console.log(result)
				
				
				// Promise.all([
				// 	await GetCGMyichangjianceForlinechart(postdata2),
				// ]).then(result => {
				
				
					
					obj.hideLoading();
					// console.log(result)

					if (result[0]) {

						if (result[0].CGMjl.length === 0) {
							this.$message({
								type: "error",
								message: "您所选择的时间段内没有数据!",
							});

						} else if (result[0].CGMjl.length < 12 * val) {
							// alert("数据不足" + val + "小时")
							this.$message({
								type: "error",
								message: "数据不足" + val + "小时",
							});
						}
						let CGMycjc = []
						let CGMjlsj = []
						let CGMjl = []
						let CGMycjctj = []
						let CGMxzz = []
						let abnormal1 = [] // 异常1虚线Y轴坐标初始化容器
						let abnormal2 = []
						let abnormal3 = []
						let abnormal4 = []
						let abnormal5 = []
						let abnormal6 = []
						let abnormal7 = []
            let abnormal8 = []
						let index1 = [] // 异常1索引存储器
						let index2 = []
						let index3 = []
						let index4 = []
						let index5 = []
						let index6 = []
            let differ = []
						let num1 = 0 // 异常1计数器
						let num2 = 0 // 异常2计数器
						let num3 = 0 // 异常3计数器
						let num4 = 0 // 异常4计数器
						let num5 = 0 // 异常5计数器
						let num6 = 0 // 异常6计数器
						let total = 0
						CGMjl = result[0].CGMjl
						CGMxzz = result[0].CGMxzz
						CGMycjctj = result[0].CGMycjctj
						total = result[0].CGMjl.length
						let value1 = ''
						let value2 = ''
						let value3 = ''
						let value4 = ''
						let value5 = ''
						let value6 = ''
						let value0 = ''
						if (CGMycjctj) {
							value1 = (CGMycjctj['1.0'] ? CGMycjctj['1.0'] : 0) // PISA故障
							value2 = (CGMycjctj['2.0'] ? CGMycjctj['2.0'] : 0) // 尖峰故障
							value3 = (CGMycjctj['3.0'] ? CGMycjctj['3.0'] : 0) // 漂移故障
							value4 = (CGMycjctj['4.0'] ? CGMycjctj['4.0'] : 0) // 阶跃故障
							value5 = (CGMycjctj['5.0'] ? CGMycjctj['5.0'] : 0) // 缺失故障
							value6 = (CGMycjctj['0.5'] ? CGMycjctj['0.5'] : 0) // 未知故障
							value0 = (CGMycjctj['0.0'] ? CGMycjctj['0.0'] : 0) // 正常数据点
						} else {
							value1 = 0
							value2 = 0
							value3 = 0
							value4 = 0
							value5 = 0
							value6 = 0
							value0 = 0
						}

            for (var i = 0; i < result[0].CGMjlsj.length; i++) {
              if (CGMjl[i]==CGMxzz[i]){
                differ[i]=0
              }else {
                differ[i]=CGMxzz[i]
              }
            }
						for (var i = 0; i < result[0].CGMjlsj.length; i++) {

							CGMjlsj[i] = this.timestap_alert(result[0].CGMjlsj[i])
							abnormal1[i] = -2
							abnormal2[i] = -4
							abnormal3[i] = -6
							abnormal4[i] = -8
							abnormal5[i] = -10
							abnormal6[i] = -12
							abnormal7[i] = 3.9
              abnormal8[i] = 10.0

						}
						for (var j = 0; j < result[0].CGMycjc.length; j++) {

							switch (result[0].CGMycjc[j]) {
								case 1: // abnormal1[num1] = response.data.CGMycjc[j]              //用于存储PISA异常类型
									index1[num1] = j //用于存储异常数据索引
									num1++; //循环结束后num1等于异常1个数;
									break;
								case 2: // abnormal2[num2] = response.data.CGMycjc[j]              //用于存储尖峰异常类型;
									index2[num2] = j //用于存储异常数据索引
									num2++; //循环结束后num2等于异常2个数;
									break;
								case 3: // abnormal3[num3] = response.data.CGMycjc[j]              //用于存储漂移异常类型;
									index3[num3] = j //用于存储异常数据索引
									num3++; //循环结束后num3等于异常3个数;
									break;
								case 4: // abnormal4[num4] = response.data.CGMycjc[j]              //用于存储阶跃异常类型;
									index4[num4] = j //用于存储异常数据索引
									num4++; //循环结束后num4等于异常4个数;
									break;
								case 5: // abnormal5[num5] = response.data.CGMycjc[j]              //用于存储缺失异常类型;
									index5[num5] = j //用于存储异常数据索引
									num5++; //循环结束后num5等于异常5个数;
									break;
								// 2022.12.7王昕卓添加噪声故障
								case 0.5: // abnormal6[num6] = response.data.CGMycjc[j]              //用于存储噪声异常类型;
									index6[num6] = j //用于存储异常数据索引
									num6++; //循环结束后num6等于异常6个数;
									break;
								default:
									;
									break;
							}

						}
						let abPoint1 = []
						let abPoint2 = []
						let abPoint3 = []
						let abPoint4 = []
						let abPoint5 = []
						let abPoint6 = []
						for (var n = 0; n < num1; n++) {
							abPoint1[n] = {
								"name": 'PISA',
								"coord": [index1[n], -2],
								// "value":abnormal1[n],
								"symbolSize": 10,
								"symbol": "pin",
								"fontsize": 20,
								"label": {
									"fontsize": 20
								},
								"itemStyle": {
									"opacity": 0.7,
									"color": {
										"type": 'linear',
										"x": 0,
										"y": 0,
										"x2": 0,
										"y2": 1,
										"colorStops": [{
											"offset": 0,
											"color": '#00ff00' // 0% 处的颜色
										}, {
											"offset": 1,
											"color": '#00ff00' // 100% 处的颜色
										}],
										"global": false // 缺省为 false
									}
								}
							}
						}
						for (var n = 0; n < num2; n++) {
							abPoint2[n] = {
								"name": '尖峰故障',
								"coord": [index2[n], -4],
								// "value":abnormal1[n],
								"symbolSize": 10,
								"symbol": "circle",
								"fontsize": 20,
								"label": {
									"fontsize": 20
								},
								"itemStyle": {
									"opacity": 0.7,
									"color": '#ff0000'
								}
							}
						}
						for (var n = 0; n < num3; n++) {
							abPoint3[n] = {
								"name": '漂移故障',
								"coord": [index3[n], -6],
								// "value":abnormal1[n],
								"symbolSize": 10,
								"symbol": "triangle",
								"fontsize": 20,
								"label": {
									"fontsize": 20
								},
								"itemStyle": {
									"opacity": 0.7,
									"color": '#b3b300'
								}
							}
						}
						for (var n = 0; n < num4; n++) {
							abPoint4[n] = {
								"name": '阶跃故障',
								"coord": [index4[n], -8],
								// "value":abnormal1[n],
								"symbolSize": 10,
								"symbol": "diamond",
								"fontsize": 20,
								"label": {
									"fontsize": 20
								},
								"itemStyle": {
									"opacity": 0.7,
									"color": '#ff7cf9'
								}
							}
						}
						for (var n = 0; n < num5; n++) {
							abPoint5[n] = {
								"name": '缺失故障',
								"coord": [index5[n], -10],
								// "value":abnormal1[n],
								"symbolSize": 10,
								"symbol": "arrow",
								"fontsize": 20,
								"label": {
									"fontsize": 20
								},
								"itemStyle": {
									"opacity": 0.7,
									// "color": {
									// 	"type": 'linear',
									// 	"x": 0,
									// 	"y": 0,
									// 	"x2": 0,
									// 	"y2": 1,
									// 	"colorStops": [{
									// 		"offset": 0,
									// 		"color": '#ff6600' // 0% 处的颜色
									// 	}, {
									// 		"offset": 1,
									// 		"color": '#ff6600' // 100% 处的颜色
									// 	}],
									// 	"global": false // 缺省为 false
									// }
									"color": '#ff6600'
								}
							}
						}
						for(var n=0; n<num6; n++){
							abPoint6[n] = {
											"name": '噪声故障',
											"coord": [index6[n], -12],
											// "value":abnormal1[n],
											"symbolSize" : 10,
											"symbol":"emptyCircle",
											"fontsize" : 20,
											"label" : {
												"fontsize" : 20
											},
											"itemStyle":{
												"opacity":0.7,
												"color": {
													"type": 'linear',
													"x": 0,
													"y": 0,
													"x2": 0,
													"y2": 1,
													"colorStops": [{
														"offset": 0, "color": '#00ffff' // 0% 处的颜色
													}, {
														"offset": 1, "color": '#00ffff' // 100% 处的颜色
													}],
													"global": false // 缺省为 false
												}
											}
										}
						}!
						this.chartSetOption(obj, CGMjlsj, CGMjl, CGMxzz, abPoint1, abnormal1, abPoint2,
							abnormal2,
							abPoint3, abnormal3, abPoint4, abnormal4, abPoint5, abnormal5, abPoint6, abnormal6, abnormal7,abnormal8,differ)
						// this.showchart(value1,value2,value3,value4,value5,value6)

						var p0 = value0 / total // 正确率
						var str0 = Number(p0 * 100).toFixed()
						// str0+="%";
						var p1 = value5 / total // 缺失率
						var str1 = Number(p1 * 100).toFixed()
						// str1+="%";
						// var p2 = (total - value0 - value1 - value6)/total                       // 除了 PISA 故障和未知故障
						// var str2 = Number(p2*100).toFixed()
						// str2+="%";
						var p3 = (value3 + value6) / total
						// console.log(str0 + '-' + str1 + '-' + str2 + '-')
						this.dataQuality = []

						if (total === 0) {
							this.dataQuality[0] = 0
							this.dataQuality[1] = '--'
							this.dataQuality[2] = '--'
						} else if (p0 >= 0.9) {
							this.dataQuality[0] = str0
							this.dataQuality[1] = str1
							this.dataQuality[2] = '较高'
						} else if ((p0 < 0.9) && (p0 > 0.7)) {
							this.dataQuality[0] = str0
							this.dataQuality[1] = str1
							this.dataQuality[2] = '中等'
						} else if ((p0 <= 0.7) && (p0 > 0)) {
							this.dataQuality[0] = str0
							this.dataQuality[1] = str1
							this.dataQuality[2] = '较差'
						} else {

						}
						this.analysisData(value1, value5, p0, total, value2 + value4, p3);
					}

				}).catch(error => {
				      console.log(error)
				    });
					// 2023.2.20王昕卓添加数据分析接口
							Promise.all([
								await request({
									url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMpingu',
									method: 'post',
									data:{
										"begintime": ttt1.getTime(),
										"br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
										"endtime": ttt2.getTime()
									}
								})
							]).then(result => {
								// console.log(result)
								
								this.MSG = result[0].a6.MSG.toFixed(2)
								this.SD = result[0].a6.SD.toFixed(2)
								this.GMI = result[0].a6.GMI.toFixed(2)
								this.CV = result[0].a6.CV.toFixed(2)
								this.TAR = result[0].a6.TAR.toFixed(2)
								this.TBR = result[0].a6.TBR.toFixed(2)
								this.HypoCount = result[0].a6.HypoCount.toFixed(0)
								this.TIR = result[0].a6.TIR.toFixed(2)
								
								}).catch(error => {
							      console.log(error)
							    });
							//2023/4/18杨振宇添加夜间分析和日间分析
					
					Promise.all([
					  await request({
					    url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMdaypingu',
					    method: 'post',
					    data:{
					      "begintime": ttt1.getTime(),
					      "br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
					      "endtime": ttt2.getTime()
					    }
					  })
					]).then(result => {
					  // console.log(result)
					
					  this.dayMSG = result[0].a6.MSG.toFixed(2)
					  this.daySD = result[0].a6.SD.toFixed(2)
					  this.dayGMI = result[0].a6.GMI.toFixed(2)
					  this.dayCV = result[0].a6.CV.toFixed(2)
					  this.dayTAR = result[0].a6.TAR.toFixed(2)
					  this.dayTBR = result[0].a6.TBR.toFixed(2)
					  this.dayHypoCount = result[0].a6.HypoCount.toFixed(0)
					  this.dayTIR = result[0].a6.TIR.toFixed(2)
					
					}).catch(error => {
					  console.log(error)
					});
					Promise.all([
					  await request({
					    url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetFGMnightpingu',
					    method: 'post',
					    data:{
					      "begintime": ttt1.getTime(),
					      "br_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
					      "endtime": ttt2.getTime()
					    }
					  })
					]).then(result => {
					  // console.log(result)
					
					  this.nightMSG = result[0].a6.MSG.toFixed(2)
					  this.nightSD = result[0].a6.SD.toFixed(2)
					  this.nightGMI = result[0].a6.GMI.toFixed(2)
					  this.nightCV = result[0].a6.CV.toFixed(2)
					  this.nightTAR = result[0].a6.TAR.toFixed(2)
					  this.nightTBR = result[0].a6.TBR.toFixed(2)
					  this.nightHypoCount = result[0].a6.HypoCount.toFixed(0)
					  this.nightTIR = result[0].a6.TIR.toFixed(2)
					
					}).catch(error => {
					  console.log(error)
					});
			},

			chartSetOption(obj, xAxisdata, yAxisdata2, CGMxzz, abPoint1, abnormal1, abPoint2, abnormal2, abPoint3,
				abnormal3, abPoint4, abnormal4, abPoint5, abnormal5, abPoint6, abnormal6, abnormal7,abnormal8,differ) {
				obj.hideLoading();
				obj.setOption({
					title: {
						text: '血糖值(mmol/L)',
						textStyle: {
							color: '#35384D',
							fontSize: 20
						},
						top: '24px',
						left: '4%'
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#FFFFFF',
						textStyle: {
							color: '#35384D'
						},
						padding: [
							10, // 上
							10, // 右
							20, // 下
							10, // 左
						],
						extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
					},
					legend: {
						// 2022.12.7王昕卓添加噪声故障
						data: ['数据修正值', '数据记录', 'PISA', '尖峰故障', '漂移故障', '阶跃故障', '缺失故障', '噪声故障', '低血糖阈值','高血糖阈值','差异性'],
						bottom: '16px'
					},
					grid: {
						left: '25px',
						right: '25px',
						bottom: '60px',
						show: false,
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						},
						top: '38px',
						right: '41px'
					},
					xAxis: {
						type: 'category',
						// 是否让线条和坐标轴有缝隙
						boundaryGap: true,
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						},
						data: xAxisdata,
						// type : 'time',
						axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
							interval: 23, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
							// rotate: 40 // 横坐标上label的倾斜度
						},
					},
					yAxis: {
						type: 'value',
						// name: '血糖测量值(mmol/L)',
						nameTextStyle: {
							verticalAlign: 'bottom',
							align: 'left'
						},
						min: '0',
						// max: '25',
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						},
						// 坐标轴在 grid 区域中的分隔线
						splitLine: {
							show: true,
							lineStyle: {
								color: '#F7F9FB'
							}
						},
						// splitArea: {
						// 	show: true
						// },
						nameRotate: 0
					},
					series: [{
							name: '数据修正值',
							type: 'line',
							smooth: 'true',
							symbol: "none",
							color: 'rgb(170, 66, 6)',
							// color: '#da9a39',
							data: CGMxzz,
							lineStyle: {
								width: 2,
								// type: 'dashed'
							}
						},
						{
							name: '数据记录',
							type: 'line',
							color: '#504DA6',
							smooth: 'true',
							symbol: "none",
							data: yAxisdata2,
							// 线条样式
							// 2022.11.30王昕卓修改线条宽度
							// lineStyle: {
							// 	width: 5
							// },
							lineStyle: {
								width: 2,
							},
							// 区域填充样式。设置后显示成区域面积图
/*							areaStyle: {
								// color: 'linear-gradient(#ececf5 #FFFFFF)'
								color: '#ececf5'
							}*/
						},
						// 2022.12.28王昕卓添加低血糖阈值

              //2022/4/20杨振宇添加差异性
            {
              name: '差异性',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#49c4f8',
              data: differ,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'

              },
              areaStyle: {
                color: '#ececf5'}
            },
            {
              name: '低血糖阈值',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#ff6600',
              data: abnormal7,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'
              },

            },
            {
              name: '高血糖阈值',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#b706de',
              data: abnormal8,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'
              },
            },
						// 2022.12.28王昕卓删除故障类型
						// {
						// 	name: 'PISA',
						// 	type: 'line',
						// 	smooth: 'true',
						// 	symbol: "none",
						// 	color: '#00ff00',
						// 	data: abnormal1,
						// 	tooltip: {
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint1
						// 	}
						// },
						// // {
						// // 	name: '异常1',
						// // 	type: 'line',
						// // 	smooth:'true',
						// // 	symbol:"none",
						// // 	color: '#00ff00',
						// // 	data: 0,
						// // 	lineStyle: {
						// // 		width: 2,
						// // 		type: 'dashed'
						// // 	}
						// // },
						// {
						// 	name: '尖峰故障',
						// 	type: 'line',
						// 	smooth: 'true',
						// 	symbol: "none",
						// 	color: '#ff0000',
						// 	data: abnormal2,
						// 	tooltip: {
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint2
						// 	}
						// },
						// {
						// 	name: '漂移故障',
						// 	type: 'line',
						// 	smooth: 'true',
						// 	symbol: "none",
						// 	color: '#b3b300',
						// 	data: abnormal3,
						// 	tooltip: {
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint3
						// 	}
						// },
						// {
						// 	name: '阶跃故障',
						// 	type: 'line',
						// 	smooth: 'true',
						// 	symbol: "none",
						// 	color: '#ff7cf9',
						// 	data: abnormal4,
						// 	tooltip: {
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint4
						// 	}
						// },
						// {
						// 	name: '缺失故障',
						// 	type: 'line',
						// 	smooth: 'true',
						// 	data: abnormal5,
						// 	color: '#ff6600',
						// 	symbol: "none",
						// 	tooltip: {
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint5
						// 	}
						// },
						// {
						// 	name: '噪声故障',
						// 	type: 'line',
						// 	smooth:'true',
						// 	data: abnormal6,
						// 	symbol:"none",
						// 	color: '#00ffff',
						// 	tooltip:{
						// 		show: false
						// 	},
						// 	lineStyle: {
						// 		width: 2,
						// 		type: 'dashed'
						// 	},
						// 	markPoint: {
						// 		data: abPoint6
						// 	}
						// },
					]
				});
			},
			reqChange(val) {
				this.activeclass = []
				this.val = ''
				this.val = val
				for (var i = 0; i < 3; i++) {
					this.activeclass[i] = 'changebtn'
				}
				switch (val) {
					case 12:
						this.activeclass[0] = 'atclick';
						break;
					case 24:
						this.activeclass[1] = 'atclick';
						break;
					case 48:
						this.activeclass[2] = 'atclick';
						break;
				}


				// let sdtime1 = new Date().setHours(new Date().getHours() + 8)
				let sdtime1 = new Date(this.endtime)
				sdtime1 = new Date(sdtime1)
				// console.log(sdtime1 + '-' + this.endtime)
				let sdtime2 = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - 12) //小时
				sdtime2 = new Date(sdtime2)
				let sdtime3 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 1) //1天
				sdtime3 = new Date(sdtime3)
				// sdtime3 = sdtime3.setHours(sdtime3.getHours() + 8)
				// sdtime3 = new Date(sdtime3)
				let sdtime4 = new Date(sdtime1).setDate(new Date(sdtime1).getDate() - 2) //1周
				sdtime4 = new Date(sdtime4)
				// sdtime4 = sdtime4.setHours(sdtime4.getHours() + 8)
				// sdtime4 = new Date(sdtime4)
				let sdtimen = new Date()
				let startTime = new Date()
				if (val === 12) {
					startTime = new Date(sdtime2)
					// console.log(this.endtime + '-' + startTime)
				} else if (val === 24) {
					startTime = new Date(sdtime3)
					// console.log(sdtime1 + '-' + startTime)
				} else if (val === 48) {
					startTime = new Date(sdtime4)
					// console.log(sdtime1 + '-' + startTime)
				}

				this.begintime = startTime,
					this.endtime = sdtime1.getTime()



				this.dynamicChart(val);
				// this.dataQualityChart();
				this.showTime();
			},
			showchart(value1, value2, value3, value4, value5, value6) {
				let bar_dev = this.$refs.chart1
				if (bar_dev) {

					var obj = this.theme
					var echarts = require('echarts');
					// echarts.registerTheme('vintage' , obj);
					let myChart1 = echarts.init(bar_dev)
					myChart1.setOption({
						title: {
							// text: '产量玫瑰图',
							fontSize: 15
						},
						backgroundColor: "#BC80AD",
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b} : {c} ({d}%)'
						},
						grid: {
							left: '25px',
							right: '25px',
							top: '20px',
						},
						legend: {
							right: 35,
							orient: 'vertical',
							data: ['PISA', '尖峰', '漂移', '阶跃', '缺失', '噪声']
						},
						toolbox: {
							show: true,
							feature: {
								// mark: {show: true},
								// dataView: {show: true, readOnly: false},           //数据视图选项、按钮图标
								magicType: {
									show: true,
									type: ['pie', 'funnel']
								},
								// restore: {show: true},                             //刷新按钮图标
								// saveAsImage: {show: true}
							}
						},
						series: [{
							name: '低血糖预警次数',
							type: 'pie',
							// radius: [30, 50],
							"color": [
								"#00ff00",
								"#ff0000",
								"#b3b300",
								"#ff7cf9",
								"#ff6600",
								"#00ffff",
								"#efa18d",
								"#787464",
								"#cc7e63",
								"#724e58",
								"#4b565b"
							],
							center: ['36%', '51%'],
							radius: 65,
							avoidLabelOverlap: false,
							// label: {
							//  show: false,
							//  position: 'center'
							// },
							label: {
								show: false // 
							},
							emphasis: {
								label: { // 鼠标移动到图上展示框
									show: true,
									fontSize: '12',
									fontWeight: '600'
								}
							},
							// roseType: 'area',
							data: [{
									value: value1,
									name: 'PISA'
								},
								{
									value: value2,
									name: '尖峰'
								},
								{
									value: value3,
									name: '漂移'
								},
								{
									value: value4,
									name: '阶跃'
								},
								{
									value: value5,
									name: '缺失'
								},
								{
									value: value6,
									name: '噪声'
								}
							]
						}]
					});
				}
			},
			requestpatientmsg() {

				this.bl_Id = this.getCookie("bl_Id")
				this.brName = this.getCookie("brName")
				this.brXb = this.getCookie("brXb")
				this.type = this.getCookie("type")
				this.csnyr = this.getCookie("csnyr")
				this.qzrq = this.getCookie("qzrq")
				this.ysID = this.getCookie("ysID")
				this.ysName = this.getCookie("ysName")

			},
			updatepatientmsg() {
				//已被弃用
				request({
					url: '/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/' + this
						.brID, //吴佳琪201-5-7修改新接口框架
					method: 'get'
				}, res => {
					let requestData = res.data.data.res
					this.bl_Id = requestData.brID
					this.brName = requestData.name
					this.brXb = requestData.sex
					this.type = requestData.hblx
					this.csnyr = this.timestap_ageCac(requestData.csnyr)
					this.qzrq = this.timestap_bcCac(requestData.qzrq)
					document.cookie = "bl_Id=" + this.bl_Id;
					document.cookie = "brName=" + this.brName;
					document.cookie = "brXb=" + this.brXb;
					document.cookie = "type=" + this.type;
					document.cookie = "csnyr=" + this.csnyr;
					document.cookie = "qzrq=" + this.qzrq;
				}, err => {
					console.log(err)
				})
			},
			analysisData(value1, value5, p0, total, valueplus, p3) {
				// console.log(value1,fault,value5,p0)
				this.suggestion = []
				let length = 0
				if (value1 > 15) {
					this.suggestion[length] = '发生PISA故障较多，请患者尽量避免压到传感器'
					length++
				} else if (p3 > 0.05 || valueplus > 12) {
					this.suggestion[length] = '数据受外部干扰较大，请减少干扰并及时更换采集芯片'
					length++
				} else if (value5 > 12) {
					this.suggestion[length] = '为了避免数据缺失与停滞，请患者不要取下设备，并及时更换采集芯片'
					length++
				}
				// else if(p0 > 0.95){
				// 	this.suggestion[length] = '总体数据质量很好'
				// 	length++
				// }
				else {
					if (total === 0) {
						this.suggestion[length] = '当前时段内没有患者数据'
					} else if (p0 >= 0.9) {
						this.suggestion[length] = '总体数据质量较高,无临床使用建议'
					} else if ((p0 > 0.7) && (p0 < 0.9)) {
						this.suggestion[length] = '总体数据质量中等,无临床使用建议'
					} else {
						this.suggestion[length] = '总体数据质量较差,无临床使用建议'
					}
				}

			},
			getCookie(cname) {
				var name = cname + "=";
				var ca = document.cookie.split(';');
				for (var i = 0; i < ca.length; i++) {
					var c = ca[i].trim();
					if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
				}
				return "";
			},

			async dataInitialization() {
				let that = this;
				

				let param = {
					// "bl_ID": this.selectedPatientObj.patientId,
					"bl_ID": 'br' + this.selectedPatientObj.brID + 'cgm',
				}
				// 2022.11.27王昕卓修改接口
				let {result} = await request({
					url:'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/getyichangtimestamp/' + 'br' + this.selectedPatientObj.brID + 'cgm',
					method:'get'
				});
				// console.log(result)
				// let result = await Getyichangtimestamp(param);
				let timestap = result
				let dates = new Date();
				let tempTime = dates.getFullYear() + '-' + ((dates.getMonth() + 1)>9?(dates.getMonth() + 1):('0'+(dates.getMonth() + 1)))

				if (timestap) {
					// let sdtime1 = new Date(timestap)
					// sdtime1 = new Date(sdtime1)
					// let startTime = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - this
					// 	.val) //小时
					// startTime = new Date(startTime)
					// startTime = this.dateFormat(startTime)


					let sdtime1 = new Date()
					if (timestap.indexOf('/') >= 0) {
						let dataInfoList = timestap.split(' ')[0].split('/')
						let timeInfo = timestap.split(' ')[1]
						let dataInfo = dataInfoList[2] + '-' + dataInfoList[1] + '-' + dataInfoList[0] + ' ' + timeInfo
						sdtime1 = new Date(dataInfo)

				
					} else {
						sdtime1 = new Date(timestap)
					}
				

					let startTime = new Date(sdtime1).setHours(new Date(sdtime1).getHours() - this.val) //小时

					this.begintime = this.dateFormat(startTime)
					this.endtime = this.dateFormat(sdtime1)
				
					const calendar = this.$refs.calendar;
					await calendar.move({
						month: sdtime1.getMonth() + 1,
						year: sdtime1.getFullYear()
					})
					tempTime = sdtime1.getFullYear() + '-' +( (sdtime1.getMonth() + 1) >9?(sdtime1.getMonth() + 1) :('0'+(sdtime1.getMonth() + 1) ))

				} else {
					this.begintime = 0
					this.endtime = 0
				}


				//更新日历可用日期
				let param2 = {
					// "Id": this.selectedPatientObj.patientId,
					"Id": 'br' + this.selectedPatientObj.brID + 'cgm',
					"Time": tempTime
				}

				// 2022.11.28王昕卓修改接口
				const {data} = await request({
					url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/syscontrolassess/getbrcgmsjbmy',
					method: 'post',
					data: {
						"brID": this.selectedPatientObj.brID,
						"time": tempTime
					}
				})
				let result2 = data
				// console.log(result2)
				
				// let result2 = await GetPatientUserCGMTimeData(param2)
				this.disabledDates = result2.res
				
			
				this.dynamicChart(this.val);

			},
			timestap_alert(originaltime) {
				let date = ''
				if (originaltime.indexOf('/') >= 0) {
					let dataInfoList = originaltime.split(' ')[0].split('/')
					let timeInfo = originaltime.split(' ')[1]
					let dataInfo = dataInfoList[2] + '-' + dataInfoList[1] + '-' + dataInfoList[0] + ' ' + timeInfo
					date = new Date(dataInfo)
				
								
				} else {
					date = new Date(originaltime)
				}
				
				
				
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
					''; //date.getMinutes() + ':';
				var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

				return (h + m)
			},
			timestap_choose(originaltime) {
				if (originaltime) {
					const date = new Date(originaltime)
					var Y = date.getFullYear() + '-';
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
					var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
					var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
					var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
						''; //date.getMinutes() + ':';
					var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

					return (Y + M + D + h + m)
				} else {
					return ('--:--:--')
				}
			},
			dateFormat(originaltime) {
				const date = new Date(originaltime)
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D2 = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + 'T';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
					':'; //date.getMinutes() + ':';
				var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

				return (Y + M + D2 + h + m + s)
			},
			timestap_ageCac(originaltime) {
				const date1 = new Date(originaltime)
				const date2 = new Date()
				var Y1 = date1.getFullYear();
				var M1 = date1.getMonth() + 1
				var Y2 = date2.getFullYear();
				var M2 = date2.getMonth() + 1

				return (Y2 - Y1)
			},
			timestap_bcCac(originaltime) {
				const date1 = new Date(originaltime)
				const date2 = new Date()
				var Y1 = date1.getFullYear();
				var M1 = date1.getMonth() + 1
				var Y2 = date2.getFullYear();
				var M2 = date2.getMonth() + 1
				var y = Y2 - Y1
				var m = M2 - M1
				if (m < 0) {
					m = 12 + m
					y--
					if (y === 0) {
						return (m + '个月')
					}
					return (y + '年' + m + '个月')
				} else {
					if (y === 0) {
						if (m === 0) {
							return ('不足一个月')
						}
						return (m + '个月')
					} else {
						if (m === 0) {
							return (y + '年')
						}
						return (y + '年' + m + '个月')
					}
				}
			},
			showTime() {
				this.myVar02 = ""
				// this.myVar02 = setInterval(()=>{
				const date = new Date()
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
				var D2 = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + 'T';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
					':'; //date.getMinutes() + ':';
				var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

				this.date = Y + M + D + h + m + s
				// },1000)

			},

			htmltoPdf() {
				let Width = document.querySelector('#chec').offsetWidth
				let Height = document.querySelector('#chec').offsetHeight
				let offsetTop = document.querySelector('#chec').offsetTop
				var heightStyle = this.$refs.checc.offsetHeight;
				alert(heightStyle + '-' + Height)
			},
			getPdf() {
				var title = 'chart'
				html2canvas(document.querySelector('#chec'), {
					allowTaint: true
				}).then(function(canvas) {
					let win_in = document.documentElement.clientWidth || document.body
						.clientWidth // 获得当前可视窗口的宽度（不包含滚动条）
					let win_out = window.innerWidth // 获得当前窗口的宽度（包含滚动条）

					// if (win_out > win_in) {
					//   // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
					//   abs = (win_out - win_in) / 2    // 获得滚动条宽度的一半
					//   // console.log(a, '新abs');
					// }
					let contentWidth = canvas.width * 2
					let contentHeight = canvas.height * 2
					let pageHeight = contentWidth / 592.28 * 841.89
					let leftHeight = contentHeight
					let position = 0
					let imgWidth = 595.28
					let imgHeight = 592.28 / contentWidth * contentHeight
					let pageData = canvas.toDataURL('image/jpeg', 1.0)
					let PDF = new JsPDF('', 'pt', 'a4')
					// if (leftHeight < pageHeight) {
					PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
					// } else {
					//   while (leftHeight > 0) {
					//     PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
					//     leftHeight -= pageHeight
					//     position -= 841.89
					//     if (leftHeight > 0) {
					//       PDF.addPage()
					//     }
					//   }
					// }
					PDF.save(title + '.pdf')
				})
			},
			pdfClick() {
				// console.log(this.selectedPatientObj.brID)
				this.handlebackstate()
				this.handlerembrID(this.selectedPatientObj.brID)
				
				// const params = {
				// 	ID: this.selectedPatientObj.patientId,
				// 	name: this.brName,
				// 	nl: this.csnyr,
				// 	sex: this.brXb,
				// 	lx: this.type,
				// 	bc: this.qzrq,
				// 	dataQuality: this.dataQuality,
				// 	suggestion: this.suggestion,
				// 	date: this.date,
				// 	ysName: this.ysName,
				// 	ysID: this.ysID,
				// 	begintime: this.begintime,
				// 	endtime: this.endtime,
				// }
			
				const params = {
					ID: this.selectedPatientObj.brID,
					xm: this.userNameParase,
					nl: this.selectedPatientObj.nl,
					xb: this.selectedPatientObj.xb,
					lx: this.selectedPatientObj.hblx,
					bc: this.qzrq,
					dataQuality: this.dataQuality,
					suggestion: this.suggestion,
					date: this.date,
					ysName: this.dockerObj.name,
					ysID: this.dockerObj.userId,
					begintime: getFromateTime(this.begintime, true),
					endtime: getFromateTime(this.endtime, true),
					
					HypoCount:this.HypoCount,
					TBR:this.TBR*100,
					TIR:this.TIR*100,
					TAR:this.TAR*100,
					GMI:this.GMI,
					MSG:this.MSG,
					CV:this.CV*100,
					SD:this.SD,
					
					dayHypoCount:this.dayHypoCount,
					dayTBR:this.dayTBR*100,
					dayTIR:this.dayTIR*100,
					dayTAR:this.dayTAR*100,
					dayGMI:this.dayGMI,
					dayMSG:this.dayMSG,
					dayCV:this.dayCV*100,
					daySD:this.daySD,
					
					nightHypoCount:this.nightHypoCount,
					nightTBR:this.nightTBR*100,
					nightTIR:this.nightTIR*100,
					nightTAR:this.nightTAR*100,
					nightGMI:this.nightGMI,
					nightMSG:this.nightMSG,
					nightCV:this.nightCV*100,
					nightSD:this.nightSD,
				}
		
				this.$router.push({
					path: '/chart/pdf',
					query: {
						params
					}
				})
				// this.$route.query.params.name
			},
			downloadPDF(ele, pdfName) {
				ele = document.querySelector('#chec')
				let eleW = ele.offsetWidth // 获得该容器的宽
				let eleH = ele.offsetHeight // 获得该容器的高
				let eleOffsetTop = ele.offsetTop // 获得该容器到文档顶部的距离
				let eleOffsetLeft = ele.offsetLeft // 获得该容器到文档最左的距离

				var canvas = document.createElement('canvas')
				var abs = 0

				let win_in = document.documentElement.clientWidth || document.body.clientWidth // 获得当前可视窗口的宽度（不包含滚动条）
				let win_out = window.innerWidth // 获得当前窗口的宽度（包含滚动条）

				if (win_out > win_in) {
					// abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
					abs = (win_out - win_in) / 2 // 获得滚动条宽度的一半
					// console.log(a, '新abs');
				}
				canvas.width = eleW * 2 // 将画布宽&&高放大两倍
				canvas.height = eleH * 2

				var context = canvas.getContext('2d')
				context.scale(2, 2)
				context.translate(-eleOffsetLeft - abs, -eleOffsetTop)
				// 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
				// translate的时候，要把这个差值去掉

				// html2canvas(element).then( (canvas)=>{ //报错
				// html2canvas(element[0]).then( (canvas)=>{
				html2canvas(ele, {
					dpi: 300,
					// allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
					useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
				}).then((canvas) => {
					var contentWidth = canvas.width
					var contentHeight = canvas.height
					//一页pdf显示html页面生成的canvas高度;
					var pageHeight = contentWidth / 592.28 * 841.89
					//未生成pdf的html页面高度
					var leftHeight = contentHeight
					//页面偏移
					var position = 0
					//a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
					var imgWidth = 595.28
					var imgHeight = 595.28 / contentWidth * contentHeight
					var pageData = canvas.toDataURL('image/jpeg', 1.0)
					var pdf = new JsPDF('', 'pt', 'a4')
					//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
					//当内容未超过pdf一页显示的范围，无需分页
					if (leftHeight < pageHeight) {
						//在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
						pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
						// pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
					} else { // 分页
						while (leftHeight > 0) {
							pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
							leftHeight -= pageHeight
							position -= 841.89
							//避免添加空白页
							if (leftHeight > 0) {
								pdf.addPage()
							}
						}
					}
					//可动态生成
					pdf.save(pdfName)
				})
			},
			handleWindowPrint() {

				//浏览器方式打印
				//ele 为  css ID选择器
				//fileName    生成的PDF文件名
				//去除页面不必要的 head 标签内  内容， 避免影响打印页 ， title 为保存为 pdf 的文件时的 文件名
				document.head.innerHTML = '<meta charset="utf-8">\n' +
					' <title> ' + 'fileName' + '</title>\n' +
					' <link rel="shortcut icon" href="http://192.168.29.50:8081/favicon.ico" type="image/x-icon" />\n' +
					' <meta name="format-detection" content="telephone=no">\n' +
					' <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">\n' +
					' <meta name="viewport" content="width=device-width,initial-scale=1.0">\n' +
					' <link rel="stylesheet" href="./static/css/contract.css"/>'; //生成pdf的外部css样式

				//要打印的 内容 html
				document.body.innerHTML = document.querySelector('chec').outerHTML;

				// window.print();

				//转异步 等待dom元素渲染（样式）完毕在打印
				setTimeout(() => {
					//打印
					window.print();
					//刷新页面
					window.location.reload();
				}, 200)

			},
			layuiRender() {
				layui.use('laydate', function() {
					var laydate = layui.laydate;
					var ins22 = laydate.render({
						elem: '#test-limit1',
						min: '2020-01-02',
						max: '2020-01-04',
						ready: function() {
							ins22.hint('日期可选值设定在 <br> 2020-01-02 到 2020-01-04');
						}
					});
					var ins21 = laydate.render({
						elem: '#test-limit2',
						min: '2020-01-02',
						max: '2020-01-04',
						ready: function() {
							ins22.hint('日期可选值设定在 <br> 2020-01-02 到 2020-01-04');
						}
					});
					laydate.render({
						elem: '#test-limit3',
						type: 'time',
						min: '00:00:00',
						max: '23:59:59',
						btns: ['clear', 'confirm']
					});
					laydate.render({
						elem: '#test-limit4',
						type: 'time',
						min: '00:00:00',
						max: '23:59:59',
						btns: ['clear', 'confirm']
					});
				})
			}
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.myVar01)
			clearInterval(this.myVar02)
			next();
		},

	}
</script>

<style scoped="scoped" lang="less">
#chec {
  margin-top: 7vh;

  .cgmCheckBox {
    display: flex;
    width: 84vw;
    margin-left: 14vw;
    height:54vw;

    .cgmCheckBoxLeft {

      flex: 5;
      // background-color: red;
      margin-top: 1vh;
      height: 92vh;
      width: 56vw;

      .personalMessageBanner {
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 7vh;
        padding: 0 30px 23px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .personalMessageItem {
          display: inline-block;
          margin-right: 50px;
          padding-top: 2vh;
          font-size: 1.1rem;
          font-weight: bold;
          color: #504DA6;

          .itemTitle {
            font-weight: normal;
            color: #7B7B7B;
          }
        }
      }

      .cgmChartBanner {
        position: relative;
        width: 100%;
        // height: 480px;
        height: 53vh;
        // margin: 30px 0;
        margin-top: 1vh;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .timeSelected {
          position: absolute;
          top: 34px;
          right: 124px;
          z-index: 1;

          span {
            display: inline-block;
            width: 62px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #504CA5;
            cursor: pointer;

            &:hover {
              border: 1px solid #ECECF2;
              border-radius: 8px;
              box-sizing: border-box;
            }
          }

          .atclick {
            border: 1px solid #ECECF2;
            border-radius: 8px;
            box-sizing: border-box;
          }
        }

        .checkcharts {
          width: 100%;
          // height: 460px;
          height: 100%;
        }
      }

      .cgmResult {
        display: flex;
        width: 100%;
        // 2023.1.9王昕卓修改高度
        // height: 23vh;
        height: 21rem;
        margin-top: 1vh;

        .dataQualityBanner {
          position: relative;
          flex: 4;
          // height: 275px;
          padding: 19px 30px;
          background-color: #FFFFFF;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          box-sizing: border-box;

          .dataQualityTitle {
            font-size: 1.2rem;
            font-weight: bold;
            color: #707070;
          }

          .dataQualityContent {
            margin-top: 1vh;
            font-size: 14px;
            color: #333333;

            span {
              display: block;
              font-size: 20px;
              font-weight: bold;
              color: #504DA6;
            }
          }

          .dataQualityChart {
            // width: 2rem;
            // height: 2rem;
            // width: 100px;
            // height: 100px;
            // background-color: pink;
            position: absolute;
            bottom: 18vh;
            right: 1vw;
            // transform: translateY(-50%);
          }
          .tableBox {
            margin-top: 5vh;
            border: 1px solid #C8C7F7;
            border-radius: 10px;
            overflow: hidden;

            .dataQualityAssessTable {
              width: 100%;
              text-align: center;
              font-size: 0.8rem;

              th {
                padding: 0.5vh 0;
                background-color: #504DA6;
                color: #FFFFFF;

                &:nth-child(1) {
                  border-right: 1px solid #C8C7F7;
                }
              }

              td {
                padding: 0.5vh 0;
                color: #333333;
                border-top: 1px solid #C8C7F7;

                &:nth-child(2n + 1) {
                  border-right: 1px solid #C8C7F7;
                }
              }

            }
          }
        }

        .cgmUseAdviceBanner {
          flex: 4;
          // height: 275px;
          margin: 0 1vw;
          padding: 19px 26px;
          background-color: #FFFFFF;
          // border: 1px solid #504DA6;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          box-sizing: border-box;

          .cgmUseAdviceTitle {
            font-size: 1.2rem;
            font-weight: bold;
            color: #707070;
          }

          .tableBox {
            margin-top: 1vh;
            border: 1px solid #C8C7F7;
            border-radius: 10px;
            overflow: hidden;

            .dataQualityAssessTable {
              width: 100%;
              text-align: center;
              font-size: 0.8rem;

              th {
                padding: 0.5vh 0;
                background-color: #504DA6;
                color: #FFFFFF;

                &:nth-child(1) {
                  border-right: 1px solid #C8C7F7;
                }
              }

              td {
                padding: 0.5vh 0;
                color: #333333;
                border-top: 1px solid #C8C7F7;

                  border-right: 1px solid #C8C7F7;

              }

            }
          }

          .cgmUseAdviceContent {
            margin-top: 1vh;
            font-size: 14px;
            font-weight: bold;
            color: #504DA6;
          }
        }

        /*					.dataQualityAssessBanner {
                    flex: 4;
                    // height: 275px;

                    padding: 19px 19px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    box-sizing: border-box;

                    .dataQualityAssessTitle {
                      font-size: 1.2rem;
                      font-weight: bold;
                      color: #707070;
                    }


                  }*/
      }
    }

    .cgmCheckBoxRight {
      flex: 2;
      margin-top: 1vh;
      height: 92vh;
      // margin-left: 22px;
      margin-left: 1vw;
      width: 28vw;

      .patientSelectBanner {
        display: flex;
        align-items: center;
        width: 100%;
        // height: 121px;
        // 2023.1.9王昕卓修改高度
        // height: 13vh;
        height: 7vh;
        padding: 0 30px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .patientSelectTitle {
          display: inline-block;
          font-size: 1.1rem;
          color: #707070;
        }

        .patientSelectcontent {
          width: 56%;
          height: 42px;
          margin: 0 0 0 10px;
          padding-left: 8px;
          background: #F5F5FA;
          border: 1px solid #504DA6;
          border-radius: 4px;
          color: #8181A5;
          font-weight: normal;
        }
      }

      .dataQueryBanner {
        position: relative;
        width: 100%;
        // height: 480px;
        // 2023.1.9王昕卓修改高度
        // height: 65vh;
        height: 33.4rem;
        // margin: 30px 0;
        margin-top: 1vh;
        background-color: #FFFFFF;
        box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
        border-radius: 0.625rem;
        box-sizing: border-box;

        .dataQueryTitle {
          // margin-bottom: 44px;
          padding: 1.25rem 1.875rem;
          // padding: 5px 30px;
          font-size: 1.2rem;
          font-weight: bold;
          color: #707070;
        }

        .dataQueryCalendar {
          width: 100%;

          border: none;
        }

        .buttonList {
          position: absolute;
          top: 1.5625rem;
          // top: 5px;
          right: 1.875rem;

          .buttonItem {
            padding: 0.25rem 0.4375rem;
            background: rgba(245, 245, 250, 0.4);
            border: 0.0625rem solid #504DA6;
            border-radius: 0.25rem;
            color: #8181A5;
            cursor: pointer;

            &:last-child {
              margin-left: 0.75rem;
              background: #504DA6;
              color: #FFFFFF;
            }

          }

        }

        .dataQueryTitleButton {
          position: absolute;
          right: 0;
          bottom: 0.6875rem;
          width: 3.125rem;
          height: 1.875rem;
          margin-left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          z-index: 1;
        }
      }

      .doctorMessageBanner {
        position: relative;
        width: 100%;
        // height: 275px;
        // 2023.1.9王昕卓修改高度
        // height: 23vh;
        height: 21rem;
        margin-top: 1vh;
        padding: 19px 21px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        box-sizing: border-box;

        .checkButtonBox {
          position: absolute;
          top: 19px;
          right: 30px;

          .checkButton {
            padding: 4px 7px;
            background: rgba(245, 245, 250, 0.4);
            border: 1px solid #504DA6;
            border-radius: 4px;
            color: #8181A5;
            cursor: pointer;

            &:first-child {
              margin-right: 12px;
              background: #504DA6;
              color: #FFFFFF;
            }
          }
        }

        .doctorMessageTitle {
          font-size: 1.2rem;
          font-weight: bold;
          color: #707070;

          span {
            display: block;
            margin: 0.5vh 0 30px;
            font-size: 0.9rem;
            font-weight: normal;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
//引入Vue
import Vuex from 'vuex'
//引入Vuex，相当于最大的仓库
import getters from './getters'
Vue.use(Vuex);
//使用插件
import user from './user.js'
const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default

  return modules
}, {})

const store = new Vuex.Store({
  state:{     //lhh
  	connectstate:false  ,
  	patientID:0,
	currentID:0,
	isback:false,
	titleflag:false,
	// 2023.3.21王昕卓添加
	doctorID:0,
	// 2022.12.10
	// phone:0,
  },
  mutations:{
		transfer_docID(state,DoctorID){
			state.doctorID=DoctorID
		},
		changestate(state){
			state.connectstate = true    //lhh
		},	
		transfer_brID(state,brID){
			state.patientID=brID
		} ,
		// 2022.12.10
		// remphone(state,phone){
		// 	state.phone=phone
		// } ,
		IDreset(state){
			state.patientID = 0
		},
		statereset(state){
			state.connectstate = false
		},
		remcurrentID(state, brID){
			state.currentID = brID
		},
		backstate(state){
			state.isback = true
		},
		changetitleflag(state){
			state.titleflag= !state.titleflag
		}
      },
  modules,
  getters,
  user
})

export default store

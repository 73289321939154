import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/User/AuthLogin',
	// url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/onloadweb/${phone}/${password}',
	// url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/onloadweb/' + this.phone + '/' + this.password,
    method: 'get',
    params: data
  })
}

export function getInfo(params) {
  return request({
    url: '/JwtAPI/AuthAsync/Me',
    method: 'post',
    data: params
  })
}

export function reqGetCode(data) {
  return request({
    // url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/phoneCode/' + '/' + phoneNumber,
    url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/phoneCode/${phoneNumber}',
	method: 'get',
    params: data
  })
}
// export const reqGetCode = (phone)=>request({url: '/xuetangjiance/sys-doctor-user/phoneCode/${phoneNumber}',method:'get'})
// // 获取验证码

//获取指定id的用户信息
export function GetUserInfor(id) {
  return request({
    url:'/getUserInfo',
    method:'get',
    data:{
      Id:id
    }
  })
}
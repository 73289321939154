import Cookies from 'js-cookie'

const TokenKey = 'Token_CGMPRO'

const ysNameKey = 'ysName'
const ysId = 'ysId'

export function getToken() {
  return Cookies.get(TokenKey)
}

export   function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setUserInfoCookies(userObj) {
   Cookies.set(ysNameKey, userObj.name)
    Cookies.set(ysId, userObj.id)
}

<template>
    <div id="chart"  class="charts"></div>
  </template>
  <script>
  import *as echarts from "echarts"
  import axios from 'axios';
  export default {
    data() {
        return {
            TWOid:'',
            TWOTBR1: [],
            TWOTBR2: [],
            TWOTAR1: [],
            TWOTAR2: [],
            TWOTIR: [],
            TWOMEDIAN: [],
            TWOTAR: [],
            TWOTBR: [],
            //startday:'2027-06-12T00:00:00',

            
        }
    },
    props: [
        'userid',
         'startday'
  ],
    created() {



    },
    mounted() {
    this.getMidCompute2();
    
    },
  
    methods: {
      ADD() {
       
        this.drawGraph(this.TWOTBR1,this.TWOTBR2,this.TWOTAR1,this.TWOTAR2,this.TWOMEDIAN)
  
  
      },
      getMidCompute2() {
       
  
            axios.get('http://js.aps.ink:8090/getMidCompute', {
              // 传递的参数
              params: {
                id: this.userid,
                startday:this.startday
              }
              // 回调函数,一定要使用箭头函数,不然this的指向不是vue示例
            }).then(res => {
              // 如果状态为400,说明登录失败
              if (res.data.status === 400) {
              } else {
                // 登录成功提示

                // 将数据转换为只有两位小数的数字
                this.TWOTAR2[0] = parseFloat(res.data.tbr2percent.toFixed(2));
                this.TWOTAR1[0] = parseFloat(res.data.tbr1percent.toFixed(2));
                this.TWOMEDIAN[0] = parseFloat(res.data.tirpercent.toFixed(2));
                this.TWOTBR1[0] = parseFloat(res.data.tar1percent.toFixed(2));
                this.TWOTBR2[0] = parseFloat(res.data.tar2percent.toFixed(2));
                //console.log(this.TWOTBR2)
                this.ADD()
              }
            }).catch(error => {
              
            });
         
    },
      
  
  
  
  
  
    
  
      drawGraph(TWOTBR1,TWOTBR2,TWOTAR1,TWOTAR2,TWOMEDIAN) {
        const chart = echarts.init(document.getElementById('chart'));
  
        const option = {
            title: {
                
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['极低', '低', '正常', '高', '极高'],
                show: false
            },
            xAxis: {
                type: 'category',
                data: ['APG分析']
            },
            yAxis: {
                splitLine: {
            show: false  // 隐藏 y 轴上的网格线
            },
                type: 'value',
                axisLine:{show:false},
                axisLabel:{
                formatter:function(value){
                    if(value === 180 || value === 250 )  {
                    return value;
                } else {
                    return '';
                }
            
                }
                },
                min: 0,
                max: 100,
            },
            markLine: {
            data: [
            {
            yAxis: 50, // 在 y 轴上标记数值为 50 的水平线
            lineStyle: {
            color: 'red', // 标线的颜色
            },
            label: {
            show: true,
            position: 'start', // 标签显示在标线末端
            formatter: 'Critical Value', // 标签的内容
            },
            },
            ],
            },
            series: [
                {
                    name: '极低',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        show: false,
                        position: 'inside'
                    },
                    data: TWOTAR2  // 这里是标度的数据
                },
                {
                    name: '低',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        show: false,
                        position: 'inside'
                    },
                    data: TWOTAR1 // 这里是百分比的数据
                },
                {
                    name: '正常',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        show: false,
                        position: 'inside'
                    },
                    data: TWOMEDIAN  // 这里是百分比的数据
                },
                {
                    name: '高',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        show: false,
                        position: 'right',
                        formatter:'{c},{d}%'
                    },
                    data: TWOTBR1 // 这里是百分比的数据
                },
                {
                    name: '极高',
                    type: 'bar',
                    stack: '总量',
                    label: {
                        show: false,
                        position: 'inside'
                    },
                    data: TWOTBR2 // 这里是百分比的数据
                },
            ]
        }
        chart.clear();
        chart.setOption(option,true);
      }
    }
}
  
  


  </script>
  <style lang="less" scoped>
  .charts{
    height:375px;
    width:125px;
    position: absolute;
            top: 50%;
            left: 23%;
            transform: translate(-50%, -50%);
  
  }
  </style>
import request from '@/utils/request'

/**
 * 获取字典下拉数据 
 * @param {*} params 
 */
export function GetAllMasterList(params) {
  return request({
    url: '/api/Master/GetAllMasterList',
    method: 'post',
    data: params
  })
}

/**
 * 获取字典列表 (分页)
 * @param {*} params 
 */
export function GetMasterList(params) {
  return request({
    url: '/api/Master/GetMasterList',
    method: 'post',
    data: params
  })
}

/**
 * 提交字典信息
 * @param {*} params 
 */
export function SubmitMaster(params) {
  return request({
    url: '/api/Master/SubmitMaster',
    method: 'post',
    data: params
  })
}


// 配置路由相关的信息
import VueRouter from 'vue-router'
import Vue from 'vue'
import signin from '../Sign-in.vue'

import App from '../App.vue'

// const indexview = () => import('@/views/index.vue')
// const Manage = () => import('@/components/manage.vue')
// const About = () => import('@/components/about.vue')
// const page1 = () => import('@/components/cgmprediction.vue')
// const page2 = () => import('@/components/cgmcheck.vue')
// const precisesrch = () => import('@/components/precisesrch.vue')
// const modify = () => import('@/components/modify.vue')
// const Pdf = () => import('@/components/pdf.vue')

// 嵌套路由在history模式下懒加载会失效,所以正常导入
import indexview from '../views/index.vue'
import page1 from '../components/cgmprediction.vue'
import page2 from '../components/cgmcheck.vue'
import About from '../components/about.vue'
import Manage from '../components/manage.vue'
import precisesrch from '../components/precisesrch.vue'
import modify from '../components/modify.vue'
import Pdf from '../components/pdf.vue'
import sysDictionary from '../components/sysDictionary.vue'
import doctorManage from '../components/doctorManage.vue'
import register from '../components/register.vue'
import kongfu from '../components/kongfu.vue'//2023/4/05 杨振宇添加空腹血糖检测界面
import pdfForKongfu from '../components/pdforkongfuf.vue'//2023/4/05 杨振宇添加空腹血糖检测界面
import shiyan from '../components/shiyan.vue'//2023/4/05 杨振宇添加空腹血糖检测界面
import pdf2 from '../components/pdf2.vue'//2023/4/05 杨振宇添加空腹血糖检测界面
import pdf3 from '../components/pdf3.vue'//2023/4/05 杨振宇添加空腹血糖检测界面
// 王昕卓2022/11/7添加用户注册界面



import Hellow from '../components/HelloWorld.vue'

//1.通过Vue.use(插件),暗转插件
Vue.use(VueRouter)

const routes = [
	{
		path:'/App',
		component:App
	},
	{
		path:'/',
		// redirect: signin
		component:signin
	},
	{
		path:'/register',
		component:register
	},
	// 王昕卓2022/11/7添加用户注册界面
	{
		path:'/chart',
		component:indexview,
		redirect: '/chart/precisesrch',
		children:[
			{
				path:'manage',
				component:Manage
			},
			{
				path:'assessment',
				component:About
			},
			{
				path: 'alert',
				component: page1
			},
			{
				path: 'check',
				component: page2
			},
			{
				path: 'precisesrch',
				component: precisesrch
			},
			{
				path:'modify/:id',
				component:modify
			},
			{
				path:'pdf',
				component:Pdf
			},
			{
				path:'doctorManage',
				component:doctorManage
			},
			{
				path:'sysDictionary',
				component:sysDictionary
			},
			{
				path:'kongfu',
				component:kongfu
			},
			{
				path:'pdfForKongfu',
				component:pdfForKongfu
			},
			{
				path:'shiyan',
				component:shiyan
			},
			{
				path:'pdf2',
				component:pdf2
			},
			{
				path:'pdf3',
				component:pdf3
			},
		]
	},
]
//2.创建路由对象
const router = new VueRouter({
	// mode: 'history',
	//部署Tomcat
	// base:'/cgm/',
	//配置路由和组件之间的映射
	routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


//将router对象传入到vue实例
export default router
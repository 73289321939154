import axios from 'axios'
import Vue from 'vue'

// const request = function(config,success,failure){
// 	//1.create axios instance
// 	const instance = axios.create({
// 		baseURL:'http://106.12.203.41:8080',
		
// 	})
	
// 	//send real net request
// 	instance(config)
// 	.then(res => {
// 		success(res);
// 	}).catch(err => {
// 		failure(err);
// 	})
// }

// export default request;

// const request = (config,success,failure) => {
// 	//1.create axios instance
// 	const instance = axios.create({
// 		baseURL:'http://106.12.203.41:8080',
		
// 	})
	
// 	//send real net request
// 	instance(config)
// 	.then(res => {
// 		success(res);
// 	}).catch(err => {
// 		failure(err);
// 	})
	
// 	return request;
// }
// export default function(Vue) {
//     //添加全局API
//     Vue.prototype.request = {
//         request
//     }
// }

export function request(config,success,failure) {
	//1.create axios instance创建axios的实例
	const instance = axios.create({
		baseURL: 'http://121.36.225.90:8080',
		// baseURL: 'http://8.142.84.145:8080',              // 'http://182.92.61.253:8080',       //http://106.12.203.41:8080     182.92.219.201:8080
		// 设置基础的url配置项，这样接口处的url前面就不用写url:'http://js.aps.ink:8000/api/home'，直接写成 url:'/api/home', 就可以了
	})
	
	//send real net request
	instance(config)
	.then(res => {
		success(res);
	}).catch(err => {
		failure(err);
	})
}
// // 电弧历史数据折线图 
// export const get_historyData = (tail, beginTime,endTime) =>ajax(dianhu_baseUrl_data+tail,{beginTime,endTime},'POST')
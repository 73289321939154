<template>
	<div class="maincontain">
		<!-- 导入试剂库模板dialog -->
		<div class="muban-dialog-div">
			<el-dialog :visible.sync="dialogVisible" width="50%" show-close>  
				<div slot="title" style="background: #504DA6; color: white">
					<div style="padding: 15px 20px">{{ dialogtitle[dialog_Index] }}</div>
				</div>
				<div>

					<el-upload :action="updataUrl" class="upload-demo" ref="upload" :multiple="true" show-file-list
						name="file" :on-remove="handleRemove" :on-change="(file, fileList) => {handleUploadChange(file, fileList, this.dialog_Index)}"
						:on-exceed="handleExceed" :file-list="fileList" :auto-upload="false">
						<el-button icon="el-icon-document" size="small" type="primary" slot="trigger"
							style="width: 120px; height: 35px;background-color: #504DA6;border: none;"
							:loading="fullscreenLoading">选取文件</el-button>
						<el-button size="small" icon="el-icon-upload" type="primary"
							style="width: 120px; height: 35px; margin-left: 20px;background-color: #504DA6;border: none;"
							@click="submitUpload" :loading="fullscreenLoading" :disabled="scdisabled">开始上传</el-button>
						<el-button size="small" icon="el-icon-s-data" type="primary" v-if="dialog_Index==1||dialog_Index==2"
							style="width: 120px; height: 35px; margin-left: 20px;background-color: #504DA6;border: none;"
							@click="dataAnalysis" :loading="fullscreenLoading" :disabled="!analysisObj">数据分析</el-button>
						<div slot="tip" class="el-upload__tip" v-if="dialog_Index==0||dialog_Index==1">
							上传文件只能为excel文件，且为csv格式
						</div>
						<div slot="tip" class="el-upload__tip" v-else>
							上传文件应为文本文件，且为txt格式
							<!-- 1.14 ,新加FGM-->
						</div>
					</el-upload>
				</div>
				<!-- <div style="margin-top: 20px">
		      <el-progress :text-inside="true" :stroke-width="17" :percentage="this.percentage"></el-progress>
		    </div> -->

				<div style="height: 500px; margin-top: 15px" v-if="opeateType=='upData'">
					<el-collapse accordion>
						<el-collapse-item title="模板数据录入说明" style="font-size: 16px">   
							<p v-if="dialog_Index==0">
								1. 文件非空校验<br />
								2. 文件格式校验 (csv格式校验)<br />
								3. 标题列名称校验 (名称与排列顺序)<br />
								4. 数据ID重复校验 (ID已存在无法添加)<br />
								5. 模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br />
								<!-- 1.
								模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败。<br />
								2.
								文件名必须以br开头, 以cgm结尾。<br />
								3. 上传文件只能为excel文件，且为csv格式。<br /> -->
							</p>
							<p v-else-if="dialog_Index==1">
								1. 文件非空校验<br />
								2. 文件名校验 (以br开头, 以cgm结尾)<br />
								3. 文件格式校验 (csv格式校验)<br />
								4. 标题列名称校验 (名称与排列顺序)<br />
								5. 数据表重名校验 (表名已存在无法添加)<br />
								6.
								模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br />

							</p>
							<p v-else>
								1. 文件非空校验<br />
								2. 文件格式校验 (txt格式校验)<br />
								<!-- 文本格式校验,1.14 -->
								3. 标题列名称校验 (名称与排列顺序)<br />
								4. 数据表重名校验 (表名已存在无法添加)<br />
								5.模板数据录入需要安照模板内的示例格式填写，格式不一致会导致数据录入失败<br />
								
							</p>
						</el-collapse-item>
					</el-collapse>
				</div>
				<div v-else>
					<div id="Pdf" class="printeBox">
						<h1 align="middle">数据质量分析报告</h1>
						<el-table highlight-current-row v-loading="loading" :data="analysisObj.data"
							style="margin-top: 15px; width: 100%" align="center"
							@selection-change="handleSelectionChange">
							<!-- <el-table-column type="selection" width="55" /> -->
							<el-table-column prop="FaultType" label="故障类型" align="center" />
							<el-table-column prop="Count" label="故障个数" align="center" />
							<el-table-column prop="Percentage" label="正确率(%)" align="center" />


						</el-table>
						<div style="margin-top: 20px; border:1px solid #000000;"></div>
						<div style="margin: 15px;">
							<div style="color: #000000;text-align: left;">数据文件信息:</div>
							<div style="color: #000000;text-align: left;margin-top: 5px;">{{analysisObj.fileName}}</div>

						</div>
					</div>
					<div class="reprotButton">
						<button class="tool-ButtonStyle" @click="back">返回</button>
						<button class="tool-ButtonStyle" @click="printPdf">下载PDF</button>
					</div>
				</div>
			</el-dialog>
		</div>
		<div class="dialog_diy">
			<el-dialog :visible.sync="dialogVisible1" width="70%" style="margin-top: -50px;" @close="closeDialog">
				<div slot="title" style="background:#504DA6;color: white;">
					<div style="padding:15px 20px;">{{ dialogtitle[dialog_Index] }}</div>
				</div>

				<el-form ref="selectedObj" :model="selectedObj" :rules="rules" label-width="150px">
					<el-row>
						<el-divider content-position="left">基本信息{{selectedObj.userId}}</el-divider>
						<el-col :span="12">
							<!-- <el-form-item label="工号:" v-if="selectedObj.userId">
								<el-input v-model="selectedObj.userId" disabled style="width: 90%;" />
							</el-form-item> -->

							<el-form-item label="姓名:" required prop="xm">
								<el-input v-model="selectedObj.xm" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item>

							<!-- <el-form-item label="性别:" required prop="phone">
								<el-input v-model="selectedObj.sex" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item> -->
							<el-form-item label="性别:">
								<el-select v-model="selectedObj.xb" :disabled="isView" style="width: 90%;"
									placeholder="请选择状态">

									<el-option v-for="item in sexList" :key="item.id" :label="item.label"
										:disabled="isView" :value="item.id" />
								</el-select>
							</el-form-item>
							<!-- <el-form-item label="患病类型:" required prop="title">
								<el-input v-model="selectedObj.title" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item> -->
							<el-form-item label="患病类型:">
								<el-select v-model="selectedObj.hblx" :disabled="isView" style="width: 90%;"
									placeholder="请选择类型">

									<el-option v-for="item in diseaseTypeList" :key="item.id" :label="item.label"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="身高:">
								<el-input type="number" v-model="selectedObj.sg" placeholder="请输入..."
									:disabled="isView" style="width: 90%;" />
							</el-form-item>
							<el-form-item label="体重:">
								<el-input type="number" v-model="selectedObj.weight" placeholder="请输入..."
									:disabled="isView" style="width: 90%;" />
							</el-form-item>
							<el-form-item label="BMI:">
								<el-input type="number" v-model="selectedObj.bmi" placeholder="请输入..."
									:disabled="isView" style="width: 90%;" />
							</el-form-item>
							<el-form-item label="出生日期:">

								<!-- <el-date-picker format="yyyy-MM-dd" style="width: 90%;" :disabled="isView" v-model="selectedObj.birthday"
									type="date" placeholder="选择日期">
								</el-date-picker> -->
								<el-date-picker format="yyyy-MM-dd" :disabled="isView" style="width: 90%;"
									v-model="selectedObj.csnyr" type="datetime" placeholder="选择日期时间" align="right">
								</el-date-picker>
							</el-form-item>


						</el-col>
						<el-col :span="12">
							<el-form-item label="电话:">
								<el-input v-model="selectedObj.phone" :disabled="isView" style="width: 90%;" />
							<!-- <el-form-item label="电话:" required prop="phone">
								<el-input v-model="selectedObj.phone" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" /> -->
							</el-form-item>
							<el-form-item label="治疗方案:">
								<el-input v-model="selectedObj.zlfaxz" :disabled="isView" style="width: 90%;" />
							</el-form-item>

							<el-form-item label="系统类型:">
								<el-input v-model="selectedObj.cgmlx" :disabled="isView" style="width: 90%;" />
							</el-form-item>

							<el-form-item label="控制要求:">
								<el-input v-model="selectedObj.xtkzyqxz" :disabled="isView"
									style="width: 90%;" />
							</el-form-item>


							<el-form-item label="是否饮酒:">
								<el-select v-model="selectedObj.sfyj" :disabled="isView" style="width: 90%;"
									placeholder="请选择">

									<el-option v-for="item in isDrinkList" :key="item.id" :label="item.label"
										:value="item.id" />
								</el-select>
							</el-form-item>

							<el-form-item label="并发症:">
								<el-input v-model="selectedObj.bfz" :disabled="isView" style="width: 90%;" />
							</el-form-item>



							<el-form-item label="病程:">
								<el-date-picker format="yyyy-MM-dd hh:mm:ss" :disabled="isView" style="width: 90%;"
									v-model="selectedObj.qzrq" type="datetime" placeholder="选择日期时间"
									align="right">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin-top: 40px; text-align: center">
						<el-form :inline="true" class="form-inline">
							<el-form-item>
								<el-button style="background-color: #504DA6;border: none;" type="success"
									:loading="isInstoreing" @click="updataInfo">提交
								</el-button>
							</el-form-item>

							<el-form-item>
								<el-button @click="closePopup">取 消</el-button>
							</el-form-item>
						</el-form>
					</el-row>
				</el-form>
			</el-dialog>
		</div>

		<div class="chaxunPageSearchBanner">
			<div class="inputConditions">
				<div class="searchInputConditions">
					输入条件：
					<div class="searchStyle">
						<input type="text" v-model="formInline.searchInfo" class="searchInputStyle"
							placeholder="请输入待查询ID/姓名" @keyup.enter="precisionsearch" />
						<input type="button" class="searchButtonStyle" value="精准搜索" @click="precisionsearch" />
					</div>
				</div>
			</div>
			<div class="dashedStyle"></div>
			<div class="selectionConditions">
				<div>选择条件：</div>
				<ul>
					<li>
						<span class="selectionTitleStyle">性别</span>
						<span class="selectionContentStyle">
							<input id="man" class="checkboxStyle" v-model="xb" value="男" type="checkbox" /><label
								for="man">男</label>
							<input id="woman" class="checkboxStyle" v-model="xb" value="女" type="checkbox" /><label
								for="woman">女</label>
						</span>
					</li>
					<!-- <li>
						<span class="selectionTitleStyle">降糖治疗</span>
						<span class="selectionContentStyle">
							<input id="hypoglycemicTherapyYes" class="checkboxStyle" type="checkbox"
								v-model="jtzl" value="是" /><label for="hypoglycemicTherapyYes">是</label>
							<input id="hypoglycemicTherapyNo" class="checkboxStyle" type="checkbox"
								v-model="jtzl" value="否" /><label for="hypoglycemicTherapyNo">否</label>
						</span>
					</li> -->
					<li>
						<span class="selectionTitleStyle">持续血糖检测系统选择</span>
						<span class="selectionContentStyle">
							<input id="abbottInstant" class="checkboxStyle" type="checkbox" v-model="cgmlx"
								value="雅培瞬感" /><label for="abbottInstant">FGM</label>
							<!-- <input id="dekang" class="checkboxStyle" type="checkbox" v-model="cgmlx"
								value="德康" /><label for="dekang">德康</label>    -->
							<input id="medtronic" class="checkboxStyle" type="checkbox" v-model="cgmlx"
								value="美敦力" /><label for="medtronic">CGM</label>
						</span>
					</li>
					<!-- <li>
						<span class="selectionTitleStyle"><span>治疗方案</span></span>
						<span class="selectionContentStyle">
							<input id="treatmentPlan1" class="checkboxStyle" type="checkbox" v-model="zlfaxz"
								value="胰岛素泵治疗" /><label for="treatmentPlan1">胰岛素泵治疗</label>
							<input id="treatmentPlan2" class="checkboxStyle" type="checkbox" v-model="zlfaxz"
								value="胰岛素三短一长" /><label for="treatmentPlan2">胰岛素三短一长</label>
						</span>
					</li> -->
					<li>
						<span class="selectionTitleStyle">患病类型</span>
						<span class="selectionContentStyle">
							<input id="diseaseType1" class="checkboxStyle" type="checkbox" v-model="hblx"
								value="1" /><label for="diseaseType1">1型糖尿病</label>
							<input id="diseaseType2" class="checkboxStyle" type="checkbox" v-model="hblx"
								value="2" /><label for="diseaseType2">2型糖尿病</label>
							<input id="diseaseType3" class="checkboxStyle" type="checkbox" v-model="hblx"
								value="3" /><label for="diseaseType3">特殊类型</label>
						</span>
					</li>
					<!-- <li>
						<span class="selectionTitleStyle">治疗方案是否变化</span>
						<span class="selectionContentStyle">
							<input id="treatmentPlanChangeYes" class="checkboxStyle" type="checkbox"
								v-model="zlfasfbh" value="是" /><label
								for="treatmentPlanChangeYes">是</label>
							<input id="treatmentPlanChangeNo" class="checkboxStyle" type="checkbox"
								v-model="zlfasfbh" value="否" /><label for="treatmentPlanChangeNo">否</label>
						</span>
					</li> -->
				</ul>
			</div>
			<div class="dashedStyle"></div>

			<div class="intervalCondition-center">

				<span class="textStyle1">区间条件：</span>
				<select class="searchSelect" name="condition" v-model="logicalrule">
					<option value="AND">And</option>
					<option value="OR">Or</option>
					<option value="NOT">Not</option>
				</select>
				<input class="searchSelect" type="text" v-model="lowerlim" placeholder="条件下限" />
				<span class="textStyle2">到</span>
				<input class="searchSelect" type="text" v-model="upperlim" placeholder="条件上限" />
				<select class="searchSelect" name="condition" v-model="condition">
					<!-- <option value="1">年龄</option>  -->   //2.27
					<option value="2">身高</option>
					<option value="3">体重</option>
					<option value="4">BMI</option>
					<!-- <option value="5">病程</option> -->
				</select>
				<button class="queryResetButtonStyle" @click="conditionsearch">查询</button>
				<button class="queryResetButtonStyle" @click="reset">重置</button>
			</div>
			<div class="tool-style">
				<button class="tool-ButtonStyle" @click="openPopup(null,0)">导入患者基础信息</button>
				<button class="tool-ButtonStyle" @click="openPopup(null,1)">导入患者CGM信息</button>
				<button class="tool-ButtonStyle" @click="openPopup(null,2)">导入患者FGM信息</button>
				<button class="tool-ButtonStyle" style="margin-right: 1%;float:right;background: rgb(238,78,8);" @click="downloadExcel">导出患者数据文件</button>
			</div>
		</div>
		<div class="chaxunPageArticalBanner">
			<el-table highlight-current-row v-loading="loading" :data="tableData" style="width: 100%" align="center"
				@selection-change="handleSelectionChange">
				<!-- <el-table-column type="selection" width="55" /> -->
				<el-table-column prop="brID" label="ID" align="center" width="100" />
				<el-table-column prop="userNameParase" label="姓名" align="center" width="80" />
				<el-table-column prop="sexName" label="性别" align="center" width="60" />
				<el-table-column prop="ages" label="年龄" align="center" width="60" />
				<el-table-column prop="diseaseTypeName" label="患病类型" align="center" width="100" />
				<el-table-column prop="qzrq" label="病程(年)" align="center" width="80" />
				<el-table-column prop="sg" label="身高(cm)" align="center" />
				<el-table-column prop="tz" label="体重(Kg)" align="center" />
				<el-table-column prop="bmi" label="BMI" align="center" width="80" />
				<el-table-column prop="zlfaxz" label="治疗方案" align="center" width="100" />
				<el-table-column prop="zlfasfbh" label="治疗方案是否变化" align="center" width="150" />
				<el-table-column prop="cgmlx" label="系统类型" align="center" width="100" />
				<el-table-column prop="xtkzyqxz" label="控制要求" align="center" width="100" />
				<el-table-column prop="jtzl" label="降糖治疗" align="center" width="100" />
				<el-table-column prop="sfyj" label="是否饮酒" align="center" />
				<el-table-column prop="bfz" label="并发症" align="center" />
				<el-table-column prop="phone" label="电话" align="center" />
				<el-table-column fixed="right" label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="info" size="small" plain @click="openPopup(scope.row,3)">编辑</el-button>
						<el-button type="warning" size="small" plain @click="delItem(scope.row,false)">数据分析</el-button>
						<el-button type="success" size="small" plain @click="kongItem(scope.row,false)">空腹血糖分析</el-button>
						<!-- <el-button type="success" size="small" plain @click="delItem(scope.row,true)">分析结果</el-button> -->
					</template>
				</el-table-column>

			</el-table>
			<div class="page tableBottomTool">
				<el-pagination background :current-page.sync="currentPage" layout="total,prev, pager, next, sizes"
					:total="total" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize"
					@current-change="handleCurrentChange" @size-change="handleSizeChange" />
			</div>
		</div>

	</div>
</template>

<script>
	import request from '@/utils/request'
	import axios from 'axios'
	
	import JSZip from 'jszip'
	import FileSaver from 'file-saver'
	
	import html2canvas from 'html2canvas'
	import JsPDF from 'jspdf'
	import {
		GetDoctorUserList,
		DelDoctorUser
	} from '../api/doctor.js'
	import {
		GetPatientUserList,
		ImportPatientUserCgmData,
		BatchImportPatientUserData,
		SubmitPatientUser,
	} from '../api/patient.js'
	import {
		GetAllMasterList
	} from '../api/system.js'
	import {
		diseaseTypeOption,
		SexOption,
		isDrink
	} from '../common/resource.js'


	import {
		pageMixin
	} from '../mixins/index'
	import {
		timeParas,
		getFromateTime,
		getAge,
		nameParas,
		retainDecimal
	} from '../utils'
	export default {
		name: "precisesrch",
		mixins: [pageMixin],
		data() {
			return {
				// urlArray:[], //2.23 lhh
				zipurl:'',
				docPhone:"13844226982", // 1.13 lhh
				updataUrl: "",
				opeateType: 'upData',
				fullscreenLoading: false,
				Percentage: 0, //进度条
				fileList: [], //导入文件，文件存储位置
				file: "",
				filename: "",
				dialog_Index: 0,
				dialogtitle: ['上传患者基础信息', "上传患者CGM数据","上传患者FGM数据", '编辑患者信息'], //表单头名 ,新增FGM,1.14,lhh
				sexList: SexOption,
				diseaseTypeList: diseaseTypeOption,
				isDrinkList: isDrink,

				scdisabled: true, //导入模板表单选取文件按钮开关
				analysisDisabled: true,

				isView: false,
				dialogVisible: false,
				dialogVisible1: false,
				isInstoreing: false,

				analysisObj: {data:[],fileName:''},

				upperlim: '',
				lowerlim: '',
				
				precisiondata: false,     //lhh新加条件判断，用于精准搜索和条件搜索及其渲染
				conditiondata: false, 
				select_url:'',
				allData:[],
				copy_allData:[],
				// newbackObj:{Percentage:[],
				// 			FaultType:[],
				// 			Count:[]
				// 		},


				formInline: {
					searchInfo: '',

				},
				selectedObj: {},
				departmentOptions: [],
				dialogMask: null,

				rules: {
					xm: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}, ],
					phone: [{
						required: true,
						message: "请输入电话",
						trigger: "blur"
					}, ],

				},

				xm: '',
				passWord: '',
				// sex:'',
				hblx: '',
				sg: '',
				tz: '',
				bmi: '',
				zlfaxz: '',
				cgmlx: [],
				xtkzyqxz: '',
				// isDrinkWine:'',
				bfz: '',
				phone: '',
				qzrq: '2020-11-11',     //bc
				csnyr: '2020-11-11',
				rcyy: '',
				// 弹窗数据end

				logicalrule: 'AND',
				condition: '2',  //2.27
				lowerlim: '',
				upperlim: '',
				xb: [],
				sfyj: [],
				jtzl: [],
				/* 增加降糖治疗的初值 */
				cgmlx: [],
				kzxq: [],
				hblx: [],
				zlfaxz: [],
				zlfasfbh: [],
				/* 增加治疗方案是否变化的初值 */
				xlsdata: '',
				brID: '',
				user: [],
				bl_Id: '',
				ysID: '10001',
				ysName: '',

// 		   "brID": "病人ID", 
//         "xm": "姓名",
//         "csnyr": "出生年月日",
//         "sg": "身高",
//         "tz": "体重",
//         "bmi": "BMI",
//         "xb": "性别",
//         "hblx": "患病类型",
//         "qzrq": "确诊日期",
//         "zlfaxz": "治疗方案选择",
//         "xtkzyqxz": "血糖控制要求选择",
//         "sfyj": "是否饮酒",
//         "bfz": "并发症",
//         "rcyy": "",
//         "jtzl": "降糖治疗",
//         "zlfasfbh": "治疗方案是否变化",
//         "phone": "电话",
//         "passWord": "密码",
//         "cgmlx": "CGM类型"

			}
		},


		async mounted() {
			this.pageSize = 5
			// let param = {
			// 	"Type": "100",
			// 	"Name": "",
			// }
			// GetAllMasterList(param).then(res => {
			// 	if (res.success) {
			// 		this.departmentOptions = res.data;
			// 	}

			// });
			await this.init();
		},
		methods: {
			wrong(){
				this.$message({
				    message: '功能正在开发……'
				});
			},
			handlechangetitleflag(){
					this.$store.commit('changetitleflag');
					return this.$store.state.titleflag
			},
			handleconnect(){
					// 触发mutation
					this.$store.commit('changestate');     //lhh
					return this.$store.state.connectstate
			        },
			handlebrID(transfID){
					this.$store.commit('transfer_brID',transfID); 
					return this.$store.state.patientID
			},		
			back() {
				this.opeateType = 'upData'
			},
			dataAnalysis() {
				this.opeateType = 'dateAnalysis'
				
			},
			printPdf() {
				var title = this.analysisObj.fileName + '数据分析结果'
		
				html2canvas(document.querySelector('#Pdf'), {
					// html2canvas 的作用就是允许让我们直接在用户浏览器上拍摄网页或其部分的“截图”。
					// 文档对象模型 Document引用的 querySelector() 方法返回文档中与指定选择器或选择器组
					// (这里为id选择器Pdf)匹配的第一个Element对象。如果找不到匹配项，则返回null。
					allowTaint: true,  //是否允许不同源的图片污染画布
					useCORS: true,   // 是否尝试使用 CORS 从服务器加载图片;两者都是解决跨域问题的方式
					// 图片生成显示不全，只有半截或者空白
					// 在有滚动的页面，产生了滚动条后，生成的图片可能会只有一半或者空白。答案只有一个，那就是要
					// 把html2canvas的配置项参数中，scrollx，scrolly都设置为0，问题就解决了。
					// scrollX: 0,
					// scrollY: -280,

				}).then(function(canvas) {
					let imgWidth = 505
					let imgHeight = imgWidth / canvas.width * canvas.height
					let pageData = canvas.toDataURL('image/jpeg', 1.0)
					let PDF = new JsPDF('p', 'pt', 'a4')
					PDF.addImage(pageData, 'JPEG', 45, 30, imgWidth, imgHeight)
					PDF.save(title + '.pdf')
				})
			},

			//开始上传
			async submitUpload() {    // lhh,1.14
				this.opeateType = 'upData'
				this.analysisObj = {
					data: [],
					fileName: ''
				};
				// this.$refs.upload.submit();

				if (this.fileList.length <= 0) {
					this.$notify.error({
						title: "错误",
						message: "上传文件不能为空",
					});
					return false;
				} else {

					this.fullscreenLoading = true;
					const formData = new FormData();
					let fileRawList = this.fileList.map(item => {
						// map() 方法返回一个新数组，数组中的元素为原始数组元素调用函数处理后的值。
						// map() 方法按照原始数组元素顺序依次处理元素。
						return item.raw
						// String.raw() 是一个模板字符串的标签函数,用来获取一个模板字符串的原始字符串
					})
					//注意上传多文件时用此方法创建参数
					// if (fileRawList.length > 0) {
					// 	fileRawList.forEach(item => {  //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
					// 		formData.append("multipartFiles", item);  //type类型为file
					// 	})
					// }
					// console.log("hhhhhhhhhhh",formData.getAll("multipartFiles"))
					// console.log("什么玩意这是",formData)
					// formData.forEach((value, key) => {
					//      console.log(key,value);   
					// })      //lhh
					
					let opeateSuccess = false;
					let opeateMsg = '';
					if (this.dialog_Index == 0) {

						// const {
						// 	success,
						// 	msg,
						// 	// errorColNumber
						// } = await BatchImportPatientUserData(formData);   lhh改
						if (fileRawList.length > 0) {
							fileRawList.forEach(item => {  //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
								formData.append("multipartFile", item);  //type类型为file 
							})
						}
						const {
							success,
							message
						} = await request({
						  url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/insertbasicinfofromcsv',
						  method: 'post',
						  // body: {"multipartFile":formData},
						  // headers: { "Content-Type": "multipart/form-data" },
						  data:formData,
						  params:{DoctorID:this.$store.state.doctorID}
						});
						opeateSuccess = success;
						opeateMsg = message
					} else {
						// const {
						// 	success,
						// 	msg,
						// 	data2,
						// 	data
						// 	// errorColNumber
						// } = await ImportPatientUserCgmData(formData);
						if (fileRawList.length > 0) {
							fileRawList.forEach(item => {  //forEach方法用于调用数组的每个元素，并将元素传递给回调函数。
								formData.append("multipartFiles", item);  //type类型为file
							})
						}
						let selecturl="";    // CGM数据和FGM数据接口判断,lhh ,1.14
						if (this.dialog_Index == 1){
							selecturl = 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFilebyCsv';
						} else {
							selecturl = 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/basic-information/getMulFileByTxt';
						}
						const {
							success,
							message,
							data
						} = await request({
						  url: selecturl,
						  method: 'post',
						  data: formData,
						  // params:{doctorPhone:this.docPhone}   // lhh,1.14
						  params:{doctorID:this.$store.state.doctorID}
						});
						// console.log("返回数据",data.res)       //lhh改
						// if (data.res) {
						// 	this.analysisObj.fileName = data2;
						// }
						let datalist = [];
						if (data.res && data.res.FaultType && data.res.FaultType.length > 0) {  //lhh改
							for (let i = 0; i < data.res.FaultType.length; i++) {
								let obj = {
									FaultType: data.res.FaultType[i],
									Count: data.res.Count[i],
									Percentage: data.res.Percentage[i] ? ((retainDecimal(data.res.Percentage[i] * 100,
										2)) + '%') : ('0%')
								}

								datalist.push(obj)
							}
						}
						this.analysisObj.data = datalist;  
						opeateSuccess = success;
						opeateMsg = message
					}
					this.fullscreenLoading = false;
					if (opeateSuccess) {
						this.Percentage = 100;

						this.$alert(`${opeateMsg}`, `导入成功`, {
							confirmButtonText: "确定",
							type: "success",
						});
						this.fileList = [];
						this.scdisabled = true;
					} else {
						this.Percentage = 0;

						// messageBoxMutiple(
						//   `<div>Excel 表格错误行号：${errorColNumber}</div><div>错误标题列名：${errorTitle}</div><div>错误说明信息：${msg}</div>`,
						//   `导入错误`, {
						//     confirmButtonText: "确定",
						//     type: "error"
						//   }
						// );
						this.$alert(`${opeateMsg}`, `错误`, {
							confirmButtonText: "确定",
							type: "danger",
						});
						this.fileList = [];
						this.scdisabled = true;
					}
				}
			},
			//验证选择文件数量  上传文件数量限制如,multiple:limit="3",lhh,1.14
			handleExceed() {
				this.$notify.error({
					title: "错误",
					message: "校验错误,超出上传文件数量限制",
				});
			},
			//验证文件,新加txt格式校验,lhh,1.14
			handleUploadChange(file, fileList, isFGMdata){
				this.opeateType = 'upData'
				if (isFGMdata==2) {
					if (!/\.(txt|TXT)$/.test(file.name)) {
						this.$notify.error({
							title: "错误",
							message: "上传文件应为文本文件,且为txt格式",  //txt校验,lhh,1.14
						});
						this.fileList = [];
						this.file = "";
						return false;
					}
				} else {
					// if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
					if (!/\.(csv|CSV)$/.test(file.name)) {
						this.$notify.error({
							title: "错误",
							message: "上传文件只能为excel文件，且为csv格式",
						});
						this.fileList = [];
						this.file = "";
						return false;
					}
				}
				// // if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
				// if (!/\.(csv|CSV)$/.test(file.name)) {
				// 	this.$notify.error({
				// 		title: "错误",
				// 		message: "上传文件只能为excel文件，且为csv格式",
				// 	});
				// 	this.fileList = [];
				// 	this.file = "";
				// 	return false;
				// }
				this.scdisabled = false;
				this.Percentage = 0;

				this.fileList = fileList;
				this.file = file.raw;
				this.filename = file.name;
			},
			//删除当前文件
			handleRemove(row) {
				let index = this.fileList.findIndex(item => {
					return item.uid == row.uid
				})
				if (index >= 0) {
					this.fileList.splice(index, 1)
				}
				if (this.fileList.length <= 0) {
					this.scdisabled = true;
				}

			},




			closeDialog() {
				this.dialogVisible = false;
				this.dialogVisible1 = false;
			},
			async reset() {   // 重置，lhh
				this.formInline = {
					searchInfo: "",
				};
				// document.getElementById("").reset()
				this.logicalrule= 'AND',
				this.condition= '2',
				this.lowerlim= '',
				this.upperlim= '',
				this.xb= [],
				this.sfyj= [],   // 可略，lhh
				this.jtzl= [],
				this.cgmlx= [],
				this.kzxq= [],   // 可略，lhh
				this.hblx= [],
				this.zlfaxz= [],
				this.zlfasfbh= [],
				
				this.precisiondata= false,   //lhh
				this.conditiondata= false,
				this.currentPage= 1,
				this.pageSize= 5,
				await this.init();
			},
			async precisionsearch() {    //新方法定义，用于精准搜索。lhh
			
			    // console.log(this.precisiondata)
				this.brID=this.formInline.searchInfo,
				// console.log(this.brID)
				
				// for(var i=0;i<this.copy_allData.length;i++){     //copy_allData,存放全部患者数据,lhh
				// 	if (this.copy_allData[i].brID == this.brID) {
				// 		this.precisiondata= true;
				// 		await this.init();
				// 		break;
				// 	}
				// }
				
				this.precisiondata= true;
				await this.init();
				// console.log("精准搜索测试",this.precisiondata)
				
				// this.brID=this.formInline.searchInfo,
				// console.log(this.brID)
				// const {data} = await request({
				// url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/'+this.brID,
				// // url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/1182903',
				// method: 'get'
				// });   //lhh, 整合进init里面
				// console.log(data.res)
			},
			// searchParamsMaker() {  // lhh改
			// 	let postdata = {
			// 		"xb": this.xb,
			// 		"cgmlx": this.cgmlx,
			// 		"hblx": this.hblx,
			// 		"jtzl": this.jtzl,
			// 		"zlfa": this.zlfaxz,
			// 		"zlfasfbh": this.zlfasfbh,
					
			// 		"nl": [],  //
			// 		"sg": [],
			// 		"tz": [],
			// 		"bmi": [],
			// 		"bc": [],
					
			// 		// "csnyr": [],  
			// 		// "qzrq": [] ,
					
			// 		"kzxq": this.kzxq,   //控制需求，暂不显示,lhh
			// 		"sfyj": this.sfyj,   //是否饮酒，暂不显示
					
					
			// 	}
			// 	if (this.logicalrule && this.lowerlim && this.upperlim) {
			// 		// alert('您所选择的区间条件不全！')
			// 		switch (this.condition) {
			// 			case '1':
			// // 				var date1 = this.timestap_agetodate(this.lowerlim)
			// // 				var date2 = this.timestap_agetodate(this.upperlim)
			// // 				// date1 = "\"" + date1 + "-12-31\""
			// // 				// date2 = "\"" + date2 + "-01-01\""
			// // 				// postdata.nl[0] = [this.logicalrule, date2, date1]
			
			// 				postdata.nl[0] = [this.logicalrule, this.lowerlim, this.upperlim];
			// 				// console.log(postdata.nl[0])
			// 				break;
			// 			case '2':
			// 				postdata.sg[0] = [this.logicalrule, this.lowerlim, this.upperlim];
			// 				// console.log(postdata.sg)
			// 				break;
			// 			case '3':
			// 				postdata.tz[0] = [this.logicalrule, this.lowerlim, this.upperlim];
			// 				// console.log(postdata.tz)					
			// 				break;
			// 			case '4':
			// 				postdata.bmi[0] = [this.logicalrule, this.lowerlim, this.upperlim];
			// 				// console.log(postdata.bmi)
			// 				break;
			// 				// 吴佳琪 2021-4-22 周浩辉start
			// 			case '5':
			// 				var date1 = this.timestap_agetodate(this.lowerlim)
			// 				var date2 = this.timestap_agetodate(this.upperlim)
			// 				// date1 = "\"" + date1 + "-12-31\""
			// 				// date2 = "\"" + date2 + "-01-01\""
			// 				// postdata.bc[0] = [this.logicalrule, date2, date1]
			
			// 				date1 = date1 + "-12-31"+" "+"23:59:59"
			// 				date2 = date2 + "-01-01"+" "+"00:00:00"  // qzrq,lhh
			// 				postdata.bc[0] = [this.logicalrule, date2, date1]
			// 				break;
			// 				//end
			// 			default:
			// 				;
			// 				break;
			// 		}
			// 	}
			// 	return postdata
			// },
			async conditionsearch() {	
				
				this.xlsdata = '';
				let response = ''
				
				let postdata = {
					"xb": this.xb,
					"cgmxz": this.cgmlx,
					"hblx": this.hblx,	
					"jtzl": this.jtzl,
					"zlfa": this.zlfa,
					"zlfasfbh": this.zlfasfbh,

					"kzxq": this.kzxq,   //控制需求，暂不显示,lhh
					"sfyj": this.sfyj,   //是否饮酒，暂不显示
				
					"sg": [],
					"nl": [],
					"tz": [],
					"bmi": [],
					"bc": []
				}
				if (this.logicalrule && this.lowerlim && this.upperlim) {
					// alert('您所选择的区间条件不全！')
					switch (this.condition) {
						case '1':
							// var date1 = this.timestap_agetodate(this.lowerlim)
							// var date2 = this.timestap_agetodate(this.upperlim)
							// date1 = "\"" + date1 + "-12-31\""
							// date2 = "\"" + date2 + "-01-01\""
							// postdata.nl[0] = [this.logicalrule, date2, date1]
							postdata.nl[0] = [this.logicalrule, this.lowerlim, this.upperlim];   //1.14,原来data参数不用了，
							// console.log(postdata.nl[0])
							break;
						case '2':
							postdata.sg[0] = [this.logicalrule, this.lowerlim, this.upperlim];
							// console.log(postdata.sg)
							break;
						case '3':
							postdata.tz[0] = [this.logicalrule, this.lowerlim, this.upperlim];
							// console.log(postdata.tz)					
							break;
						case '4':
							postdata.bmi[0] = [this.logicalrule, this.lowerlim, this.upperlim];
							// console.log(postdata.bmi)
							break;
							// 吴佳琪 2021-4-22 周浩辉start
						case '5':
							// var date1 = this.timestap_agetodate(this.lowerlim)
							// var date2 = this.timestap_agetodate(this.upperlim)
							// date1 = "\"" + date1 + "-12-31\""
							// date2 = "\"" + date2 + "-01-01\""
							// postdata.bc[0] = [this.logicalrule, date2, date1]
							postdata.bc[0] = [this.logicalrule, this.lowerlim, this.upperlim]   //1.14
							break;
							//end
						default:
							;
							break;
					}
				}
				// const {data} = await request({
				// 	url:'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxxbycondition/'+this.ysID,
				// 	method: 'post',
				// 	ContentType: 'application/json',
				// 	data: postdata,
				// 	})
				// 	console.log(this.ysID)
				// 	// console.log(this.searchParamsMaker().nl.length)
				// 	console.log(this.cgmlx)
				// 	console.log("hhhhhh",postdata)
				// 	console.log(data.res)
				if(this.formInline.searchInfo=='') {
					
					const {data} = await request({
						// 2023.3.21王昕卓修改接口
						// url:'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxxbycondition/'+this.ysID,
						url:'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxxbycondition/'+this.$store.state.doctorID,
						// url:'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxxbycondition/10001',
						method: 'post',
						ContentType: 'application/json',
						data: postdata
					});
					if (data.res.length) {
					this.xlsdata= data.res,
					// console.log(this.xlsdata)
					this.conditiondata= true,
					this.currentPage = 1,  // lhh
					await this.init();
					} else {
						alert('未查询到满足条件的患者')
					}
					
					// 	if (response.data.data.res.length) {
					// 		// console.log(response.data)
					// 		this.page.pageData = response.data.data.res
					// 		this.page.rows = response.data.data.res.length
					// 		this.page.pageTotal = Math.ceil(this.page.rows / this.page.pageSize)
					// 		for (var i = 0; i < response.data.data.res.length; i++) {
					// 			response.data.data.res[i].csnyr = this.timestap_ageCac(this.page.pageData[i]
					// 				.csnyr) // 函数传参别忘了index:[i]
					// 			response.data.data.res[i].qzrq = this.timestap_bcCac(this.page.pageData[i].qzrq)
					// 		}
					// 		var lastpage = this.page.rows % this.page.pageSize // 取余函数
					// 		this.pageJump(1, response.data.data.res) /* 吴佳琪修改返回的参数20210416*/
					// 		// console.log(Math.ceil(9/2))                                                     // 向上取整函数
					// 		// console.log(this.xlsdata)
					// 	} else {
					// 		alert('未查询到满足条件的患者')
					// 	}
					
					
					// }).catch(error => {
					// 	console.log(error)
					// });
				} else {
					await this.precisionsearch();    //精准搜索优先于条件搜索，lhh
				}	
			},
			timestap_agetodate(age) {
				const date1 = new Date()
				var Y1 = date1.getFullYear();
				var M1 = date1.getMonth() + 1  // 获取当前月份(0-11,0代表1月)

				return (Y1 - age)
			},
			async init() {
				this.tableData = [];
				this.loading = true;
				// const {
				// 	data,
				// 	count
				// } = await GetPatientUserList(this.searchParamsMaker()); //解构赋值;lhh修改
				
				// console.log(this.ysID)  lhh改
				
				// console.log(this.precisiondata)
				if(this.precisiondata){    //lhh
					// this.brID=this.formInline.searchInfo,
					// console.log(this.brID)
					this.select_url='http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/'+this.brID   //精准搜索，lhh
					// console.log(this.select_url)
				} else {
					// this.select_url='http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/'+this.ysID   //全部病人数据，lhh
					// 2023.3.21王昕卓修改接口
					this.select_url='http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/'+this.$store.state.doctorID
					// this.select_url='http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/10001'
				}
					// const {data} = await request({
					// url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/'+this.brID,
					// // url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/1182903',
					// method: 'get'
					// });
					// console.log(data.res)
				
				const {data} = await request({
				// url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/'+this.ysID,
				url: this.select_url,
				method: 'get',
				});
				
				// request({
				// url: 'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/sys-patient-user/selectallpatient/10001',
				// method: 'get',
				// }).then((response) => {
				// 	console.log(response.data.res)
				// 	console.log(response.data.res.length)
				// });  lhh
				
				// console.log("精准/全部(get)请求的数据",data.res)
				// if (data) {  lhh修改
				if(this.precisiondata) {       //lhh
					// this.allData=[],
					// this.allData.push(data.res)
					if (data.res.length==0){
						alert("患者ID或姓名输入有误!请检查后重新输入")     //lhh
					}
					this.allData = data.res
					// console.log("精准搜索结果", this.allData)
				} else if(this.conditiondata){
					this.allData = this.xlsdata
					// console.log("条件搜索结果", this.allData)
				}else {
					this.allData = data.res,   
					this.copy_allData=data.res  // copy_allData为当前医生下所有患者信息,lhh
					// console.log("全部患者信息", this.allData)
				}	
				// const allData = data.res   
				if (this.allData) {
					this.allData.forEach(item => {

						if (item.duration) {      //1.14
							item.qzrq = item.duration
							}
						// if (item.qzrq) {
						// 	item.qzrq = timeParas(item.qzrq, 'time');
						// }
						if (item.age) {    //1.14
							item.ages = item.age
							}
							
						// if (item.approveTime) {
						// 	item.approveTime = timeParas(item.approveTime);
						// }  lhh改，这段没用了
						item.userNameParase = nameParas(item.xm)

						if (item.hblx) {
							let index = diseaseTypeOption.findIndex(T => {
								return T.id == item.hblx
							})
							if (index >= 0) {
								item.diseaseTypeName = diseaseTypeOption[index].label
							} else {
								item.diseaseTypeName = item.hblx
							}
						}
						if (item.xb) {
							let index = SexOption.findIndex(T => {
								return T.id == item.xb
							})
							if (index >= 0) {
								item.sexName = SexOption[index].label
							} else {
								item.sexName = item.xb
							}
						}


					})
					// this.tableData = data;   lhh修改
					this.tableData = this.allData.slice(
					(this.currentPage-1)*this.pageSize,
					this.currentPage*this.pageSize
					);   
					this.total = this.allData.length;
					//分页，当前表格展示的指定pageSize条数据
				}
				// this.total = count;    lhh修改
				// console.log(allData.length)
				this.loading = false;

			},
			delItem(obj,analysis_result) {    //lhh,再加一个参数判断两个按钮哪个触发
				// console.log('修改title',this.$store.state.titleflag)
				
				// this.handlechangetitleflag()    //1.14
				
				// console.log(this.$store.state.titleflag)
				// this.handlechangetitleflag()
				// console.log(this.$store.state.titleflag)
				
				this.$router.push({ path: '/chart/check' })
				this.$message({
					type: "success",
					message: '您已成功跳转',
				});
				// console.log("跳转了哈哈哈哈",obj.brID)
				// console.log("vuex测试",this.$store.state.connect)
				// console.log("vuex测试",this.$store.state.patientID)
				this.handlebrID(obj.brID)
				// console.log(this.handlebrID(obj.brID))
				
				if(analysis_result){
					this.handleconnect()
					// console.log("改变控制开关connectstate",this.handleconnect())	
				}
				
				
				// if(分析结果){
				// 	判断参数=true,   //vuex， state管理
					// this.$message({
					// 	type: "success",
					// 	message: '您已进入分析结果页面',
					// });
				// }
				
				// 进入cgmcheck中后,若判断参数=true
				// 				=>执行clickpdf()
				
				
				
				// return
				// this.$confirm("您确定要删除所选医生信息吗？", "提示", {
				// 	confirmButtonText: "确定",
				// 	cancelButtonText: "取消",
				// 	type: "warning",
				// }).then(async () => {
				// 	let param = {
				// 		"UserId": obj.userId
				// 	}
				// 	const {
				// 		success,
				// 		msg
				// 	} = await DelDoctorUser(param);
				// 	if (success) {

				// 		let index = this.tableData.findIndex(item => {
				// 			return item.id === obj.id
				// 		})
				// 		if (index >= 0) {
				// 			this.tableData.splice(index, 1)

				// 			this.$message({
				// 				type: "success",
				// 				message: msg,
				// 			});
				// 		}
				// 	} else {
				// 		this.$message({
				// 			type: "error",
				// 			message: msg,
				// 		});
				// 	}
				// });

			},

//杨振宇2023/4/05添加空腹界面跳转
      kongItem(obj,analysis_result) {    //lhh,再加一个参数判断两个按钮哪个触发
        // console.log('修改title',this.$store.state.titleflag)

        // this.handlechangetitleflag()    //1.14

        // console.log(this.$store.state.titleflag)
        // this.handlechangetitleflag()
        // console.log(this.$store.state.titleflag)

        this.$router.push({ path: '/chart/kongfu' })
        this.$message({
          type: "success",
          message: '您已成功跳转',
        });
        // console.log("跳转了哈哈哈哈",obj.brID)
        // console.log("vuex测试",this.$store.state.connect)
        // console.log("vuex测试",this.$store.state.patientID)
        this.handlebrID(obj.brID)
        // console.log(this.handlebrID(obj.brID))

        if(analysis_result){
          this.handleconnect(),
              console.log("改变控制开关connectstate",this.handleconnect())
        }




      },


			openPopup(e, type) {
				if (e) {
					this.selectedObj = JSON.parse(JSON.stringify(e))
				}
				this.dialog_Index = type  //type=2,FGMdata,lhh,1.14
				this.file = '';
				this.fileList = []
				this.analysisDisabled = true;
				this.scdisabled = true; //导入模板表单选取文件按钮开关
				this.fullscreenLoading = false
				this.analysisObj = {
					data:[],
					fileName:''
				}
				
				if (type < 3) {
					this.dialogVisible = true;
				} else {
					this.dialogVisible1 = true;
				}

			},
			getFile(url){   // 1.13,lhh
			  return new Promise((resolve, reject) => {
			    request({
			      method: "get",
			      url,
				  // params: {
				  // 			"docPhone":this.docPhone,
				  // 		},
				  // headers:{ 'Content-Type': 'application/json; application/octet-stream'},
			      responseType: "blob"
			    })
			      .then(data => {
					const blob = new Blob([data],
					{type : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});  // 查看浏览器请求中content-type解决乱码问题，2.23，lhh
			        resolve(blob);
			      })
			      .catch(error => {
			        reject(error.toString());
			      });
			  });
			},
			
			
			// // 直接多文件下载
			// downloadFile(url){   // 普通遍历下载，1.13,lhh
			//  const iframe = document.createElement("iframe");
			//   iframe.style.display = "none";  // 防止影响页面
			//   iframe.style.height = 0;  // 防止影响页面
			//   iframe.src = url;
			//   document.body.appendChild(iframe);
			//   setTimeout(()=>{
			//     iframe.remove();
			//   }, 5 * 60 * 1000);
			// },
			// downloadExcel(){     //多文件下载打包，1.13，lhh
			
			// 不用的
			// let aLink = document.createElement('a') //a标签,  单文件下载，lhh
			// // aLink.href ="http://8.142.84.145:8080/download/101/10025-2023-01-13-17-23-51/1420565.xlsx"; // 设置下载地址
			// aLink.download = "hhh.xlsx";// 设置下载文件名,这里不起作用？
			// document.body.appendChild(aLink);
			// aLink.click();
			// document.body.removeChild(aLink);
			
			
			// 	// console.log(this.docPhone);
			// 	const {data} = await request({
			// 		url:'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/br-cgmjl/getDataFromDocUseExcel/{docPhone}',
			// 		method: 'get',
			// 		params: {
			// 			"docPhone":this.docPhone,
			// 		},
			// 	});
			// 	// console.log("怎么回事小老弟",data.res);  
				
				
			// 	data.res.forEach(item => {    // 遍历下载，数量不超过10个，1.13,lhh
			// 	    this.downloadFile(item)
			// 	})
			
			async downloadExcel(zipurl){
			
			this.doctorID = this.$store.state.doctorID;
			const {data} = await request({
				// 2023.3.21王昕卓添加doctorID
				url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/br-cgmjl/getDataFromDocUseExcel/{docid}',
				method:'get',
				params: {
						"docID":this.doctorID
				},
			});
			// console.log(data.res);
			zipurl = data.res;
			window.location.href = zipurl;
			
			
			// 	// this.geturlList()
			// 	var urlArray=[]  // 2.23,清空，否则影响下次下载，因为下载地址包含当前时间戳
			// 	this.doctorID = this.$store.state.doctorID;
			// 	const {data} = await request({
			// 			url:'http://8.142.84.145:8080/newxuetangjiance/xuetangjiance/br-cgmjl/getDataFromDocUseExcel/{docid}',
			// 			method: 'get',
			// 			params: {
			// 				// "docPhone":this.docPhone,
			// 				// "docID":this.$store.state.doctorID
			// 				"docID":10025
			// 			},
			// 		});
					
			// 	data.res.forEach(item => {    
			// 		let t = item.replaceAll("http://8.142.84.145:8080","/api")  // 2.23,对应proxy跨域替换
			// 		// console.log(t)
			// 		// this.getFile(t)
			// 		// let tt = t.split("/")
			// 		// let proxyUrl="/"+tt[1]+"/"+tt[tt.length-1]
					
			// 		// console.log("proxyUrl",proxyUrl)
			// 		urlArray.push(t)
			// 		// console.log("在这里",urlArray)	
			// 		})		
			
			// 	// console.log(this.urlArray)
			// 	// console.log(this.urlArray instanceof Array)
				
			// 	// const hhhhh = [
			// 	//         '/api/1426870.xlsx',
			// 	//         '/api/1429899.xlsx']    //测试文件列表，2.23,lhh
			// 	// console.log(hhhhh)		
				
			// 	const zip = new JSZip()
			//     const cache = {}
			//     const promises = []
			//     urlArray.forEach(item => {
			//         const promise = this.getFile(item).then(data => {  // 下载文件, 并存成ArrayBuffer对象
			// 			const arr_name = item.split("/")
			// 			const file_name = arr_name[arr_name.length - 1]  // 获取文件名
			// 			zip.file(file_name, data, { binary: true })  // 逐个添加文件
			// 			cache[file_name] = data
			//         })
			//         promises.push(promise)
			//       })
			
			//     Promise.all(promises).then(() => {
			//         zip.generateAsync({type:"blob"}).then(content => { // 生成二进制流
			//           FileSaver.saveAs(content, "打包下载.zip") // 利用file-saver保存文件
			//         })
			//     })
			},
			async updataInfo() {

				this.$refs["selectedObj"].validate(async (valid) => {
				// ref 加在子组件上，用this.$refs.（ref值） 获取到的是组件实例，可以使用组件的
				// 所有方法。在使用方法的时候直接this.$refs.（ref值）.方法（） 就可以使用了。
				// https://blog.csdn.net/wh710107079/article/details/88243638
					if (valid) {
						let birth = null

						if (this.selectedObj.csnyr) {

							// birth = getFromateTime(this.selectedObj;  
							birth = getFromateTime(this.selectedObj.csnyr, true);   //lhh改，必须带时分秒才行，加上true即可
						}
						let course = null
						if (this.selectedObj.qzrq) {
							// course = getFromateTime(this.selectedObj.courseOfDisease, true).replace(' ',
							// 	'T');  
							course = getFromateTime(this.selectedObj.qzrq, true);   //lhh改，去掉replace
						}

						let param = {
							"brID": this.selectedObj.brID ? this.selectedObj.brID :
								null,
							"xm": this.selectedObj.xm,
							"phone": this.selectedObj.phone,
							"csnyr": birth,
							"sg": this.selectedObj.sg,
							"tz": this.selectedObj.tz,
							"bmi": this.selectedObj.bmi,
							"xb": this.selectedObj.xb,
							"hblx": this.selectedObj.hblx, 
							"cgmlx": this.selectedObj.cgmlx, 
							"qzrq": course,
							"zlfaxz": this.selectedObj.zlfaxz,
							"xtkzyqxz": this.selectedObj.xtkzyqxz,
							"sfyj": this.selectedObj.sfyj,
							"bfz": this.selectedObj.bfz,
							"jtzl": this.selectedObj.jtzl,
							"zlfasfbh": this.selectedObj.zlfasfbh,
							"rcyy": this.selectedObj.rcyy,
							"passWord": this.selectedObj.passWord,
						}

						// console.log(param)
						
						// const {
						// 	success,
						// 	msg
						// } = await SubmitPatientUser(param);  lhh改
						const {
							success,
							msg
						} = await request({
							url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/sys-patient-user/updatagrxx',
							method: 'post',
							data: param
						  });   //lhh改
						  
						if (success) {
							this.$message({
								type: "success",
								message: "提交成功啦",   //lhh改，因为后台返回message为空
							});
							this.init();
							this.closePopup();
						} else {
							this.$notify.error({
								title: "失败",
								message: "提交失败，好像哪里出问题了",  //lhh改
								duration: 3000,
							});
						}




					}

				});


			},
			closePopup() {
				this.closeDialog();

				Object.keys(this.selectedObj).forEach(key => (this.selectedObj[key] = ''));

			},

		},

	}
</script>

<style scoped="scoped" lang="less">
	.selectionConditions {  //lhh
		ul {
			width: 100%;
			// height: 120px;
			margin-top: 14px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			flex-direction:column;  //lhh,让元素沿垂直主轴从上到下垂直排列
		}
	}
	.printeBox {
		width: 100%;
		min-height: 250px;
		margin-top: 15px;
	}

	.muban-dialog-div {
		::v-deep .el-dialog__wrapper {

			// eslint-disable-line
			.el-dialog {
				// margin: 0 auto !important;
				// overflow: hidden;
				// height: 55%;

				.el-dialog__body {
					padding: 30px 20px;
				}

				.el-dialog__header {
					padding: 0px;
				}

				.el-dialog__headerbtn {
					top: 5px;
					right: 5px;
					padding-top: 10px;
				}

				.el-dialog__headerbtn .el-dialog__close {
					color: #fff;
					height: 30px;
					width: 35px;
				}

				.el-dialog__headerbtn .el-dialog__close:hover {
					color: gray;
				}
			}
		}
	}

	.dialog_diy {
		::v-deep .el-dialog__wrapper {

			// eslint-disable-line
			.el-dialog {

				.el-dialog__body {
					padding: 0px;
				}

				.el-dialog__header {
					padding: 0px;
				}

				.el-dialog__headerbtn {
					top: 5px;
					right: 5px;
					padding-top: 10px;
				}

				.el-dialog__headerbtn .el-dialog__close {
					color: #fff;
					height: 30px;
					width: 35px;
				}

				.el-dialog__headerbtn .el-dialog__close:hover {
					color: gray;
				}
			}
		}
	}
</style>

<template>
    <div class="register">
        <div class="box">
            <i class="el-icon-close" @click="close_register"></i>
            <div class="content">
                <div class="nav">
                    <span class="active">用户注册</span>
                </div>
                <el-form>
                    <el-input
                            placeholder="用户名"
                            prefix-icon="el-icon-user"
                            v-model="name"
                            clearable>
                    </el-input>
					<el-input
                            placeholder="手机号"
                            prefix-icon="el-icon-phone-outline"
                            v-model="phone"
                            clearable
                            @blur="check_phone">  <!-- @blur 是当元素失去焦点时所触发的事件 --> 
                    </el-input>
                    <el-input
                            placeholder="密码"
                            prefix-icon="el-icon-key"
                            v-model="password"
                            clearable
                            show-password>
                    </el-input>
                    <el-input
                            placeholder="验证码"
                            prefix-icon="el-icon-chat-line-round"
                            v-model="sms"
                            clearable>
                        <template slot="append">
                            <span class="sms" @click="send_sms">{{ sms_interval }}</span>
							<!-- <span class="sms" @click="$store.dispatch('getCode',phone)">{{ sms_interval }}</span> -->
                        </template>
                    </el-input>
					<!-- 2022.12.12王昕卓添加医院代码 -->
					<el-input
					        placeholder="医院代码"
					        prefix-icon="el-icon-s-promotion"
					        v-model="hospitalID"
					        clearable>
					</el-input>
                    <el-button type="primary" @click="userRegister">注册</el-button>
                </el-form>
                <div class="foot">
                    <!-- <span @click="go_login">立即登录</span> -->
					<span @click="close_register">立即登录</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import {request} from '../network/request.js'
	// import {request} from '../utils/request.js'
	import axios from 'axios';
	import signin from '../Sign-in.vue'
    export default {
        name: "register",
        data() {
            return {
                name: '',
				hospitalID: '',
				phone: '',
                password: '',
                sms: '',
                sms_interval: '获取验证码',
                is_send: false,
				realsms: ''
            }
        },
        methods: {
            close_register() {
                // this.$emit('close', false)
				this.$router.push({
					path: '/'
				})
            },
            check_phone() {
                // if (!this.phone) return;
				if (!this.phone) {
					this.$message({
						message: '手机号不能为空！',
						type: 'warning',
						duration: 1000,
					});
					 return false;
				}
                if (!this.phone.match(/^1[3-9][0-9]{9}$/)) {
                    this.$message({
                        message: '手机号有误！',
                        type: 'warning',
                        duration: 1000,
                        onClose: () => {
                            this.phone = '';
                        }
                    });
                    return false;
                }
                this.is_send = true;
            },
			userlogin() {
				this.tip = "           "
				request({
					url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/onloadweb/' + this.phone + '/' + this.password,
					method: 'get'
				}, res => {
					if ((this.phone) && (this.password)) {
						// if (res.data.data.ststus.status === 1)
						if (res.data.data.status === 1) {
							/* 吴佳琪 2021-4-15 添加获取seeien域内的医生姓名 */
							// this.requestDoctor();
							this.$router.push('/chart/alert')
						} else {
							this.tip = "/* 您输入的账户名或密码有误! */"
							// alert('账号或密码错误')
						}
					} else {
						this.tip = "/* 用户名和密码不能为空! */"
						// alert('用户名和密码不能为空')
					}
				}, err => {
					console.log(err)
				})
			},
			//获取验证码
			// async getCode(){
			// 	//简单判断一下---至少有数据
			// 	try{
			// 		//如果获取到验证码
			// 		const {phone} = this;
			// 		phone && await this.$store.dispatch('getCode',phone);
			// 		//将组件的sms属性值变为仓库中的验证码
			// 		this.sms = this.$store.state.user.code;
			// 	}catch (error){}
			// },
			send_sms() {
				this.tip = "           "
				let obj = {
					// phone:this.res.data.data.phone
					phone:this.phone
					}
				request({
					url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/phoneCode/' + this.phone,
					method: 'get'
				}, res => {
					// console.log(res)
					this.realsms = res.data.data.code
						if (res.data.code === 20001) {
							this.$message({
							    message: '用户已存在！',
							    type: 'warning',
							    duration: 1000,
							    onClose: () => {
							        this.phone = '';
							    }
							});
						}else{
							if (!this.is_send) return;
							this.is_send = false;
							let sms_interval_time = 60;
							this.sms_interval = "发送中...";
							let timer = setInterval(() => {
							    if (sms_interval_time <= 1) {
							        clearInterval(timer);
							        this.sms_interval = "获取验证码";
							        this.is_send = true; // 重新回复点击发送功能的条件
							    } else {
							        sms_interval_time -= 1;
							        this.sms_interval = `${sms_interval_time}秒后再发`;
							    }
							}, 1000);
						}
					}
				)
			},
			// userRegister(){
			// 	const url:'/newxuetangjiance/xuetangjiance/sys-doctor-user/registerDoc/' + this.name + this.password + this.phone + this.sms,
			// 	if((this.name) && (this.phone) && (this.password) && (this.sms)) {
			// 		let postdata = {
			// 			name:this.name,
			// 			phone:this.phone,
			// 			password:this.password,
			// 			sms:this.sms,
			// 		}
			// 		if 
			// 	}
			// }
			async userRegister() {
				// let obj = {
				// 	sms:this.sms
				// 	}
				if((this.name) && (this.phone) && (this.password) && (this.sms) && (this.hospitalID)) {
					if(this.sms === this.realsms){
						if((this.hospitalID==101) || (this.hospitalID==102)) {
							request({
								url:'/newxuetangjiance/xuetangjiance/sys-doctor-user/registerDoc/' + this.name + '/' + this.password + '/' + this.phone + '/' + this.sms + '/' + this.hospitalID,
								method: 'get'
							},res => {
								this.$message({
									message: '注册成功！',
								});
							});	
						} else{
							this.$message({
								message: '医院代码输入错误！',
							});
							// console.log(this.hospitalID)
							onClose: () => {
								this.hospitalID = '';
							}
						}
						
					} else{
						// this.tip = "/* 验证码输入错误！ */"
						this.$message({
							message: '验证码输入错误！',
						});
						onClose: () => {
							this.sms = '';
						}
					}
				} else {
					// this.tip = "/* 输入信息不能为空！ */"
					this.$message({
						message: '输入信息不能为空！',
					});
				}
				}
				
			}
        }
    
</script>

<style scoped>
    .register {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        /* background-color: rgba(0, 0, 0, 0.3); */
		background-color: #504da6;
    }

    .box {
        width: 400px;
        /* height: 480px; */
		height: 650px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        /* top: calc(50vh - 240px); */
		top: calc(40vh - 236px);
        left: calc(50vw - 200px);
    }

    .el-icon-close {
        position: absolute;
        font-weight: bold;
        font-size: 20px;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .el-icon-close:hover {
        color: darkred;
    }

    .content {
        position: absolute;
        top: 40px;
        width: 280px;
        left: 60px;
    }

    .nav {
        font-size: 20px;
        height: 38px;
        border-bottom: 2px solid darkgrey;
    }

    .nav > span {
        margin-left: 90px;
        color: darkgrey;
        user-select: none;
        cursor: pointer;
        padding-bottom: 10px;
        border-bottom: 2px solid darkgrey;
    }

    .nav > span.active {
        color: black;
        border-bottom: 3px solid black;
        padding-bottom: 9px;
    }

    .el-input, .el-button {
        margin-top: 40px;
    }

    .el-button {
        width: 100%;
        font-size: 18px;
    }

    .foot > span {
        float: right;
        margin-top: 20px;
        color: orange;
        cursor: pointer;
    }

    .sms {
        color: orange;
        cursor: pointer;
        display: inline-block;
        width: 70px;
        text-align: center;
        user-select: none;
    }
</style>

<template>
	<div id="pred">
		<div class="bloodSugarHeaderBanner">
			<div class="bloodSugarTitle">实时血糖预测</div>
			<div class="personalMessage">
				<span>ID : {{bl_Id}}</span>
				<span>姓名 : {{brName}}</span>
				<span>年龄 : {{csnyr}}</span>
				<span>性别 : {{brXb}}</span>
				<span>类型 : {{type}}</span>
				<span>病程 : {{qzrq}}</span>
			</div>
		</div>
		<div class="bloodSugarContentBox">
			<div class="bloodSugarContentBoxLeft">
				<div class="bloodSugarChartBanner">
					<div class="timeSelected">
						<span :class="activeclass[0]" @click="reqChange(72)">6</span>
						<span :class="activeclass[1]" @click="reqChange(144)">12</span>
						<span :class="activeclass[2]" @click="reqChange(288)">24</span>
						<span :class="activeclass[3]" @click="reqChange(576)">48</span>
					</div>
					<div id="dhbigchart" class="dhcharts"></div>
				</div>
				<div class="bloodSugarResultBox">
					<div class="bloodSugarAssessBanner">
						<h2 class="bloodSugarAssessTitle">预测血糖评估</h2>
						<div class="bloodSugarAssessContent">
							<!-- <div class="bloodSugarAssessContentLeft">预测准确度{{dataHandle(accuracy)}} %</div> -->
							<div class="bloodSugarAssessContentLeft">
								<el-progress type="circle" :percentage="dataHandle(accuracy)" :stroke-width="13" color="#504DA6" stroke-linecap="square">
								  <template #default="{ percentage }">
									<span class="percentage-value">{{ percentage }}</span>
									<div class="percentage-label">预测准确度</div>
								  </template>
								</el-progress>
							</div>
							<div class="bloodSugarAssessContentRight">
								<h3>实时风险等级<span>{{dangerStep(dangerGrade)}}</span></h3>
								<h3>预警时间<span>30分钟</span></h3>
							</div>
						</div>
					</div>
					<div class="bsDataHistoryBanner">
						<h2 class="bsDataHistoryTitle">低血糖历史数据统计图</h2>
						<div class="bsDataHistoryContent">
							<div class="bsDataHistoryContentLeft">
								<h3>低血糖次数统计</h3>
								<!-- <div id="hischarts1" ref="charts1"></div> -->
								<div class="hypoglycemiacharts1"></div>
							</div>
							<div class="bsDataHistoryContentRight">
								<h3>低血糖预警次数统计</h3>
								<!-- <div id="hischarts2" ref="charts2"></div> -->
								<div class="hypoglycemiacharts2"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bloodSugarContentBoxRight">
				<div class="bsTimeAxisBanner">
					<h2 class="bsTimeAxisTitle">血糖值</h2>
					<div class="bsTimeAxisContent">
						<div class="bsTimeAxisItem">6:00<span>8.2 mmol/L</span></div>
						<div class="bsTimeAxisItem">10:00<span>4.2 mmol/L</span></div>
						<div class="bsTimeAxisItem">16:00<span>12.2 mmol/L</span></div>
						<div class="bsTimeAxisItem">21:00<span>6.2 mmol/L</span></div>
					</div>
				</div>
				<div class="bsHistoryRecordBanner">
					<h2 class="bsHistoryRecordTitle">历史低血糖事件记录</h2>
					<div>
						<div class="scrollStyle">
							<table>
							<tr>
								<th>日期</th>
								<th>时间</th>
								<th>类型</th>
							</tr>
							<!-- <tr v-for="(items,index) in alertRecord" :key="index">
								<td>{{items.yjrq}}</td>
								<td>{{items.yjsj}}</td>
								<td>{{items.lx}}</td>
							</tr> -->
							<tr></tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
							<tr>
								<td>2018/10/29</td>
								<td>22:21</td>
								<td>2</td>
							</tr>
						</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

  import * as echarts from 'echarts'
	import {
		request
	} from '../network/request.js'
	export default {
		name: 'page1',
		data() {
			return {
				theme: {
					"seriesCnt": 4,
					"backgroundColor": "rgba(254,248,239,1)",
					"titleColor": "#333333",
					"subtitleColor": "#aaaaaa",
					"textColorShow": false,
					"textColor": "#333",
					"markTextColor": "#eeeeee",
					"color": [
						"#d87c7c",
						"#919e8b",
						"#d7ab82",
						"#6e7074",
						"#61a0a8",
						"#efa18d",
						"#787464",
						"#cc7e63",
						"#724e58",
						"#4b565b"
					],
					"borderColor": "#ccc",
					"borderWidth": 0,
					"visualMapColor": [
						"#bf444c",
						"#d88273",
						"#f6efa6"
					],
					"legendTextColor": "#333333",
					"kColor": "#c23531",
					"kColor0": "#314656",
					"kBorderColor": "#c23531",
					"kBorderColor0": "#314656",
					"kBorderWidth": 1,
					"lineWidth": 2,
					"symbolSize": 4,
					"symbol": "emptyCircle",
					"symbolBorderWidth": 1,
					"lineSmooth": false,
					"graphLineWidth": 1,
					"graphLineColor": "#aaaaaa",
					"mapLabelColor": "#000000",
					"mapLabelColorE": "rgb(100,0,0)",
					"mapBorderColor": "#444444",
					"mapBorderColorE": "#444444",
					"mapBorderWidth": 0.5,
					"mapBorderWidthE": 1,
					"mapAreaColor": "#eeeeee",
					"mapAreaColorE": "rgba(255,215,0,0.8)",
					"axes": [{
							"type": "all",
							"name": "通用坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "category",
							"name": "类目坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": false,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "value",
							"name": "数值坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "log",
							"name": "对数坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "time",
							"name": "时间坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						}
					],
					"axisSeperateSetting": true,
					"toolboxColor": "#999999",
					"toolboxEmphasisColor": "#666666",
					"tooltipAxisColor": "#cccccc",
					"tooltipAxisWidth": 1,
					"timelineLineColor": "#293c55",
					"timelineLineWidth": 1,
					"timelineItemColor": "#293c55",
					"timelineItemColorE": "#a9334c",
					"timelineCheckColor": "#e43c59",
					"timelineCheckBorderColor": "rgba(194,53,49,0.5)",
					"timelineItemBorderWidth": 1,
					"timelineControlColor": "#293c55",
					"timelineControlBorderColor": "#293c55",
					"timelineControlBorderWidth": 0.5,
					"timelineLabelColor": "#293c55",
					"datazoomBackgroundColor": "rgba(47,69,84,0)",
					"datazoomDataColor": "rgba(47,69,84,0.3)",
					"datazoomFillColor": "rgba(167,183,204,0.4)",
					"datazoomHandleColor": "#a7b7cc",
					"datazoomHandleWidth": "100",
					"datazoomLabelColor": "#333333"
				},
				myVar01: '',
				manchecked: '20001',
				apipart: 'CGMyczGRU',
				discreteP: '72',
				bl_Id: '',
				brName: '',
				brXb: '',
				type: '',
				csnyr: '',
				qzrq: '',
				val: 72,
				alertRecord: [],
				accuracy: '--',
				dangerGrade: '',
				activeclass: ['atclick', 'changebtn', 'changebtn', 'changebtn']
			}
		},
		methods: {
			// 预测血糖评估准确度
			// bloodSugarAssessContentLeft() {
			// 	// 初始化实力对象 echarts.init(dom容器);
			// 	var myChart = echarts.init(document.querySelector(".bloodSugarAssessContentLeft"));
			// 	// 指定配置项和数据
			// 	var option = {
			// 	    series: [{
			// 	        type: 'gauge',
			// 	        startAngle: 90,
			// 	        endAngle: -270,
			// 	        pointer: {
			// 	            show: false
			// 	        },
			// 	        progress: {
			// 	            show: true,
			// 	            overlap: false,
			// 	            roundCap: true,
			// 	            clip: false,
			// 	            itemStyle: {
			// 	                borderWidth: 1,
			// 	                borderColor: '#464646'
			// 	            }
			// 	        },
			// 	        axisLine: {
				
			// 	            lineStyle: {
			// 	                width: 40
			// 	            }
			// 	        },
			// 	        splitLine: {
			// 	            show: false,
			// 	            distance: 0,
			// 	            length: 10
			// 	        },
			// 	        axisTick: {
			// 	            show: false
			// 	        },
			// 	        axisLabel: {
			// 	            show: false,
			// 	            distance: 50
			// 	        },
			// 	        data: [{
			// 	            // value: this.dataHandle(this.accuracy),
			// 				value: this.accuracy,
			// 	            name: '预测准确度',
			// 	            title: {
			// 	                offsetCenter: ['0%', '-30%']
			// 	            },
			// 	            detail: {
			// 	                offsetCenter: ['0%', '-20%']
			// 	            }
			// 	        }
			// 	        ],
			// 	        title: {
			// 	            fontSize: 14,
			// 				textStyle: {
			// 				    color: '#504DA6'
			// 				}
			// 	        },
			// 	        detail: {
			// 	            width: 50,
			// 	            height: 14,
			// 	            fontSize: 25,
			// 				fontWeight: 'bold',
			// 	            color: '#272727',
			// 	            // borderColor: 'auto',
			// 	            // borderRadius: 20,
			// 	            // borderWidth: 1,
			// 	            formatter: '{value}%'
			// 	        }
			// 	    }]
			// 	};
			// 	setInterval(function () {
			// 	    option.series[0].pointer.show = false;
			// 	    option.series[0].data[0].value = this.accuracy;
			// 	    myChart.setOption(option, true);
			// 	}, 1000);
			// 	// 把配置项给实例对象
			// 	// myChart.setOption(option);
			// 	// 让图表跟随屏幕自动的去适应
			// 	window.addEventListener("resize", function() {
			// 		myChart.resize();
			// 	})
			// },
			// 低血糖次数统计图
			hypoglycemiacharts1() {
				// 初始化实力对象 echarts.init(dom容器);
				var myChart = echarts.init(document.querySelector(".hypoglycemiacharts1"));
				// 指定配置项和数据
				var option = {
				    tooltip: {
				        trigger: 'item'
				    },
					color: ['#FC7100', '#504DA6'],
				    legend: {
				        left: '0',
						bottom: '0',
						orient: 'vertical' ,
						itemWidth: 10,
						itemHeight: 10
				    },
				    series: [
				        {
				            name: '低血糖',
				            type: 'pie',
				            radius: ['40%', '70%'],
				            avoidLabelOverlap: false,
				            label: {
				                show: false,
				                position: 'center'
				            },
				            emphasis: {
				                label: {
				                    show: true,
				                    fontSize: '14',
				                    fontWeight: 'bold'
				                }
				            },
				            labelLine: {
				                show: false
				            },
				            data: [
				                {value: 1048, name: '类型1'},
				                {value: 735, name: '类型2'},
				            ]
				        }
				    ]
				};
				// 把配置项给实例对象
				myChart.setOption(option);
				// 让图表跟随屏幕自动的去适应
				window.addEventListener("resize", function() {
					myChart.resize();
				})
			},
			// 低血糖预警次数统计
			hypoglycemiacharts2() {
				// 初始化实力对象 echarts.init(dom容器);
				var myChart = echarts.init(document.querySelector(".hypoglycemiacharts2"));
				// 指定配置项和数据
				var option = {
				    tooltip: {
				        trigger: 'item'
				    },
					color: ['#FC7100', '#504DA6', '#09EBCC'],
				    legend: {
						left: '0',
						bottom: '0',
				        orient: 'vertical' ,
						itemWidth: 10,
						itemHeight: 10
				    },
				    series: [
				        {
				            name: '低血糖预警',
				            type: 'pie',
				            radius: ['40%', '70%'],
				            avoidLabelOverlap: false,
				            label: {
				                show: false,
				                position: 'center'
				            },
				            emphasis: {
				                label: {
				                    show: true,
				                    fontSize: '14',
				                    fontWeight: 'bold'
				                }
				            },
				            labelLine: {
				                show: false
				            },
				            data: [
				                {value: 1048, name: '类型1'},
				                {value: 735, name: '类型2'},
				                {value: 580, name: '类型3'}
				            ]
				        }
				    ]
				};
				// 把配置项给实例对象
				myChart.setOption(option);
				// 让图表跟随屏幕自动的去适应
				window.addEventListener("resize", function() {
					myChart.resize();
				})
			},
			dynamicChart(val) {
				this.requestpatientmsg();
				clearInterval(this.myVar01)
				this.myVar01 = ''
				var obj = this.theme
				var echarts = require('echarts');
				echarts.registerTheme('vintage', obj);//注册主题，用于初始化实例的时候指定。
				// echarts.registerTheme('custom' , obj);
				var myChart3 = echarts.init(document.getElementById('dhbigchart'), 'vintage');

				// 初始 option
				myChart3.showLoading();//显示加载动画效果  
				// 绘制图表
				myChart3.clear();//清空当前实例，会移除实例中所有的组件和图表。
				myChart3.setOption({//设置图表实例的配置项
					title: {
						text: '血糖值(mmol/L)',
						textStyle: {
							color: '#35384D',
							fontSize: 20
						},
						top: '18px'
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#FFFFFF',
						textStyle: {
							color: '#35384D'
						},
						padding: [
						    10,  // 上
						    10, // 右
						    20,  // 下
						    10, // 左
						],
						extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
					},
					color: ['#504DA6', '#6FD1F6'],
					legend: {
						data: ['CGM预测', 'CGM记录'],
						bottom: '16px'
					},
					grid: {
						top: '75',
						left: '0',
						right: '60px',
						bottom: '50px',
						containLabel: true,
						backgroundColor: 'transparent',
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						},
						top: '18px',
					},
					xAxis: {
						type: 'category',
						// 是否让线条和坐标轴有缝隙
						boundaryGap: true,
						data: [],
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						// name: '血糖值(mmol/L)',
						nameTextStyle: {
							verticalAlign: 'bottom',
							align: 'left'
						},
						// min: '0',
						// max: '25',
						nameRotate: 0,
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					visualMap: [{
						top: '70px',
						left: 'right',
						bottom: '50px',
						dimension: 6,
						min: 0,
						max: 13,
						itemHeight: 300,

						precision: 0.1,
						text: [''],
						textGap: 10,
						textStyle: {
							color: '#00007f'
						},
						inRange: {
							// colorLightness: [1,0.7]
						},
						outOfRange: {
							color: ['#fa8894']
						},
						controller: {
							inRange: {
								color: ['#fa8894', '#ffaa00', '#00ff00', '#ffaa00', '#fa8894']
							},
							outOfRange: {
								color: ['#fa8894']
							}
						}
					}],
					series: [{
							name: 'CGM预测',
							type: 'line',
							smooth: 'true',
							symbol: 'none',
							data: [],
							// 线条样式
							lineStyle: {
								width: 5
							},
						},
						{
							name: 'CGM记录',
							type: 'line',
							smooth: 'true',
							data: [],
							symbol: 'none',
							// 线条样式
							lineStyle: {
								width: 5
							},
							// 区域填充样式。设置后显示成区域面积图
							areaStyle: {
								// color: 'linear-gradient(#ececf5 #FFFFFF)'
								color: '#ececf5'
							},
							markArea: {
								data: [
									[{
											name: '',
											yAxis: 0,
											itemStyle: {
												// color: '#ffcccc',
												color: 'transparent',
												opacity: 0.5
											}
										},
										{
											yAxis: 3.9
										}
									],
									[{
											name: '低',
											yAxis: 3.9,
											itemStyle: {
												color: '#504DA6',
												// opacity: 0.5
											}
										},
										{
											yAxis: 4.0
										}
									],
									// [{
									// 		name: '',
									// 		yAxis: 4.4,
									// 		itemStyle: {
									// 			color: '#76c99b',
									// 			opacity: 0.5
									// 		}
									// 	},
									// 	{
									// 		yAxis: 9.5
									// 	}
									// ],
									[{
											name: '高',
											yAxis: 9.5,
											itemStyle: {
												color: '#504DA6',
												// opacity: 0.5
											}
										},
										{
											yAxis: 9.6
										}
									],
									[{
											name: '',
											yAxis: 9.6,
											itemStyle: {
												// color: '#ffcccc',
												color: 'transparent',
												opacity: 0.5
											}
										},
										{
											yAxis: 25
										}
									],
								]
							}
						}
					]
				});
				// 让图表跟随屏幕自动的去适应
				window.addEventListener("resize", function() {
					myChart3.resize();
				})
				this.requestData(myChart3, 20, this.manchecked, this.apipart, val);
				const manchecked = this.manchecked
				const apiaprt2 = this.apipart
				const discreteP = this.discreteP
				this.myVar01 = '';
				let num = 20
				this.myVar01 = setInterval(() => {
					if (num >= (800 - val)) {
						//后台给出标志位，若是暂停标志，则停止；
						clearInterval(this.myVar01)
						num = 20
					} else if (num < (800 - val)) {
						this.requestData(myChart3, num, manchecked, apiaprt2, val);
						num++
						// console.log(num)
						// clearInterval(this.myVar01)
					} else {
						clearInterval(this.myVar01)
					}
				}, 1000)//由5000改为1000用来刷新加快 吴佳琪 2021-5-13

			},
			requestData(obj, num, api1, api2, sum) {
				return
				request({
					url: '/xuetangjiance/xuetangjiance/algorithm/GetCGMForlinechart/Br' + api1 + 'CGM/' + api2 + '/gz/' + num + '/' + sum,//吴佳琪201-5-7修改新接口框架
					method: 'get'
				}, res => {
					// console.log(res.data);
					this.res = res.data
					let CGMyc = []
					let CGMjl = []
					let CGMbj = []
					let CGMjlsj = []
					let CGMyjsj = []
					let yjnum = []
					let value11 = ''
					let value12 = ''
					let value21 = ''
					let value22 = ''
					let value23 = ''
					CGMjl = res.data.CGMjl
					CGMyc = res.data.CGMyc
					CGMjlsj = res.data.CGMjlsj
					CGMbj = res.data.CGMbj
					value11 = (res.data.CGMbjtj['1.0'] ? res.data.CGMbjtj['1.0'] : 0) // CGMbjtj   CGMyjtj
					value12 = (res.data.CGMbjtj['2.0'] ? res.data.CGMbjtj['2.0'] : 0)
					value21 = (res.data.CGMyjtj['1.0'] ? res.data.CGMbjtj['1.0'] : 0)
					value22 = (res.data.CGMyjtj['2.0'] ? res.data.CGMbjtj['2.0'] : 0)
					value23 = (res.data.CGMyjtj['3.0'] ? res.data.CGMbjtj['3.0'] : 0)
					// for(var i=0;i<CGMjlsj.length;i++){
					// 	CGMjlsj[i] = this.timestap_alert(CGMjlsj[i])
					// }

					this.showHischarts1(value11, value12)
					this.showHischarts2(value21, value22, value23)
					this.chartSetOption(obj, CGMjlsj, CGMyc,
						CGMjl) // CGMjlsj = res.data.CGMjlsj后，将CGMjlsj作为参数传入函数，在函数中对CGMjlsj进行操作会改变res.data.CGMjlsj的值或结构；
					// this.span = this.res.zzsj
					// console.log(res.data.CGMjlsj.length)
					// console.log(CGMjlsj)                  
					// console.log(res.data.CGMjl.length)
					//	console.log(res.data.CGMbjtj['1.0'])
					// console.log(CGMjlsj[270])

					let yjsum = 0
					if (num === 20) {
						this.alertRecord = []
						for (var j = 0; j < CGMbj.length; j++) {
							if (CGMbj[j] !== 0) {
								this.alertRecord[yjsum] = {
									"lx": '',
									"yjsj": '',
									"yjrq": ''
								}
								this.alertRecord[yjsum].lx = ''
								this.alertRecord[yjsum].lx = CGMbj[j]
								yjnum[yjsum] = j
								this.alertRecord[yjsum].yjsj = ''
								this.alertRecord[yjsum].yjsj = this.timestap_alert(CGMjlsj[j])
								this.alertRecord[yjsum].yjrq = ''
								this.alertRecord[yjsum].yjrq = this.timestap_datelocal(res.data.CGMjlsj[j])
								yjsum++
								// console.log(res.data.CGMjlsj[j] + '------')
							}
						}
						this.dangerGrade = CGMbj[CGMbj.length - 1]
					} else {
						if (CGMbj[CGMbj.length - 1] !== 0) {
							this.alertRecord[this.alertRecord.length] = {
								"lx": '',
								"yjsj": '',
								"yjrq": ''
							}
							// this.alertRecord[this.alertRecord.length - 1].lx = ''
							// this.alertRecord[this.alertRecord.length - 1].yjsj = ''
							// this.alertRecord[this.alertRecord.length - 1].yjrq = ''
							this.alertRecord[this.alertRecord.length - 1].lx = CGMbj[CGMbj.length - 1]
							this.alertRecord[this.alertRecord.length - 1].yjsj = this.timestap_alert(CGMjlsj[
								CGMjlsj.length - 1])
							this.alertRecord[this.alertRecord.length - 1].yjrq = this.timestap_datelocal(res.data
								.CGMjlsj[res.data.CGMjlsj.length - 1])
							// console.log(CGMbj[CGMbj.length - 1] + '@' + num + this.alertRecord[this.alertRecord.length - 1].yjsj)
						}
						this.dangerGrade = CGMbj[CGMbj.length - 1]
						this.accuracy = 100 - res.data.a6['MAED']
					}
					// console.log(CGMbj)
					// console.log(value11)
					// console.log(CGMbj[CGMbj.length - 1])
				}, err => {
					console.log(err)
				})
			},
			chartSetOption(obj, xAxisdata, yAxisdata1, yAxisdata2) {
				let CGMjlsj = []
				for (var i = 0; i < xAxisdata.length; i++) {
					CGMjlsj[i] = this.timestap_alert(xAxisdata[i])
				}
				obj.hideLoading();
				obj.setOption({

					title: {
						text: '血糖值(mmol/L)',
						textStyle: {
							color: '#35384D',
							fontSize: 20
						},
						top: '18px'
					},
					backgroundColor: 'transparent',
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['CGM预测', 'CGM记录'],
						bottom: '16px'
					},
					grid: {
						top: '75',
						left: '0',
						right: '60px',
						bottom: '50px',
						containLabel: true,
						backgroundColor: 'transparent',
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						},
						top: '18px',
					},
					xAxis: {
						type: 'category',
						// 是否让线条和坐标轴有缝隙
						boundaryGap: true,
						data: CGMjlsj,
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						// name: '血糖值(mmol/L)',
						nameTextStyle: {
							verticalAlign: 'bottom',
							align: 'left'
						},
						// min: '0',
						// max: '25',
						nameRotate: 0,
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					series: [{
							name: 'CGM预测',
							type: 'line',
							symbol: 'none',
							lineStyle: {
								// color: 'green',
								width: 5,
								// type: 'dashed'        // 折线图样式：虚线
							},
							smooth: 'true',
							data: yAxisdata1,
							markPoint: {
								data: [{
										type: 'max',
										name: '最大值'
									},
									{
										type: 'min',
										name: '最小值'
									}
								]
							},
							// markLine: {
							// 	data: [
							// 		{type: 'average', name: '平均值',formatter: '平均值'}
							// 	]
							// }
						},
						{
							name: 'CGM记录',
							type: 'line',
							smooth: 'true',
							symbol: 'none',
							data: yAxisdata2,
							markPoint: {
								data: [{
										type: 'max',
										name: '最大值'
									},
									{
										type: 'min',
										name: '最小值'
									}
								]
							},
							// markLine: {
							// 	data: [{
							// 		type: 'average',
							// 		name: '平均值',
							// 		formatter: '平均值'
							// 	}]
							// }
						}
					]
				});
			},
			// 这块换新接口了 2021-5-7
			// 获取病人信息
			requestpatientmsg() {
				request({
					url: '/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/' + this.manchecked,
					// url: '/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/20001',
					method: 'get'
				}, res => {
					// console.log('获取病人信息', res.data)

					let requestData = res.data.data.res
					this.bl_Id = requestData.brID
					this.brName = requestData.xm
					this.brXb = requestData.xb
					this.type = requestData.hblx
					this.csnyr = this.timestap_handle(requestData.csnyr)
					this.qzrq = this.timestap_bcCac(requestData.qzrq)//吴佳琪2021-5-8 修改函数，此函数timestap_bcCac为计算病程
					document.cookie = "bl_Id=" + this.bl_Id;
					document.cookie = "brName=" + this.brName;
					document.cookie = "brXb=" + this.brXb;
					document.cookie = "type=" + this.type;
					document.cookie = "csnyr=" + this.csnyr;
					document.cookie = "qzrq=" + this.qzrq;
				}, err => {
					console.log(err)
				})
			},
			/* requestDoctor() {
				request({
					url: '/xuetangjiance/status',
					method: 'get'
				}, res => {
					let requestData = res.data
					let ysId = requestData.ysID
					let ysName = requestData.ysName
					document.cookie = "ysID=" + ysId;
					document.cookie = "ysName=" + ysName;
					// console.log(ysId)
				}, err => {
					console.log(err)
				})
			}, 2021-5-8 吴佳琪将其注释掉，因为在登录时获取过了*/
			getCookie(cname) {
				var name = cname + "=";
				var ca = document.cookie.split(';');
				for (var i = 0; i < ca.length; i++) {
					var c = ca[i].trim();
					if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
				}
				return "";
			},
			pushCookie() {

				// console.log(this.getCookie("bl_Id"));
				// console.log(this.getCookie("brName"));
				// console.log(this.getCookie("brXb"));
				// console.log(this.getCookie("type"));
				// console.log(this.getCookie("csnyr"));
				// console.log(this.getCookie("qzrq"));
			},
			reqChange(val) {
				this.activeclass = []
				this.val = ''
				this.val = val
				for (var i = 0; i < 4; i++) {
					this.activeclass[i] = 'changebtn'
				}
				switch (val) {
					case 72:
						this.activeclass[0] = 'atclick';
						break;
					case 144:
						this.activeclass[1] = 'atclick';
						break;
					case 288:
						this.activeclass[2] = 'atclick';
						break;
					case 576:
						this.activeclass[3] = 'atclick';
						break;
				}
				this.dynamicChart(val);
				this.hypoglycemiacharts1();
				this.hypoglycemiacharts2();
				// this.bloodSugarAssessContentLeft();
			},
			showHischarts1(value11, value12) {
				console.log(value11 + '-' + value12)
				let bar_dev = this.$refs.charts1
				if (bar_dev) {
					// console.log('charts1不为空')
					var obj = this.theme
					var echarts = require('echarts');
					echarts.registerTheme('vintage', obj);
					let myChart1 = echarts.init(bar_dev, 'vintage')

					// 初始 option
					// myChart1.showLoading();
					// 绘制图表
					// myChart1.clear();
					// myChart1.hideLoading();
					myChart1.setOption({
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b}: {c} ({d}%)'
						},
						backgroundColor: "#336ACA",
						legend: {
							orient: 'vertical',
							left: 10,
							fontSize: '12',
							fontColor: '#ffffff',
							data: ['类型1', '类型2'],
							textStyle: {
								color: "#ffffff"
							}
						},
						toolbox: {
							show: true,
							feature: {
								 saveAsImage: {show: true}
							}
						},
						series: [{
							name: '低血糖次数',
							type: 'pie',
							radius: ['47%', '77%'],
							"color": [
								"#d86c62",
								"#cca35c",
								"#6f9e6a",
								"#d7ab82",
								"#6e7074",
								"#61a0a8",
								"#efa18d",
								"#787464",
								"#cc7e63",
								"#724e58",
								"#4b565b"
							],
							center: ['63%', '50%'],
							avoidLabelOverlap: false,
							label: {
								show: false,
								position: 'center' // hover文字位置
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '17',
									fontWeight: 'bold'
								}
							},
							labelLine: {
								show: false
							},
							data: [{
									value: value11,
									name: '类型1'
								},
								{
									value: value12,
									name: '类型2'
								}
							]
						}]
					})
				}
			},
			showHischarts2(value21, value22, value23) {
				// console.log(value21 + '-' + value22 + '-' + value23)
				let bar_dev = this.$refs.charts2
				if (bar_dev) {
					// console.log('charts2不为空')
					var obj = this.theme
					var echarts = require('echarts');
					echarts.registerTheme('vintage', obj);
					let myChart2 = echarts.init(bar_dev, 'vintage')
					myChart2.setOption({
						title: {
							// text: '产量玫瑰图',
							fontSize: 15
						},
						backgroundColor: "#366aca",
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b} : {c} ({d}%)'
						},
						grid: {
							left: 25,
							right: 30,
							top: 10,
							bottom: 10
						},
						legend: {
							left: 5,
							orient: 'vertical',
							fontColor: 'red',
							data: ['类型1', '类型2', '类型3'],
							textStyle: {
								color: "#ffffff"
							}
						},
						toolbox: {
							show: true,
							feature: {
								// mark: {show: true},
								// dataView: {show: true, readOnly: false},           //数据视图选项、按钮图标
								magicType: {
									show: true,
									type: ['pie', 'funnel']
								},
								// restore: {show: true},                             //刷新按钮图标
								// saveAsImage: {show: true}
							}
						},
						series: [{
							name: '低血糖预警次数',
							type: 'pie',
							radius: [30, 50],
							"color": [
								"#d86c62",
								"#cca35c",
								"#6f9e6a",
								"#d7ab82",
								"#6e7074",
								"#61a0a8",
								"#efa18d",
								"#787464",
								"#cc7e63",
								"#724e58",
								"#4b565b"
							],
							center: ['65%', '50%'],
							avoidLabelOverlap: false,
							label: {
								show: false,
								position: 'center'
							},
							emphasis: {
								label: {
									show: true,
									fontSize: '17',
									fontWeight: 'bold'
								}
							},
							// roseType: 'area',
							data: [{
									value: value21,
									name: '类型1'
								},
								{
									value: value22,
									name: '类型2'
								},
								{
									value: value23,
									name: '类型3'
								}
							]
						}]
					});
				}
				// var obj = this.theme
				// var echarts = require('echarts');
				// echarts.registerTheme('vintage' , obj);
				// var myChart = echarts.init(document.getElementById('hischarts2'),'vintage');

				// 初始 option
				// myChart.showLoading();
				// 绘制图表

				// myChart.hideLoading();
			},
			dangerStep(step) {
				switch (step) {
					case 0:
						return ('正常');
						break;
					case -2:
						return ('轻度低血糖');
						break;
					case -1:
						return ('重度低血糖');
						break;
					case 3:
						return ('轻度高血糖');
						break;
					case 2:
						return ('中度高血糖');
						break;
					case 1:
						return ('重度高血糖');
						break;
				}
			},
			dataHandle(data) {
				if (data !== '--') {
					var dataCtr = data;
					data = JSON.stringify(data)
					var result = '';
					for (var i = 0; i < data.length; i++) {
						if (data[i] === '.') {
							result = result + data[i];
							result = result + data[i + 1];
							if (data[i + 2]) {
								result = result + data[i + 2]
							}
							return result;
						} else {
							result = result + data[i]
						}
					}
					return result;
				} else {
					return ('--')
				}
			},
			timestap_alert(originaltime) {
				let date = ''
				if (originaltime.indexOf('/') >= 0) {
					let dataInfoList = originaltime.split(' ')[0].split('/')
					let timeInfo = originaltime.split(' ')[1]
					let dataInfo = dataInfoList[2] + '-' + dataInfoList[1] + '-' + dataInfoList[0] + ' ' + timeInfo
					date = new Date(dataInfo)
				
								
				} else {
					date = new Date(originaltime)
				}
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
				''; //date.getMinutes() + ':';
				var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

				return (h + m)
			},
			timestap_datelocal(originaltime) {
				const date = new Date(originaltime)
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) +
				''; //date.getMinutes() + ':';
				var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());

				return (Y + M + D)
			},
			timestap_handle(originaltime) {
				const date2 = new Date(originaltime)
				const date = new Date()
				const Y = date.getFullYear()
				const y = date2.getFullYear()
				return (Y - y)
			},
			//吴佳琪2021-5-8 粘贴一个函数，用来计算病程
			timestap_bcCac(originaltime) {
			  const date1 = new Date(originaltime)
			  const date2 = new Date()
			  var Y1 = date1.getFullYear();
			  var M1 = date1.getMonth() + 1 
			  var Y2 = date2.getFullYear();
			  var M2 = date2.getMonth() + 1 
			  var y = Y2 - Y1
			  var m = M2 - M1
			  if(m<0){
				  m = 12 + m
				  y--
				  if(y===0){
					return(m + '个月')  
				  }
				  return(y + '年' + m + '个月')
			  }
			  else{
				  if(y===0){ 
					if(m===0){
						return('不足一个月')
					}
					return(m + '个月')
				  }
				  else{
					  if(m===0){
						  return(y + '年')
					  }
					  return(y + '年' + m + '个月')
				  }
			  } 
			},
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.myVar01)
			next();
		},
		mounted() {
			// this.dynamicChart();
			this.reqChange(this.val);
			// this.showHischarts1();
			// this.showHischarts2();
			//this.requestDoctor();
		}
	}
</script>

<style scoped="scoped" lang="less">
	#pred {
		margin-top: 90px;
		.bloodSugarHeaderBanner {
			width: 95%;
			// height: 121px;
			margin: 0 auto 30px;
			padding: 26px 30px 21px;
			background-color: #FFFFFF;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			box-sizing: border-box;
			.bloodSugarTitle {
				font-size: 26px;
				font-weight: bold;
				color: #504DA6;
			}
			.personalMessage {
				margin-top: 18px;
				font-size: 16px;
				color: #333333;
				span {
					margin-right: 40px;
				}
			}
		}
		.bloodSugarContentBox {
			display: flex;
			width: 95%;
			margin: 0 auto 30px;
			.bloodSugarContentBoxLeft {
				flex: 5;
				margin-right: 30px;
				.bloodSugarChartBanner {
					position: relative;
					width: 100%;
					height: 480px;
					padding: 19px 30px;
					background-color: #FFFFFF;
					box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					box-sizing: border-box;
					.timeSelected {
						position: absolute;
						top: 34px;
						right: 124px;
						z-index: 1;
						span {
							display: inline-block;
							width: 62px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							color: #504CA5;
							cursor: pointer;
							&:hover {
								border: 1px solid #ECECF2;
								border-radius: 8px;
								box-sizing: border-box;
							} 
						}
						.atclick {
							border: 1px solid #ECECF2;
							border-radius: 8px;
							box-sizing: border-box;
						}
					}
					.dhcharts {
						width: 100%;
						height: 460px;
						padding: 0;
					}
				}
				.bloodSugarResultBox {
					display: flex;
					width: 100%;
					margin-top: 30px;
					.bloodSugarAssessBanner {
						flex: 3;
						height: 275px;
						margin-right: 30px;
						padding: 17px 30px;
						background-color: #FFFFFF;
						box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
						border-radius: 10px;
						box-sizing: border-box;
						.bloodSugarAssessTitle {
							font-size: 20px;
							font-weight: bold;
							color: #707070;
						}
						.bloodSugarAssessContent {
							display: flex;
							margin-top: 18px;
							.bloodSugarAssessContentLeft {
								flex: 1;
								// background-color: pink;
								padding-top: 20px;
							}
							.bloodSugarAssessContentRight {
								flex: 1;
								h3 {
									position: relative;
									margin-top: 27px;
									padding-left: 10px;
									font-size: 14px;
									color: #333333;
									&::before {
										content: '';
										position: absolute;
										top: 2px;
										left: 0;
										height: 14px;
										border-left: 2px solid #504DA6;
									}
									span {
										display: block;
										margin: 4px 0 17px;
										font-size: 20px;
										font-weight: bold;
										color: #504DA6;
									}
								}
							}
						}
					}
					.bsDataHistoryBanner {
						flex: 4;
						height: 275px;
						padding: 17px 22px;
						background-color: #FFFFFF;
						box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
						border-radius: 10px;
						box-sizing: border-box;
						.bsDataHistoryTitle {
							font-size: 20px;
							font-weight: bold;
							color: #707070;
						}
						.bsDataHistoryContent {
							// display: flex;
							margin-top: 29px;
							.bsDataHistoryContentLeft {
								// flex: 1;
								width: 50%;
								display: inline-block;
								h3 {
									font-size: 16px;
									color: #35384D;
								}
								.hypoglycemiacharts1 {
									width: 100%;
									height: 150px;
									// background-color: pink;
								}
							}
							.bsDataHistoryContentRight {
								// flex: 1;
								width: 50%;
								display: inline-block;
								h3 {
									font-size: 16px;
									color: #35384D;
								}
								.hypoglycemiacharts2 {
									width: 100%;
									height: 150px;
									// background-color: pink;
								}
							}
						}
					}
				}
				
			}
			.bloodSugarContentBoxRight {
				flex: 2;
				.bsTimeAxisBanner {
					width: 100%;
					height: 313px;
					margin-bottom: 30px;
					padding: 27px 33px;
					background-color: #FFFFFF;
					box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					box-sizing: border-box;
					.bsTimeAxisTitle {
						font-size: 20px;
						font-weight: bold;
						color: #707070;
					}
					.bsTimeAxisContent {
						position: relative;
						margin-top: 23px;
						&::before {
							content: '';
							position: absolute;
							top: 15px;
							left: 6px;
							bottom: 10px;
							width: 1px;
							background: linear-gradient(to bottom, #504DA6, #504DA6 3px, transparent 3px, transparent);
							background-size: 100% 6px;
						}
						.bsTimeAxisItem {
							margin-bottom: 33px;
							font-size: 20px;
							color: #504DA6;
							&::before {
								content: '';
								display: inline-block;
								margin-right: 8px;
								width: 12px;
								height: 12px;
								background-color: #504DA6;
								border-radius: 50%;
							}
							span {
								margin-left: 23px;
								color: #707070;
							}
						}
					}
				}
				.bsHistoryRecordBanner {
					width: 100%;
					height: 442px;
					padding: 24px 33px;
					background-color: #FFFFFF;
					box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					box-sizing: border-box;
					.bsHistoryRecordTitle {
						font-size: 20px;
						font-weight: bold;
						color: #707070;
					}
					&>div {
						position: relative;
						margin-top: 30px;
						.scrollStyle {
							width: 100%;
							height: 320px;
							padding-right: 30px;
							overflow-y: auto;
							overflow-x: hidden;
							/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/  
							&::-webkit-scrollbar  {
								width: 3px;
							}  
							/*定义滚动条轨道 内阴影+圆角*/  
							&::-webkit-scrollbar-track {
							    // border-radius: 3px;  
							    // background-color: #F2F3F3;  
							}  
							/*定义滑块 内阴影+圆角*/  
							&::-webkit-scrollbar-thumb {  
							    border-radius: 1px;
							    background-color: #ebebeb;  
							}
							table {
								width: 100%;
								text-align: left;
								font-size: 15px;
								color: #272727;
								tr {
									height: 66px;
									border-bottom: 3px solid #F5F7F9;
									&:first-child {
										position: absolute;
										display: flex;
										top: 0;
										left: 0;
										width: 100%;
										height: 30px;
										background-color: #FFFFFF;
										font-size: 15px;
										color: #272727;
										border-bottom: none;
										text-align: left;
										th:nth-child(1) {
											flex: 6;
										}
										th:nth-child(2) {
											flex: 2;
											text-align: center;
										}
										th:nth-child(3) {
											flex: 2;
											text-align: right;
										}
									}
									&:nth-child(2) {
										height: 43px;
									}
									td {
										&:last-child {
											text-align: right;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>



export function nameParas(name) {
	
	if(name){
		if(name.length==2){
			let name1=name[0];

			return name1+'*'
		}else if(name.length>2){
			let name1=name[0];
			let name2=name[name.length-1];
			return name1+'*'+name2
		}
		else{
			return name
		}
		
	}else{
		return ''
	}
}

/**
 * 时间转换工具
 * @param {*} time 即将转换得内容
 * @param {*} type 返回得时间类型,分为data和time
 */
export function timeParas(time,type='data') {
	
	if(time){
		if(type=='data'){
			return time.substring(0,10) .replace("T", " ")
		}
		else{
			return time.substring(0,19) .replace("T", " ")
		}
		
	}else{
		return ''
	}
}
/**
 * 获取年龄
 */
export function getAge(birthday) {

	if(birthday&&birthday.indexOf('1001-01-01')<0){
		const date1 = new Date()
		const date2 = new Date(birthday)
		let temp=date1.getFullYear()-date2.getFullYear();

		return temp>=0?temp:0
		
	}else{
		return 0
	}
	
}

/**
 * 获取当前时间戳
 * @param {*} isfull 
 */
export function getTime(isfull=false) {
	var myDate = new Date();
	var years = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
	var month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
	var data = myDate.getDate(); //获取当前日(1-31)
	var mmonth = ""
	var mdate = ""
	if (month < 10) {
		mmonth = "0" + month.toString();
	}else{
		mmonth=month.toString();
	}
	if (data < 10) {
		mdate = "0" + data.toString();
		
	}else{
		mdate=data.toString();
	}
	if(isfull){
		let hours=myDate.getHours();
		let minute=myDate.getMinutes();
		let second=myDate.getSeconds();
		if (hours < 10) {
			hours = "0" + hours.toString();
		}
		if (minute < 10) {
			minute = "0" + minute.toString();
		}
		if (second < 10) {
			second = "0" + second.toString();
		}
		return years + "-" + mmonth + "-" + mdate+" "+hours+":"+minute+":"+second
	}else{
		return years + "-" + mmonth + "-" + mdate
	}
	
}

/**
 * 获取指定日期的时间转换工具
 * @param {*} target 目标转换时间，默认为当天
 * @param {*} isfull 是否要转换成待时间的格式
 */
export function getFromateTime(target, isfull=false) {
	var myDate =null
	if(target){
		myDate= new Date(target);
	}else{
		myDate= new Date();
	}

	var years = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
	var month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
	var data = myDate.getDate(); //获取当前日(1-31)
	var mmonth = ""
	var mdate = ""
	if (month < 10) {
		mmonth = "0" + month.toString();
	}else{
		mmonth=month.toString();
	}
	if (data < 10) {
		mdate = "0" + data.toString();
		
	}else{
		mdate=data.toString();
	}
	if(isfull){
		let hours=myDate.getHours();
		let minute=myDate.getMinutes();
		let second=myDate.getSeconds();
		if (hours < 10) {
			hours = "0" + hours.toString();
		}
		if (minute < 10) {
			minute = "0" + minute.toString();
		}
		if (second < 10) {
			second = "0" + second.toString();
		}
		return years + "-" + mmonth + "-" + mdate+" "+hours+":"+minute+":"+second
	}else{
		return years + "-" + mmonth + "-" + mdate
	}
	
}



/**
 * 保留几位小数
 * @param {} num 要转换的数字
 * @param {*} n 保留几位小数
 * @param {*} isFull 是否需要不全0位置，默认false
 */
export function retainDecimal(num, n, isFull = false) {
	
	n = n ? parseInt(n) : 0;
	if (n <= 0) {
		return Math.round(num);
	}
	num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // 四舍五入
// console.log(num);
	if (isFull) {
		num = Number(num).toFixed(n); //补足位数
	}
	
// 返回的是number类型
	return num;
}


import {Message} from 'element-ui'
// import {
//   login,
//   getInfo
// } from '@/api/user'
import {login,getInfo,reqGetCode} from '@/api/user'
// 2022.11.8王昕卓添加注册界面
import {getToken,setToken,removeToken} from '@/utils/auth'

const USEROBJ_KEY='userObj_key'

const state = {

  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  userObj:  JSON.parse(localStorage.getItem(USEROBJ_KEY))||{},  //读取数据
  count: 0
}

const mutations = {

 
  SET_USEROBJ: (state, userObj) => {
    state.userObj = userObj
	localStorage.setItem(USEROBJ_KEY,JSON.stringify(userObj) );
	
  }
}

const actions = {

  reqGetCode({
    commit
  }, userInfo) {
    const {
      phone
    } = userInfo
    return new Promise((resolve, reject) => {
      reqGetCode({
        userId: phone,  //trim用于移除字符串的头尾空格 userId: phone.trim(),
      }).then(async response => {
        if (!response.success) {
          Message({
            message: response.msg,
            type: 'error',
            duration: 5 * 1000
          })
		    reject(response.msg)
        }else{

			const {token,data} = response
			await setToken(token.access_token)
			commit('SET_USEROBJ', data)//0超级管理员 1医生 3患者
			resolve()
			
		}
       
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  
  
  login({ commit }, userInfo) {
    const { phone,password } = userInfo
    return new Promise((resolve, reject) => {login({ userId: phone.trim(),password: password }).then(async response => {
  
        if (!response.success) {
          Message({
            message: response.msg,
            type: 'error',
            duration: 5 * 1000
          })
		    reject(response.msg)
        }else{

			const {token,data} = response
			
			await setToken(token.access_token)
			//设置token，作为用户已登录的前端标识，存在cookie中
			commit('SET_USEROBJ', data)//0超级管理员 1医生 3患者
			resolve()
			
		}
       
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },

  // user logout
  logout({
    commit,
    state,
    dispatch
  }) {

 
    commit('SET_USEROBJ', {})
    removeToken()
	
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import request from '@/utils/request'

/**
 * 获取全部医生下拉列表数据
 * @param {*} params 
 */
export function GetAllDoctorDropDownList(params) {
  return request({
    url: '/api/User/GetAllDoctorDropDownList',
    method: 'post',
    data: params
  })
}

/**
 * 获取医生用户列表
 * @param {*} params 
 */
export function GetDoctorUserList(params) {
  return request({
    url: '/api/User/GetDoctorUserList',
    method: 'post',
    data: params
  })
}


/**
 * 提交医生用户信息
 * @param {*} params  = {
    "UserId": 10001,
    "PassWord": "123456",
    "Name": "秦国华",
    "Phone": "17542914396",
    "Title": "外科医生",
    "DepartmentID": "",
    "Department": "",
    "RoleId": "0",
    "RoleName": "超级管理员",
    "UserFunction": 0,
}
 */
export function SubmitDoctorUser(params) {
  return request({
    url: '/api/User/SubmitDoctorUser',
    method: 'post',
    data: params
  })
}

/**
 * 删除医生用户信息
 * @param {*} params param = {
    "UserId": 10001
}
 */
export function DelDoctorUser(params) {
  return request({
    url: '/api/User/DelDoctorUser',
    method: 'post',
    data: params
  })
}



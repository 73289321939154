<template>
	<div class="maincontain">
		<div class="dialog_diy">
			<el-dialog :visible.sync="dialogVisible" width="40%" style="margin-top: -50px;" @close="closeDialog">
				<div slot="title" style="background:#504DA6;color: white;">
					<div style="padding:15px 20px;">新建用户</div>
				</div>
				<el-form ref="form" :model="selectedObj" :rules="rules" label-width="150px">
					<el-row>
						<el-divider content-position="left">基本信息</el-divider>
						<el-col>
							<el-form-item label="工号:" v-if="selectedObj.userId">
								<el-input v-model="selectedObj.userId" disabled style="width: 90%;" />
							</el-form-item>

							<el-form-item label="姓名:" required prop="name">
								<el-input v-model="selectedObj.name" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item>

							<el-form-item label="电话:" required prop="phone">
								<el-input v-model="selectedObj.phone" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item>
							<el-form-item label="部门:" prop="departmentID">
								<el-select v-model="selectedObj.departmentID" :disabled="isView" style="width: 90%;"
									placeholder="请选择状态" clearable>
									<el-option label="请选择" value="" />
									<el-option v-for="item in departmentOptions" :key="item.id" :label="item.value"
										:value="item.id" />
								</el-select>
							</el-form-item>
							<el-form-item label="职称:" required prop="title">
								<el-input v-model="selectedObj.title" placeholder="请输入..." :disabled="isView"
									style="width: 90%;" />
							</el-form-item>

						</el-col>
						<!-- <el-col :span="12">
									<el-form-item label="编号:">
										<el-input v-model="form" disabled style="width: 90%;" />
									</el-form-item>
								</el-col> -->
					</el-row>
					<el-row style="margin-top: 40px; text-align: center">
						<el-form :inline="true" class="form-inline">
							<el-form-item>
								<el-button style="background-color: #504DA6;border: none;" type="success"
									:loading="isInstoreing" @click="updataInfo">提交
								</el-button>
							</el-form-item>

							<el-form-item>
								<el-button @click="closePopup">取 消</el-button>
							</el-form-item>
						</el-form>
					</el-row>
				</el-form>
			</el-dialog>
		</div>

		<div class="chaxunPageSearchBanner">
			<div class="inputConditions">
				<div class="searchInputConditions">
					输入条件：
					<div class="searchStyle">
						<input type="text" v-model="formInline.searchInfo" class="searchInputStyle"
							placeholder="请输入待查询ID/姓名" @keyup.enter="search" />
						<input type="button" class="searchButtonStyle" value="精准搜索" @click="search" />
					</div>
				</div>
			</div>

			<div class="dashedStyle"></div>
			<div class="intervalCondition-center">
				<span class="textStyle1">部门信息：</span>
				<select class="searchSelect" name="condition" v-model="formInline.departmentID">
					<option v-for="item in departmentOptions" :key="item.id" :label="item.value" :value="item.id" />
				</select>
				<button class="queryResetButtonStyle" @click="search">查询</button>
				<button class="queryResetButtonStyle" @click="reset">重置</button>
			</div>
			<div class="tool-style">
				<button class="tool-ButtonStyle" @click="openPopup(null,'add')">新增</button>
			</div>
		</div>
		<div class="chaxunPageArticalBanner">
			<el-table highlight-current-row v-loading="loading" :data="tableData" style="width: 100%" align="center"
				@selection-change="handleSelectionChange">
				<!-- <el-table-column type="selection" width="55" /> -->
				<el-table-column prop="userId" label="工号" />
				<el-table-column prop="name" label="姓名" />
				<el-table-column prop="phone" label="电话" />
				<el-table-column prop="department" label="部门" />
				<el-table-column prop="title" label="职称" />

				<el-table-column prop="approveName" label="审批人" />
				<el-table-column prop="approveTime" label="审批时间" />

				<el-table-column fixed="right" label="操作" align="center" width="200">
					<template slot-scope="scope">
						<el-button type="info" size="small" plain @click="openPopup(scope.row,'edit')">修改</el-button>
						<el-button type="danger" size="small" plain @click="delItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>

			</el-table>
			<div class="page tableBottomTool">
				<el-pagination background :current-page.sync="currentPage" layout="total,prev, pager, next, sizes"
					:total="total" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
					@current-change="handleCurrentChange" @size-change="handleSizeChange" />
			</div>
		</div>

	</div>
</template>

<script>
	import {
		GetDoctorUserList,
		SubmitDoctorUser,
		DelDoctorUser
	} from '../api/doctor.js'
	import {
		GetAllMasterList
	} from '../api/system.js'

	import {
		pageMixin
	} from '../mixins/index'
	import {
		timeParas
	} from '../utils'
	export default {
		name: "precisesrch",
		mixins: [pageMixin],
		data() {
			return {
				formInline: {
					userName: '',
				},
				isView: false,
				dialogVisible: false,
				isInstoreing: false,

				selectedObj: {},
				departmentOptions: [],

				rules: {
					name: [{
						required: true,
						message: "请输入医生姓名",
						trigger: "blur"
					}, ],
					phone: [{
						required: true,
						message: "请输入电话",
						trigger: "blur"
					}, ],
					title: [{
						required: true,
						message: "请输入职称",
						trigger: "blur"
					}, ],

					departmentID: [{
						required: true,
						message: "请选择部门",
						trigger: "blur"
					}],
				},

			}
		},


		async mounted() {
			let param = {
				"Type": "100",
				"Name": "",
			}
			GetAllMasterList(param).then(res => {
				if (res.success) {
					this.departmentOptions = res.data;
				}

			});
			await this.init();
		},
		methods: {
			closeDialog() {
				this.dialogVisible = false;
			},
			async reset() {
				this.formInline = {
					content: "",
				};
				await this.init();
			},
			async init() {
				this.tableData = [];
				this.loading = true;
				let searchParam = {
					"Page": this.currentPage,
					"Limit": this.pageSize,
					"UserId": null,
					"Wheres": this.formInline.searchInfo,
					"Name": '',
					"Phone": "",
					"RoleId": "", //(0: 超级管理员  1: 医生)
					"RoleName": "",
					"DepartmentID": this.formInline.departmentID,
					"Department": ""
				}

				const {
					data,
					count
				} = await GetDoctorUserList(searchParam);
				data.forEach(item => {

					if (item.approveTime) {
						item.approveTime = timeParas(item.approveTime);
					}
				})
				this.tableData = data;
				this.total = count;
				this.loading = false;

			},
			delItem(obj) {
				this.$confirm("您确定要删除所选医生信息吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(async () => {
					let param = {
						"UserId": obj.userId
					}
					const {
						success,
						msg
					} = await DelDoctorUser(param);
					if (success) {

						let index = this.tableData.findIndex(item => {
							return item.id === obj.id
						})
						if (index >= 0) {
							this.tableData.splice(index, 1)

							this.$message({
								type: "success",
								message: msg,
							});
						}
					} else {
						this.$message({
							type: "error",
							message: msg,
						});
					}
				});

			},

			openPopup(e, type) {
				if (e) {
					// this.selectedObj = e
					this.selectedObj = JSON.parse(JSON.stringify(e))
				}
				this.dialogVisible = true;

			},
			async updataInfo() {

				this.$refs["form"].validate(async (valid) => {
					if (valid) {
						this.isInstoreing = true;
						let departmentObj = this.departmentOptions.find(item => {
							return item.id == this.selectedObj.departmentID
						})
						let param = {
							"UserId": this.selectedObj.userId ? this.selectedObj.userId : null,
							"Name": this.selectedObj.name,
							"Phone": this.selectedObj.phone,
							"Title": this.selectedObj.title,
							"DepartmentID": this.selectedObj.departmentID,
							"Department": departmentObj.value,
							"RoleId": "1", //0超级管理员 1 医生 2 患者
							"RoleName": "医生",
							"UserFunction": 0,
						}

						// console.log(param);

						const {
							success,
							msg
						} = await SubmitDoctorUser(param);
						this.isInstoreing = false;
						if (success) {
							this.$message({
								type: "success",
								message: msg,
							});
							this.init();
							this.closePopup();
						} else {
							this.$notify.error({
								title: "失败",
								message: msg,
								duration: 3000,
							});
						}




					}

				});


			},
			closePopup() {

				this.closeDialog();
				Object.keys(this.selectedObj).forEach(key => (this.selectedObj[key] = ''));
				

			},
		},

	}
</script>

<style scoped="scoped" lang="less">
	.dialog_diy {
		::v-deep .el-dialog__wrapper {

			// eslint-disable-line
			.el-dialog {

				.el-dialog__body {
					padding: 0px;
				}

				.el-dialog__header {
					padding: 0px;
				}

				.el-dialog__headerbtn {
					top: 5px;
					right: 5px;
					padding-top: 10px;
				}

				.el-dialog__headerbtn .el-dialog__close {
					color: #fff;
					height: 30px;
					width: 35px;
				}

				.el-dialog__headerbtn .el-dialog__close:hover {
					color: gray;
				}
			}
		}
	}
</style>

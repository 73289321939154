<template>
	<div>
		
		<div id="Pdf" class="mains">
			<h1 align="middle">数据质量分析报告</h1>
			<div style="border:1px solid #000000;"></div>
			<div style="margin-top: 15px;margin-bottom: 15px;">
				<div style="display: inline-block;padding-right:50px;"><b>患者基本信息</b></div><br>
				<div style="display: inline-block;padding-right:50px;">患者编号 : {{$route.query.params.ID}}</div>
				<div style="display: inline-block;padding-right:50px;">患者姓名 : {{$route.query.params.xm}}</div>
				<div style="display: inline-block;padding-right:50px;">患者性别 : {{$route.query.params.xb}}</div>
				<!-- <div style="display: inline-block;padding-right:50px;">患者性别 :
				<span v-if="$route.query.params.xb==1">男</span>
				<span v-if="$route.query.params.xb==2">女</span>
				</div> -->
				<div style="display: inline-block;padding-right:50px;">患者年龄 : {{$route.query.params.nl}}</div><br>
				<div style="display: inline-block;padding-right:50px;">患者患病类型 : {{$route.query.params.lx}}</div>
				<!-- <div style="display: inline-block;">患病持续时间 : {{$route.query.params.bc}} </div> -->
			</div>
			<div style="border:1px solid #000000;"></div>
			<div style="margin-top: 15px;margin-bottom: 15px;">
				<div style="display: inline-block;padding-right:50px;"><b>数据质量分析</b></div><br>
				<div style="display: inline-block;padding-right:50px;">数据起止时间:{{begintime}}——{{endtime}}</div>
				<div id="pdfchart" class="pdfcharts" style=""></div>
				<div style="display: inline-block;padding-right:100px;"><b>检测情况说明</b></div><br>
				<!-- <div style="text-align: left;">
				<div style="display: inline-block;font-size: 20px;color: #000000;padding-left: 15px;">{{$route.query.params.yichang[0]}}</div>
				<br>
				<div style="display: inline-block;font-size: 20px;color: #000000;padding-left: 15px;">CGM数据提示可能原因：{{$route.query.params.advice[0]}}</div>
				<br>
				<div style="display: inline-block;font-size: 20px;color: #000000;padding-left: 15px;">诊断建议：{{$route.query.params.advice[1]}}</div>
				<br>
				<div style="display: inline-block;font-size: 20px;color: #000000;padding-left: 15px;">数据修正后 血糖最低值：{{$route.query.params.cgmrecord[0]}}mmol/L，早餐前血糖值：{{$route.query.params.cgmrecord[2]}}mmol/L，最低值检测时间：{{$route.query.params.cgmrecord[1]}}</div>
				</div> -->
				<div style="text-align: left;">
				<div style="display: inline-block;font-size: 14px;color: #000000;padding-left: 15px;">{{$route.query.params.yichang[0]}}</div>
				<br>
				<div style="display: inline-block;font-size: 14px;color: #000000;padding-left: 15px;">CGM数据提示可能原因：{{$route.query.params.advice[0]}}</div>
				<br>
				<div style="display: inline-block;font-size: 14px;color: #000000;padding-left: 15px;">诊断建议：{{$route.query.params.advice[1]}}</div>
				<br>
				<div style="display: inline-block;font-size: 14px;color: #000000;padding-left: 15px;">数据修正后 血糖最低值：{{$route.query.params.cgmrecord[0]}}mmol/L，早餐前血糖值：{{$route.query.params.cgmrecord[2]}}mmol/L，最低值检测时间：{{$route.query.params.cgmrecord[1]}}</div>
				</div>
			</div>
			<div style="border:1px solid #000000;"></div>
			<div style="margin-top: 15px;margin-bottom: 15px;">
				<div style="color: #000000;text-align: left;">检测时间:{{$route.query.params.date}}</div>
				<div style="color: #000000;text-align: left;">打印时间:{{strTime}}</div>
				<div style="width: 95%;">
          <div style="color: #000000;text-align: left;">查看医师：工号-{{this.$store.state.doctorID}}</div>
				</div>
			</div>
			<div style="border:1px solid #000000;"></div>
		</div>
		<div class="reprotButton">
			<button class="tool-ButtonStyle" @click="back">返回</button>
		</div>
		
	</div>
</template>

<script>
	// import axios from 'axios'
	import html2canvas from 'html2canvas'
	import JsPDF from 'jspdf'
	import request from '@/utils/request'
	// import {GetCGMyichangjianceForlinechart,} from '@/api/patient.js'
	export default{
		name:"Pdf",
		data(){
			return{
				selectedObj:null,
				strTime:'',
				begintime:'',
				endtime:''
				// ID:'',
				// xm:'',
				// nl:'',
				// xb:'',
				// lx:''
				
			}
		},

		mounted() {
			// console.log(this.$route.query.params.ID)
			this.exportTime();
			this.getTime();
			this.requestPdfdata();
			// this.getPdf();
		},
		
		

		methods:{
				
			back(){
				this.$router.push({
					path: '/chart/kongfu'
				})
			},
		async requestPdfdata(){
					var echarts = require('echarts');
					// echarts.registerTheme('vintage' , obj);
					// echarts.registerTheme('custom' , obj);
					var myChart3 = echarts.init(document.getElementById('pdfchart'));
					 
					// 初始 option
					// 2022.12.5王昕卓修改加载文字
					// myChart3.showLoading();
					myChart3.showLoading({
						text: '数据正在分析，请耐心等待...',
						maskColor: '#ffffff',
						textColor: '#404a59',
					});
					     // 绘制图表
					myChart3.clear();
					myChart3.setOption({
						// 2022/9/14 周浩辉修改start
						title: {
							text: '血糖值(mmol/L)',
							textStyle: {
								color: '#35384D',
								fontSize: 20
							},
							top: '24px',
							left: '4%'
						},
						tooltip: {
							trigger: 'axis',
							backgroundColor: '#FFFFFF',
							textStyle: {
								color: '#35384D'
							},
							padding: [
							    10,  // 上
							    10, // 右
							    20,  // 下
							    10, // 左
							],
							extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
						},
						legend: {
							bottom: '16px'
						},
						grid: {
							top: 70,
							left: '25px',
							right: '25px',
							bottom: '60px',
							containLabel: true
						},
						xAxis: {
							type: 'category',
							// 是否让线条和坐标轴有缝隙
							boundaryGap: true,
							data: [],
							// 坐标轴刻度标签的相关设置
							axisLabel: {
								color: '#9E9EA8'
							},
							// 坐标轴轴线相关设置
							axisLine: {
								show: false
							},
							// 坐标轴刻度相关设置
							axisTick: {
								show: false
							}
						},
						yAxis: {
							type: 'value',
							// name: 'Glucose Measurements(mmg/dL)',
							nameTextStyle:{
								verticalAlign:'bottom',
								align:'left'
							} ,
							// min: '0',
							// max: '25',
							nameRotate: 0,
							// 坐标轴刻度标签的相关设置
							axisLabel: {
								color: '#9E9EA8'
							},
							// 坐标轴轴线相关设置
							axisLine: {
								show: false
							},
							// 坐标轴刻度相关设置
							axisTick: {
								show: false
							},
							// 坐标轴在 grid 区域中的分隔线
							splitLine: {
								show: true,
								lineStyle: {
									color: '#F7F9FB'
								}
							},
						},
						// 2022/9/14 周浩辉修改end
					});
					
					
					
					
					let startTime = new Date(this.begintime)
					startTime = new Date(startTime)
					let endtime = new Date(this.endtime)
					endtime = new Date(endtime)
					// let postdata = {
					// 	// "br_ID":"br" + this.patientID +"cgm",
					// 	// "begintime" : startTime,
					// 	// "endtime" : endtime
					// 	"br_ID":"br" + this.patientID +"cgm",
					// 	"begintime" : startTime.getTime(),//2021-5-8 进行修改接口的post数据，将标准时间改为时间戳
					// 	"endtime" : endtime.getTime() 
					// }
					let postdata = {
						// "bl_ID": this.patientID,
						"bl_ID": 'br'+this.patientID+'cgm',
						"begintime": startTime.getTime(), //2021-5-8 进行修改接口的post数据，将标准时间改为时间戳
						"endtime": endtime.getTime()
					}
					// 2022.11.27王昕卓修改接口
					Promise.all([
						await request({
							url: 'http://121.36.225.90:8080/newxuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart',
							method:'post',
							data:{
									"begintime":startTime.getTime(),
									"br_ID": 'br' + this.patientID + 'cgm',
									"endtime": endtime.getTime()
								}
						})
					]).then(result => {
						// console.log(result)
					
					// Promise.all([
					// 	await GetCGMyichangjianceForlinechart(postdata),
					// ]).then(async (result) => {
						
					
						if (result[0]) {
							let response=result[0]
						
					let CGMycjc = []
					let CGMjlsj = []
					let CGMjl = []
					let CGMxzz = []
					let CGMycjctj = []
					let abnormal1 = []                                   // 异常1虚线Y轴坐标初始化容器
					let abnormal2 = []
					let abnormal3 = []
					let abnormal4 = []
					let abnormal5 = []
					let abnormal6 = []
              let abnormal7 = []
              let abnormal8 = []
					let index1 = []                                      // 异常1索引存储器
					let index2 = []
					let index3 = []
					let index4 = []
					let index5 = []
					let index6 = []
              let differ = []
					let num1 = 0                                          // 异常1计数器
					let num2 = 0                                          // 异常2计数器
					let num3 = 0                                          // 异常3计数器
					let num4 = 0                                          // 异常4计数器
					let num5 = 0                                          // 异常5计数器
					let num6 = 0                                          // 异常6计数器
					let total = 0                                         // 总记录计数器
					let value1 = ''
					let value2 = ''
					let value3 = ''
					let value4 = ''
					let value5 = ''
					let value6 = ''
					let value0 = ''


					CGMxzz = response.CGMxzz
					CGMycjctj = response.CGMycjctj
					total = response.CGMjl.length
					if(CGMycjctj){
						value1 = (CGMycjctj['1.0'] ? CGMycjctj['1.0'] : 0)
						value2 = (CGMycjctj['2.0'] ? CGMycjctj['2.0'] : 0)
						value3 = (CGMycjctj['3.0'] ? CGMycjctj['3.0'] : 0)
						value4 = (CGMycjctj['4.0'] ? CGMycjctj['4.0'] : 0)
						value5 = (CGMycjctj['5.0'] ? CGMycjctj['5.0'] : 0)
						value6 = (CGMycjctj['0.5'] ? CGMycjctj['0.5'] : 0)
						value0 = (CGMycjctj['0.0'] ? CGMycjctj['0.0'] : 0)
					}
					else{
						value1 = 0
						value2 = 0
						value3 = 0
						value4 = 0
						value5 = 0
						value6 = 0
						value0 = 0
					}
					CGMjl = response.CGMjl
					// console.log(CGMjl)
					// console.log(value1+'-'+value2+'-'+value3+'-'+value4+'-'+value5+'-'+value6)
					// CGMycjc = response.data.CGMycjc
              for (var i = 0; i < response.CGMjlsj.length; i++) {
                if (CGMjl[i]==CGMxzz[i]){
                  differ[i]=0
                }else {
                  differ[i]=CGMxzz[i]
                }
              }
					for(var i=0;i<response.CGMjlsj.length;i++){
						
						CGMjlsj[i] = this.timestap_alert(response.CGMjlsj[i])
						abnormal1[i] = -2
						abnormal2[i] = -4
						abnormal3[i] = -6
						abnormal4[i] = -8
						abnormal5[i] = -10
						abnormal6[i] = -12
						abnormal7[i] = 3.9
            abnormal8[i] = 10.0
					
					}
				
					for(var j=0;j<response.CGMycjc.length;j++){
						switch(response.CGMycjc[j]){
							case 1 : // abnormal1[num1] = response.data.CGMycjc[j]              //用于存储异常类型
									 index1[num1] = j                                        //用于存储异常数据索引
									 num1++   ;                                              //循环结束后num1等于异常1个数;
							break;
							case 2 : // abnormal2[num2] = response.data.CGMycjc[j]              //用于存储异常类型;
									 index2[num2] = j                                        //用于存储异常数据索引
									 num2++   ;                                              //循环结束后num2等于异常2个数;
							break;
							case 3 : // abnormal3[num3] = response.data.CGMycjc[j]              //用于存储异常类型;
									 index3[num3] = j                                        //用于存储异常数据索引
									 num3++   ;                                              //循环结束后num3等于异常3个数;
							break;
							case 4 : // abnormal4[num4] = response.data.CGMycjc[j]              //用于存储异常类型;
									 index4[num4] = j                                        //用于存储异常数据索引
									 num4++   ;                                              //循环结束后num4等于异常4个数;
							break;
							case 5 : // abnormal5[num5] = response.data.CGMycjc[j]              //用于存储异常类型;
									   index5[num5] = j                                        //用于存储异常数据索引
									   num5++   ;                                              //循环结束后num5等于异常5个数;
							break;
							case 0.5 : // abnormal6[num6] = response.data.CGMycjc[j]              //用于存储异常类型;
									 index6[num6] = j                                        //用于存储异常数据索引
									 num6++   ;                                              //循环结束后num6等于异常6个数;
							break;
							default : ;
							break;
						}
					}
					
					let abPoint1 = []
					let abPoint2 = []
					let abPoint3 = []
					let abPoint4 = []
					let abPoint5 = []
					let abPoint6 = []
					for(var n=0;n<num1;n++){
						abPoint1[n] = {
										"name": 'PISA',
										"coord": [index1[n], -2],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"pin",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": {
												"type": 'linear',
												"x": 0,
												"y": 0,
												"x2": 0,
												"y2": 1,
												"colorStops": [{
													"offset": 0, "color": '#00ff00' // 0% 处的颜色
												}, {
													"offset": 1, "color": '#00ff00' // 100% 处的颜色
												}],
												"global": false // 缺省为 false
											}
										}
									}
					}
					for(var n=0;n<num2;n++){
						abPoint2[n] = {
										"name": '尖峰故障',
										"coord": [index2[n], -4],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"circle",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": '#ff0000'
										}
									}
					}
					for(var n=0;n<num3;n++){
						abPoint3[n] = {
										"name": '漂移故障',
										"coord": [index3[n], -6],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"triangle",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": '#b3b300'
										}
									}
					}
					for(var n=0;n<num4;n++){
						abPoint4[n] = {
										"name": '阶跃故障',
										"coord": [index4[n], -8],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"diamond",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": '#ff7cf9'
										}
									}
					}
					for(var n=0;n<num5;n++){
						abPoint5[n] = {
										"name": '缺失故障',
										"coord": [index5[n], -10],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"arrow",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": '#ff6600' 
										}
									}
					}
					for(var n=0;n<num6;n++){
						abPoint6[n] = {
										"name": '噪声故障',
										"coord": [index6[n], -12],
										// "value":abnormal1[n],
										"symbolSize" : 10,
										"symbol":"emptyCircle",
										"fontsize" : 20,
										"label" : {
											"fontsize" : 20
										},
										"itemStyle":{
											"opacity":0.7,
											"color": '#00ffff' 
										}
									}
					}

              this.chartSetOption(myChart3,CGMjlsj,CGMjl,CGMxzz,abPoint1,abnormal1,abPoint2,abnormal2,abPoint3,abnormal3,abPoint4,abnormal4,abPoint5,abnormal5, abPoint6, abnormal6, abnormal7,abnormal8,differ)					// 2022/9/14 周浩辉修改 start
					window.scrollTo(0, 0)
					// 2022/9/14 周浩辉修改 end
					setTimeout(()=>{
						this.printPdf();
					},1500)
							
						}
					
					});
					
					return 
					
					
					
					
					// // const url = '/xuetangjiance/GetCGMyichangjianceForlinechart'
					// const url = '/xuetangjiance/xuetangjiance/algorithm/GetCGMyichangjianceForlinechart'
					// // let sdtime1 = new Date().setHours(new Date().getHours() + 8)
					// let startTime = new Date(this.begintime)
					// startTime = new Date(startTime)
					// let endtime = new Date(this.endtime)
					// endtime = new Date(endtime)
					// let postdata = {
					// 	// "br_ID":"br" + this.patientID +"cgm",
					// 	// "begintime" : startTime,
					// 	// "endtime" : endtime
					// 	"br_ID":"br" + this.patientID +"cgm",
					// 	"begintime" : startTime.getTime(),//2021-5-8 进行修改接口的post数据，将标准时间改为时间戳
					// 	"endtime" : endtime.getTime() 
					// }
					// this.$axios({
					//        url,
					//        method : 'post',
					//        ContentType : 'application/json',
					//        data : postdata
					//     }).then(response => {
					// 		let CGMycjc = []
					// 		let CGMjlsj = []
					// 		let CGMjl = []
					// 		let CGMxzz = []
					// 		let CGMycjctj = []
					// 		let abnormal1 = []                                   // 异常1虚线Y轴坐标初始化容器
					// 		let abnormal2 = []
					// 		let abnormal3 = []
					// 		let abnormal4 = []
					// 		let abnormal5 = []
					// 		let abnormal6 = []
					// 		let index1 = []                                      // 异常1索引存储器
					// 		let index2 = []
					// 		let index3 = []
					// 		let index4 = []
					// 		let index5 = []
					// 		let index6 = []
					// 		let num1 = 0                                          // 异常1计数器
					// 		let num2 = 0                                          // 异常2计数器
					// 		let num3 = 0                                          // 异常3计数器
					// 		let num4 = 0                                          // 异常4计数器
					// 		let num5 = 0                                          // 异常5计数器
					// 		let num6 = 0                                          // 异常6计数器
					// 		let total = 0                                         // 总记录计数器
					// 		let value1 = ''
					// 		let value2 = ''
					// 		let value3 = ''
					// 		let value4 = ''
					// 		let value5 = ''
					// 		let value6 = ''
					// 		let value0 = ''
					// 		CGMxzz = response.data.CGMxzz
					// 		CGMycjctj = response.data.CGMycjctj
					// 		total = response.data.CGMjl.length
					// 		if(CGMycjctj){
					// 			value1 = (CGMycjctj['1.0'] ? CGMycjctj['1.0'] : 0)
					// 			value2 = (CGMycjctj['2.0'] ? CGMycjctj['2.0'] : 0)
					// 			value3 = (CGMycjctj['3.0'] ? CGMycjctj['3.0'] : 0)
					// 			value4 = (CGMycjctj['4.0'] ? CGMycjctj['4.0'] : 0)
					// 			value5 = (CGMycjctj['5.0'] ? CGMycjctj['5.0'] : 0)
					// 			value6 = (CGMycjctj['0.5'] ? CGMycjctj['0.5'] : 0)
					// 			value0 = (CGMycjctj['0.0'] ? CGMycjctj['0.0'] : 0)
					// 		}
					// 		else{
					// 			value1 = 0
					// 			value2 = 0
					// 			value3 = 0
					// 			value4 = 0
					// 			value5 = 0
					// 			value6 = 0
					// 			value0 = 0
					// 		}
					// 		CGMjl = response.data.CGMjl
					// 		// console.log(CGMjl)
					// 		// console.log(value1+'-'+value2+'-'+value3+'-'+value4+'-'+value5+'-'+value6)
					// 		// CGMycjc = response.data.CGMycjc
					// 		for(var i=0;i<response.data.CGMjlsj.length;i++){
								
					// 			CGMjlsj[i] = this.timestap_alert(response.data.CGMjlsj[i])
					// 			abnormal1[i] = -2
					// 			abnormal2[i] = -4
					// 			abnormal3[i] = -6
					// 			abnormal4[i] = -8
					// 			abnormal5[i] = -10
					// 			// abnormal6[i] = -12
							
					// 		}
					// 		for(var j=0;j<response.data.CGMycjc.length;j++){
					// 			switch(response.data.CGMycjc[j]){
					// 				case 1 : // abnormal1[num1] = response.data.CGMycjc[j]              //用于存储异常类型
					// 						 index1[num1] = j                                        //用于存储异常数据索引
					// 						 num1++   ;                                              //循环结束后num1等于异常1个数;
					// 				break;
					// 				case 2 : // abnormal2[num2] = response.data.CGMycjc[j]              //用于存储异常类型;
					// 						 index2[num2] = j                                        //用于存储异常数据索引
					// 						 num2++   ;                                              //循环结束后num2等于异常2个数;
					// 				break;
					// 				case 3 : // abnormal3[num3] = response.data.CGMycjc[j]              //用于存储异常类型;
					// 						 index3[num3] = j                                        //用于存储异常数据索引
					// 						 num3++   ;                                              //循环结束后num3等于异常3个数;
					// 				break;
					// 				case 4 : // abnormal4[num4] = response.data.CGMycjc[j]              //用于存储异常类型;
					// 						 index4[num4] = j                                        //用于存储异常数据索引
					// 						 num4++   ;                                              //循环结束后num4等于异常4个数;
					// 				break;
					// 				case 5 : // abnormal5[num5] = response.data.CGMycjc[j]              //用于存储异常类型;
					// 						   index5[num5] = j                                        //用于存储异常数据索引
					// 						   num5++   ;                                              //循环结束后num5等于异常5个数;
					// 				break;
					// 				case 0.5 : // abnormal6[num6] = response.data.CGMycjc[j]              //用于存储异常类型;
					// 						 index6[num6] = j                                        //用于存储异常数据索引
					// 						 num6++   ;                                              //循环结束后num6等于异常6个数;
					// 				break;
					// 				default : ;
					// 				break;
					// 			}
					// 		}
					// 		let abPoint1 = []
					// 		let abPoint2 = []
					// 		let abPoint3 = []
					// 		let abPoint4 = []
					// 		let abPoint5 = []
					// 		let abPoint6 = []
					// 		for(var n=0;n<num1;n++){
					// 			abPoint1[n] = {
					// 							"name": 'PISA',
					// 							"coord": [index1[n], -2],
					// 							// "value":abnormal1[n],
					// 							"symbolSize" : 10,
					// 							"symbol":"pin",
					// 							"fontsize" : 20,
					// 							"label" : {
					// 								"fontsize" : 20
					// 							},
					// 							"itemStyle":{
					// 								"opacity":0.7,
					// 								"color": {
					// 									"type": 'linear',
					// 									"x": 0,
					// 									"y": 0,
					// 									"x2": 0,
					// 									"y2": 1,
					// 									"colorStops": [{
					// 										"offset": 0, "color": '#00ff00' // 0% 处的颜色
					// 									}, {
					// 										"offset": 1, "color": '#00ff00' // 100% 处的颜色
					// 									}],
					// 									"global": false // 缺省为 false
					// 								}
					// 							}
					// 						}
					// 		}
					// 		for(var n=0;n<num2;n++){
					// 			abPoint2[n] = {
					// 							"name": '尖峰故障',
					// 							"coord": [index2[n], -4],
					// 							// "value":abnormal1[n],
					// 							"symbolSize" : 10,
					// 							"symbol":"circle",
					// 							"fontsize" : 20,
					// 							"label" : {
					// 								"fontsize" : 20
					// 							},
					// 							"itemStyle":{
					// 								"opacity":0.7,
					// 								"color": '#ff0000'
					// 							}
					// 						}
					// 		}
					// 		for(var n=0;n<num3;n++){
					// 			abPoint3[n] = {
					// 							"name": '漂移故障',
					// 							"coord": [index3[n], -6],
					// 							// "value":abnormal1[n],
					// 							"symbolSize" : 10,
					// 							"symbol":"triangle",
					// 							"fontsize" : 20,
					// 							"label" : {
					// 								"fontsize" : 20
					// 							},
					// 							"itemStyle":{
					// 								"opacity":0.7,
					// 								"color": '#b3b300'
					// 							}
					// 						}
					// 		}
					// 		for(var n=0;n<num4;n++){
					// 			abPoint4[n] = {
					// 							"name": '阶跃故障',
					// 							"coord": [index4[n], -8],
					// 							// "value":abnormal1[n],
					// 							"symbolSize" : 10,
					// 							"symbol":"diamond",
					// 							"fontsize" : 20,
					// 							"label" : {
					// 								"fontsize" : 20
					// 							},
					// 							"itemStyle":{
					// 								"opacity":0.7,
					// 								"color": '#ff7cf9'
					// 							}
					// 						}
					// 		}
					// 		for(var n=0;n<num5;n++){
					// 			abPoint5[n] = {
					// 							"name": '缺失故障',
					// 							"coord": [index5[n], -10],
					// 							// "value":abnormal1[n],
					// 							"symbolSize" : 10,
					// 							"symbol":"arrow",
					// 							"fontsize" : 20,
					// 							"label" : {
					// 								"fontsize" : 20
					// 							},
					// 							"itemStyle":{
					// 								"opacity":0.7,
					// 								"color": '#ff6600' 
					// 							}
					// 						}bPoint2,abnormal2,abPoint3,abnormal3,abPoint4,abnormal4,abPoint5,abnormal5, abPoint6, abn
					// 		}
					// 		// for(var n=0;n<num6;n++){
					// 		// 	abPoint6[n] = {
					// 		// 					"name": '未知故障',
					// 		// 					"coord": [index6[n], -12],
					// 		// 					// "value":abnormal1[n],
					// 		// 					"symbolSize" : 10,
					// 		// 					"symbol":"emptyCircle",
					// 		// 					"fontsize" : 20,
					// 		// 					"label" : {
					// 		// 						"fontsize" : 20
					// 		// 					},
					// 		// 					"itemStyle":{
					// 		// 						"opacity":0.7,
					// 		// 						"color": {
					// 		// 							"type": 'linear',
					// 		// 							"x": 0,
					// 		// 							"y": 0,
					// 		// 							"x2": 0,
					// 		// 							"y2": 1,
					// 		// 							"colorStops": [{
					// 		// 								"offset": 0, "color": '#00ffff' // 0% 处的颜色
					// 		// 							}, {
					// 		// 								"offset": 1, "color": '#00ffff' // 100% 处的颜色
					// 		// 							}],
					// 		// 							"global": false // 缺省为 false
					// 		// 						}
					// 		// 					}
					// 		// 				}
					// 		// }
					// 		this.chartSetOption(myChart3,CGMjlsj,CGMjl,CGMxzz,abPoint1,abnormal1,abPoint2,abnormal2,abPoint3,abnormal3,abPoint4,abnormal4,abPoint5,abnormal5)		
					// 		// 2022/9/14 周浩辉修改 start
					// 		window.scrollTo(0, 0)
					// 		// 2022/9/14 周浩辉修改 end
					// 		setTimeout(()=>{
					// 			this.printPdf();
					// 		},1500)
					// 		// this.getPdf();
					//     }).catch(error => {
					//       console.log(error)
					//     });
				
			},
      chartSetOption(obj,xAxisdata,yAxisdata2,CGMxzz,abPoint1,abnormal1,abPoint2,abnormal2,abPoint3,abnormal3,abPoint4,abnormal4,abPoint5,abnormal5, abPoint6, abnormal6, abnormal7,abnormal8,differ){
        obj.hideLoading();
        obj.setOption({
          // 2022/9/14 周浩辉修改start
          title: {
            text: '血糖值(mmol/L)',
            textStyle: {
              color: '#35384D',
              fontSize: 20
            },
            top: '24px',
            left: '4%'
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#FFFFFF',
            textStyle: {
              color: '#35384D'
            },
            padding: [
              10,  // 上
              10, // 右
              20,  // 下
              10, // 左
            ],
            extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
          },
          legend: {
            data: ['数据修正值','数据记录','PISA','尖峰故障','漂移故障','阶跃故障','缺失故障', '噪声故障', '低血糖阈值','高血糖阈值','差异性'],
            bottom: '16px'
          },
          grid: {
            left: '25px',
            right: '25px',
            bottom: '60px',
            show: false,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            // 是否让线条和坐标轴有缝隙
            boundaryGap: true,
            // 坐标轴刻度标签的相关设置
            axisLabel: {
              color: '#9E9EA8'
            },
            // 坐标轴轴线相关设置
            axisLine: {
              show: false
            },
            // 坐标轴刻度相关设置
            axisTick: {
              show: false
            },
            data: xAxisdata,
            // type : 'time',
            axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
              interval: 23, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
              // rotate: 40 // 横坐标上label的倾斜度
            },
          },
          yAxis: {
            type: 'value',
            // name: '血糖测量值(mmol/L)',
            nameTextStyle:{
              verticalAlign:'bottom',
              align:'left'
            } ,
            min: '0',
            // max: '25',
            // 坐标轴刻度标签的相关设置
            axisLabel: {
              color: '#9E9EA8'
            },
            // 坐标轴轴线相关设置
            axisLine: {
              show: false
            },
            // 坐标轴刻度相关设置
            axisTick: {
              show: false
            },
            // 坐标轴在 grid 区域中的分隔线
            splitLine: {
              show: true,
              lineStyle: {
                color: '#F7F9FB'
              }
            },
            // splitArea: {
            // 	show: true
            // },
            nameRotate: 0
          },
          series: [
            {
              name: '数据修正值',
              type: 'line',
              smooth:'true',
              symbol:"none",
              color: 'rgb(170, 66, 6)',
              // color: '#da9a39',
              data: CGMxzz,
              lineStyle:{
                // width:5,
                width:2,
                // type:'dashed'
              }
            },
            {
              name: '数据记录',
              type: 'line',
              color: '#504DA6',
              smooth:'true',
              symbol:"none",
              data: yAxisdata2,
              // 线条样式
              // 2022.11.30王昕卓修改线条宽度
              lineStyle: {
                // width: 5
                width: 2
              },
              // 区域填充样式。设置后显示成区域面积图

            },
            // 2022.12.28王昕卓添加低血糖阈值
            //2022/4/20杨振宇添加差异性
            {
              name: '差异性',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#49c4f8',
              data: differ,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'

              },
              areaStyle: {
                color: '#ececf5'}
            },
            {
              name: '低血糖阈值',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#ff6600',
              data: abnormal7,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'
              },

            },

            {
              name: '高血糖阈值',
              type: 'line',
              smooth: 'true',
              symbol: "none",
              color: '#b706de',
              data: abnormal8,
              tooltip: {
                show: false
              },
              lineStyle: {
                width: 2,
                type: 'dashed'
              },
            },
            // 2022.12.28王昕卓删除故障类型
            // {
            // 	name: 'PISA',
            // 	type: 'line',
            // 	smooth:'true',
            // 	symbol:"none",
            // 	color: '#00ff00',
            // 	data: abnormal1,
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint1
            // 	}
            // },
            // // {
            // // 	name: '异常1',
            // // 	type: 'line',
            // // 	smooth:'true',
            // // 	symbol:"none",
            // // 	color: '#00ff00',
            // // 	data: 0,
            // // 	lineStyle: {
            // // 		width: 2,
            // // 		type: 'dashed'
            // // 	}
            // // },
            // {
            // 	name: '尖峰故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	symbol:"none",
            // 	color: '#ff0000',
            // 	data: abnormal2,
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint2
            // 	}
            // },
            // {
            // 	name: '漂移故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	symbol:"none",
            // 	color: '#b3b300',
            // 	data: abnormal3,
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint3
            // 	}
            // },
            // {
            // 	name: '阶跃故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	symbol:"none",
            // 	color: '#ff7cf9',
            // 	data: abnormal4,
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint4
            // 	}
            // },
            // {
            // 	name: '缺失故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	data: abnormal5,
            // 	color: '#ff6600',
            // 	symbol:"none",
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint5
            // 	}
            // },
            // {
            // 	name: '噪声故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	data: abnormal6,
            // 	symbol:"none",
            // 	color: '#00ffff',
            // 	tooltip:{
            // 		show: false
            // 	},
            // 	lineStyle: {
            // 		width: 2,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint6
            // 	}
            // },
            // {
            // 	name: '未知故障',
            // 	type: 'line',
            // 	smooth:'true',
            // 	data: abnormal6,
            // 	tooltip:{
            // 		show:false
            // 	},
            // 	symbol:"none",
            // 	color: '#00ffff',
            // 	lineStyle: {
            // 		width: 1,
            // 		type: 'dashed'
            // 	},
            // 	markPoint: {
            // 		data: abPoint6
            // 	}
            // },
          ],
          // 2022/9/14 周浩辉修改end
        });

      },

			timestap_alert(originaltime) {
			 let date = ''
			 if (originaltime.indexOf('/') >= 0) {
			 	let dataInfoList = originaltime.split(' ')[0].split('/')
			 	let timeInfo = originaltime.split(' ')[1]
			 	let dataInfo = dataInfoList[2] + '-' + dataInfoList[1] + '-' + dataInfoList[0] + ' ' + timeInfo
			 	date = new Date(dataInfo)
			 
			 				
			 } else {
			 	date = new Date(originaltime)
			 }
			  var Y = date.getFullYear() + '-';
			  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			  var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ''; //date.getMinutes() + ':';
			  var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
			
			  return (h + m )
			},
			exportTime(){
			  const date = new Date()
			     var Y = date.getFullYear() + '-';
			     var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			     var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
				 var D2 = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + 'T';
			     var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
			     var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':' ;//date.getMinutes() + ':';
			     var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
			     this.strTime = Y+M+D+h+m+s
			},
			changeTime(originalTime){
			  const date = new Date(originalTime)
			     var Y = date.getFullYear() + '-';
			     var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			     var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
				 var D2 = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + 'T';
			     var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
			     var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':' ;//date.getMinutes() + ':';
			     var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
			
			     return(Y+M+D+h+m+s)
			
			},
			getTime(){
				this.begintime = this.$route.query.params.begintime
				this.endtime = this.$route.query.params.endtime
				this.begintime = this.changeTime(this.begintime)
				this.endtime = this.changeTime(this.endtime)
				this.patientID = this.$route.query.params.ID
				// console.log(this.begintime + '----' + this.endtime)
			},
			printPdf() {
			      var title = '数据质量分析报告' + this.patientID
			      html2canvas(document.querySelector('#Pdf'), {
			        allowTaint: true,
					// 2022/9/14 周浩辉修改 
					useCORS: true,
					// 图片生成显示不全，只有半截或者空白
					// 在有滚动的页面，产生了滚动条后，生成的图片可能会只有一半或者空白。答案只有一个，那就是要
					// 把html2canvas的配置项参数中，scrollx，scrolly都设置为0，问题就解决了。
					// scrollX: 0,
					// scrollY: -280,
					
			      }).then(function (canvas) {
					  // 2022/9/14 周浩辉修改 
					  // console.log(canvas.width, canvas.height)
					  // console.log(document.getElementById('Pdf').scrollTop)
					  // console.log(document.getElementById('Pdf').scrollHeight)
					  // console.log(document.getElementById('Pdf').scrollLeft)
					  let imgWidth = 575
					  let imgHeight = imgWidth / canvas.width * canvas.height
					  let pageData = canvas.toDataURL('image/jpeg', 1.0)
					  let PDF = new JsPDF('p', 'pt', 'a4')
					  PDF.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight)
					  
					  // let win_in = document.documentElement.clientWidth || document.body.clientWidth // 获得当前可视窗口的宽度（不包含滚动条）
					  // let win_out = window.innerWidth // 获得当前窗口的宽度（包含滚动条）
					  			
					  // if (win_out > win_in) {
					  //   // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
					  //   abs = (win_out - win_in) / 2    // 获得滚动条宽度的一半
					  //   // console.log(a, '新abs');
					  // }
					  
					// 2022/9/14 周浩辉修改 
			        // let contentWidth = canvas.width * 2
			        // let contentHeight = canvas.height * 2
			        // // let pageHeight = contentWidth / 592.28 * 841.89
			        // // let leftHeight = contentHeight
			        // // let position = 0
			        // let imgWidth = 575
			        // let imgHeight = 592.28 / contentWidth * contentHeight
			        // let pageData = canvas.toDataURL('image/jpeg', 1.0)
			        // let PDF = new JsPDF('p', 'pt', 'a4')
			        // // if (leftHeight < pageHeight) {
			        //   PDF.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight)
					
			        // } else {
			        //   while (leftHeight > 0) {
			        //     PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
			        //     leftHeight -= pageHeight
			        //     position -= 841.89
			        //     if (leftHeight > 0) {
			        //       PDF.addPage()
			        //     }
			        //   }
			        // }
			        PDF.save(title + '.pdf')
			      })
				  // this.$router.push({
				  // 	path:'/chart/check',
				  // })
			},
		},
		
	}
</script>

<style lang="less">
	.mains{
		
		width: 70vw;
		margin:8vh 18vw 0vh 18vw;
		
	}
	.reprotButton{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 25px 25px 25px 0px;
		.tool-ButtonStyle{
			padding: 0px 20px 0px 20px;
			margin-right: 10px;
			height: 40px;
			background-color: #504da6;
			font-size: 20px;
			color: #FAFAFA;
			border-radius: 5px;
			 border: 1px solid #707070;
		}
	}
	#newpdf{
		width: 100%;
		text-align: center;
	}
	.p1{
		text-align: left;
		padding-left: 500px;
	}
	.p2{
		text-align: left;
		padding-left: 500px;
	}
	.p3{
		text-align: left;
		padding-left: 500px;
	}
	.p4{
		text-align: left;
		padding-left: 500px;
	}
	.pdfcharts{
	  /* width: 100%; */
	  height: 460px;
	  /* margin-right: 300px; */
	  /* margin-left: 3%; */
	  background-color: #ffffff;
	}
</style>

<template>
	<div id="Pdf" class="mains">
    <h1 align="middle" style="margin-left: -300px" >持续血糖监测AGP读图报告</h1>
			<div style="border:1px solid #000000;"></div>
	        <div class="list-container">
           </div>
<!--			<div style="margin-top: 10px;margin-bottom: 10px;">-->
<!--				<div style="display: inline-block;padding-right:50px;"><b>患者基本信息</b></div><br>-->
<!--        <div style="display: inline-block;padding-right:50px;">患者编号 : {{ $route.query.params.TWOid }}</div>-->
<!--        <div style="display: inline-block;padding-right:50px;">患者姓名 : {{ $route.query.params.TWOname }}</div>-->
<!--        <div style="display: inline-block;padding-right:50px;">患者性别 :-->
<!--          <span v-if="$route.query.params.TWOgender=='男'">男</span>-->
<!--          <span v-if="$route.query.params.TWOgender=='女'">女</span>-->
<!--        </div>-->
<!--        <div style="display: inline-block;padding-right:50px;">患者年龄 : {{ $route.query.params.TWOage }}</div><br>-->
<!--        <div style="display: inline-block;padding-right:50px;">患者患病类型 :-->
<!--          <span v-if="$route.query.params.TWOtype=='12'">1型、2型糖尿病患者</span>-->
<!--          <span v-if="$route.query.params.TWOtype=='EHR'">老年/高危糖尿病患者</span>-->
<!--          <span v-if="$route.query.params.TWOtype=='1PREG'">1型糖尿病合并妊娠患者</span>-->
<!--				&lt;!&ndash; <div style="display: inline-block;">患病持续时间 : {{$route.query.params.bc}} </div> &ndash;&gt;-->
<!--			  </div>-->
<!--      </div>-->
<!--			<div style="border:1px solid #000000;"></div>-->
    <shiyan ref="shiyanComponent" style="position: relative; left: -270px; top:-50px;"></shiyan>
			<div style="margin-top: 15px;margin-bottom: 15px;">
<!--				<div style="display: inline-block;padding-right:50px;"><b>数据质量分析</b></div><br>-->
<!--        <div style="display: inline-block;padding-right:50px;">数据起始日期:-->
<!--          <div style="display: inline-block;padding-right:50px;padding-left: 15px;">-->
<!--            {{$route.query.params.startday2}}-->
<!--          </div>-->
<!--				</div>-->

		<table border="1">
		<caption>报告表格</caption>
		<tr>

		<th colspan="2">解读步骤</th>
		<th>问题及分析</th>
        <th>初始治疗方案</th>
		<th>方案调整</th>
		</tr>
		<tr>
      <td>1</td>
      <td>评估数据充分性</td>
      <td>
        <div class="list-container">
          <ul>
            <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[0].result1 }}</pre></li>
          </ul>
        </div>
      </td>
      <td>-</td>
    </tr>
      <tr>
        <td>2</td>
        <td>评估血糖整体达标情况</td>
        <td>
          <div class="list-container">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[1].result2 }}</pre></li>
            </ul>
          </div>
        </td>
        <td>-</td>
      </tr>
      <tr>
        <td>3</td>
        <td>评估低血糖风险</td>
        <td>
          <div class="list-container">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[2].result3 }}</pre></li>
            </ul>
          </div>
        </td>
        <td>
          <div class="list-container2">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[5].result6 }}</pre></li>
            </ul>
          </div></td>
      </tr>
      <tr>
        <td>4</td>
        <td>评估血糖波动性</td>
        <td>
          <div class="list-container">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[3].result4 }}</pre></li>
            </ul>
          </div>
        </td>
        <td>
          <div class="list-container2">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[6].result7 }}</pre></li>
            </ul>
          </div></td>
      </tr>
      <tr>
        <td>5</td>
        <td>评估高血糖风险</td>
        <td>
          <div class="list-container">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[4].result5 }}</pre></li>
            </ul>
          </div>
        </td>
        <td>
          <div class="list-container2">
            <ul>
              <li style="letter-spacing: 1px;"><pre>{{ fivestepsreport[7].result8 }}</pre></li>
            </ul>
          </div></td>
      </tr>
    </table>
		<div style="border:1px solid #000000;margin-top: 5%;"></div>
			<div style="margin-top: 15px;margin-bottom: 15px;">
				<div style="color: #000000;text-align: left;">检测时间:{{$route.query.params.currentTime}}</div>
				<div style="color: #000000;text-align: left;">打印时间:{{strTime}}</div>
				<div style="width: 95%;">
					<div style="color: #000000;text-align: left;">查看医师：工号-{{this.$store.state.doctorID}}</div>
				</div>
			</div>
			<div style="border:1px solid #000000;"></div>
		</div>
		<div class="reprotButton">
      <button class="tool-ButtonStyle" @click="capturePageAndExportToPDF2">打印</button>
			<button class="tool-ButtonStyle" @click="back">返回</button>
		</div>
</div>
</template>
<script>
	import html2canvas from 'html2canvas'
	import jsPDF from 'jspdf'
	import request from '@/utils/request'
  import axios from "axios";
  import Shiyan from "@/components/shiyan.vue";
	// import {GetCGMyichangjianceForlinechart,} from '@/api/patient.js'
	export default{
		name:"Pdf",
    components: {Shiyan},
		data(){
			return{

        TWOid: '',
        TWOname: '',
        TWOgender: '',
        TWOntype: '',
        TWOage: '',
        begintime:'',
        endtime:'',
        currentTime:'',
        startday2:'',

        fivestepsreport: [
          {result1:""},
          {result2:""},
          {result3:""},
          {result4:""},
          {result5:""},
          {result6:""},
          {result7:""},
          {result8:""},
        ],

				selectedObj:null,
				strTime:'',
				ID:'',
				xm:'',
				nl:'',
				xb:'',
				lx:'',

        minute:'',

			}
		},
    // 'https://js.aps.ink:8002/test'
		methods:{

      //五步法报告算法接口
      fivereport(){
        let TWOid = this.$route.query.params.TWOid;
        let startday2 = this.$route.query.params.startday2;
        let minute = this.$route.query.params.minute;
        request({
          data: ({
            "id": TWOid,
            "startday":startday2,
            "minute":minute,
          }),
          url: 'https://js.aps.ink:8002/test',
          method: 'post',
          header: {
            'content-type': 'application/json'// string格式发送
          },
        }).then(res => {
          // console.log (JSON.parse(JSON.stringify(res)))
          this.fivestepsreport = (JSON.parse(res.replace(/\n/g,"\\n")))
          console.log(JSON.parse(JSON.stringify(this.fivestepsreport)))
        })
      },

			back(){
				this.$router.push({
					path: '/chart/shiyan'
				})
			},
      resedata(){
        this.exportTime();
        // setTimeout(() => {
        //   this.capturePageAndExportToPDF2();
        // }, 3000); // 延时2000毫秒，即2秒

      },


			exportTime(){
			  const date = new Date()
			     var Y = date.getFullYear() + '-';
			     var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			     var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
				 var D2 = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + 'T';
			     var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
			     var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':' ;//date.getMinutes() + ':';
			     var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
			     this.strTime = Y+M+D+h+m+s
			},
      async printPdf() {
        var title = '持续血糖监测AGP读图报告' + this.$route.query.params.TWOid;

        // 获取shiyan.vue页面生成的PDF DataURL
        let previousPdfDataUrl = await this.$refs.shiyanComponent.capturePageAndExportToPDF();

        // 创建一个新的jsPDF实例，用于合并PDF
        let mergedPdf = new jsPDF('p', 'pt', 'a4');

        // 如果存在之前页面的PDF，将其添加到新的PDF实例中
        if (previousPdfDataUrl) {
          mergedPdf.addPage(previousPdfDataUrl, 'S');
        }

        // 使用html2canvas截取整个文档的内容
        const canvas = await html2canvas(document.body, {
          allowTaint: true,
          useCORS: true,
          scale: 1,
          logging: true,
          scrollY: 0,
          scrollX: 0,
          width: window.innerWidth,
          height: document.body.scrollHeight
        });

        let imgWidth = 1005;
        let imgHeight = imgWidth / canvas.width * canvas.height;
        let pageData = canvas.toDataURL('image/jpeg', 1.0);

        // 将整个文档的截图添加到PDF中
        mergedPdf.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight);

        // 保存合并后的PDF
        mergedPdf.save(title + '.pdf');
      },
      capturePageAndExportToPDF2() {
        var title = '数据质量分析报告' + this.$route.query.params.TWOid;
        // 获取元素的尺寸和位置
        var elementRect = this.$el.getBoundingClientRect();
        var elementTwoThirdsWidth = elementRect.width * (3.17 / 4);

        // 使用html2canvas截取页面的三分之二
        html2canvas(this.$el, {
          allowTaint: true,
          useCORS: true,
          width: elementTwoThirdsWidth, // 设置截图区域的宽度为元素宽度的三分之二
          x: 0 // 设置截图区域的起始X坐标为0，从最左侧开始
        }).then(canvas => {
          const contentWidth = canvas.width;
          const contentHeight = canvas.height;
          const pageHeight = contentWidth / 592.28 * 841.89;
          let leftHeight = contentHeight;
          let position = 0;
          const imgWidth = 595.28;
          const imgHeight = 592.28 / contentWidth * contentHeight;
          const pageData = canvas.toDataURL('image/jpeg', 1.0);
          const PDF = new jsPDF('', 'pt', 'a4');

          // 当内容不超过PDF一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              // 避免添加空白页
              if (leftHeight > 0) {
                PDF.addPage();
              }
            }
          }
          PDF.save(title + '.pdf');
        });
      },


    },


		mounted() {
			// console.log(this.$route.query.params.ID)
			this.resedata();
      this.fivereport();
		}
	}

</script>
<style scoped>
.list-container ul {
    list-style: none;
	text-align: left;
  }
  .list-container li{
	
  }
  .list-container li::before {
    //content: "•";
    font-family: "Font Awesome";
    margin-right: 30px;
	vertical-align:sub;
	font-size: 30px;
  }
  .list-container2 ul {
    list-style: none;
	text-align: left;
  }
  .list-container2 li{
	padding: 10px 10px;
  }
 
.ziti1{
	font-size: 16px;
}
table {
  width: 80%;
  border: 1px solid black;
  margin: 0 auto;
  border-collapse: collapse;
  margin-left: -12px;
  margin-top: 560px;
//position: absolute; /* 绝对定位 */
//z-index: 1000; /* 高于页面上其他元素的堆叠顺序 */
}

    td {
        height: 50px;
        border: 1px solid black;
        text-align: center;
        vertical-align: center;
		padding: 10px 10px;
    }

    tbody tr:nth-child(even) {
        background-color: antiquewhite;
    }
	table tr td:nth-child(2) {
  width: 10%;
}
table tr td:nth-child(3) {
  width: 30%;
}
table tr td:nth-child(4) {
  width: 25%;
}
table tr td:nth-child(1) {
  width: 3%;
}
th{
  height: 50px;
}
.mains{
		
		width: 70vw;
		margin:8vh 18vw 0vh 18vw;
		
	}
	.ul {
  list-style: none;
  padding-left: 20px; /* 设置左侧缩进，使得小黑点有空间显示 */
}

.ul li::before {
  content: "●"; /* 使用特定的字符作为小黑点，可以是 Unicode 字符或特殊符号 */
  color: black; /* 小黑点的颜色 */
  font-size: 12px; /* 小黑点的大小 */
  margin-right: 5px; /* 可选，调整小黑点和文本之间的间距 */
}
	.reprotButton{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 25px 25px 25px 0px;
		.tool-ButtonStyle{
			padding: 0px 20px 0px 20px;
			margin-right: 10px;
			height: 40px;
			background-color: #504da6;
			font-size: 20px;
			color: #FAFAFA;
			border-radius: 5px;
			 border: 1px solid #707070;
		}
	}
	#newpdf{
		width: 100%;
		text-align: center;
	}
	.p1{
		text-align: left;
		padding-left: 500px;
	}
	.p2{
		text-align: left;
		padding-left: 500px;
	}
	.p3{
		text-align: left;
		padding-left: 500px;
	}
	.p4{
		text-align: left;
		padding-left: 500px;
	}
	.pdfcharts{
	  /* width: 100%; */
	  height: 460px;
	  /* margin-right: 300px; */
	  /* margin-left: 3%; */
	  background-color: #ffffff;
	}
</style>
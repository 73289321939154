<template>
	<div>
		<div class="assessPageHeaderBanner">
			<div class="headerTitle">系统控制评估</div>
			<div class="headerPersonalMessage">
				<div class="personalMessageItem"><span class="itemTitle">姓名 : </span>{{brName}}</div>
				<div class="personalMessageItem"><span class="itemTitle">ID : </span>{{bl_Id}}</div>
				<div class="personalMessageItem"><span class="itemTitle">年龄 : </span>{{csnyr}}</div>
				<div class="personalMessageItem"><span class="itemTitle">性别 : </span>{{brXb}}</div>
				<div class="personalMessageItem"><span class="itemTitle">类型 : </span>{{type}}</div>
				<div class="personalMessageItem"><span class="itemTitle">病程 : </span>{{qzrq}}</div><!-- 吴佳琪去掉年的单位 -->
			</div>
		</div>
		<div class="assessPageChartBanner">
			<div id="checkchart" class="valuecharts"></div>
		</div>
		<div class="assessPageTableBanner">
			<AssesTable ref="child" :bl_Id="bl_Id" />
		</div>
		
		<!-- <button type="button" class="srchbuttonk" @click="xlsdatatakeout">导出信息</button> -->
	</div>
</template>

<script>
	// import axios from 'axios'
	import echarts from 'echarts'
	import { request } from '../network/request.js'
	import { export_json_to_excel } from '../vendor/Export2Excel.js'
	import AssesTable from '@/components/AssesTable.vue'
	export default{
		name:"About",
		components: {
			AssesTable
		},
		data(){

			return{
				res:'',
				span:'',
				theme: {
					"seriesCnt": 4,
					"backgroundColor": "rgba(254,248,239,1)",
					"titleColor": "#333333",
					"subtitleColor": "#aaaaaa",
					"textColorShow": false,
					"textColor": "#333",
					"markTextColor": "#eeeeee",
					"color": [
						"#d87c7c",
						"#919e8b",
						"#d7ab82",
						"#6e7074",
						"#61a0a8",
						"#efa18d",
						"#787464",
						"#cc7e63",
						"#724e58",
						"#4b565b"
					],
					"borderColor": "#ccc",
					"borderWidth": 0,
					"visualMapColor": [
						"#bf444c",
						"#d88273",
						"#f6efa6"
					],
					"legendTextColor": "#333333",
					"kColor": "#c23531",
					"kColor0": "#314656",
					"kBorderColor": "#c23531",
					"kBorderColor0": "#314656",
					"kBorderWidth": 1,
					"lineWidth": 2,
					"symbolSize": 4,
					"symbol": "emptyCircle",
					"symbolBorderWidth": 1,
					"lineSmooth": false,
					"graphLineWidth": 1,
					"graphLineColor": "#aaaaaa",
					"mapLabelColor": "#000000",
					"mapLabelColorE": "rgb(100,0,0)",
					"mapBorderColor": "#444444",
					"mapBorderColorE": "#444444",
					"mapBorderWidth": 0.5,
					"mapBorderWidthE": 1,
					"mapAreaColor": "#eeeeee",
					"mapAreaColorE": "rgba(255,215,0,0.8)",
					"axes": [
						{
							"type": "all",
							"name": "通用坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "category",
							"name": "类目坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": false,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "value",
							"name": "数值坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "log",
							"name": "对数坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						},
						{
							"type": "time",
							"name": "时间坐标轴",
							"axisLineShow": true,
							"axisLineColor": "#333",
							"axisTickShow": true,
							"axisTickColor": "#333",
							"axisLabelShow": true,
							"axisLabelColor": "#333",
							"splitLineShow": true,
							"splitLineColor": [
								"#ccc"
							],
							"splitAreaShow": false,
							"splitAreaColor": [
								"rgba(250,250,250,0.3)",
								"rgba(200,200,200,0.3)"
							]
						}
					],
					"axisSeperateSetting": true,
					"toolboxColor": "#999999",
					"toolboxEmphasisColor": "#666666",
					"tooltipAxisColor": "#cccccc",
					"tooltipAxisWidth": 1,
					"timelineLineColor": "#293c55",
					"timelineLineWidth": 1,
					"timelineItemColor": "#293c55",
					"timelineItemColorE": "#a9334c",
					"timelineCheckColor": "#e43c59",
					"timelineCheckBorderColor": "rgba(194,53,49,0.5)",
					"timelineItemBorderWidth": 1,
					"timelineControlColor": "#293c55",
					"timelineControlBorderColor": "#293c55",
					"timelineControlBorderWidth": 0.5,
					"timelineLabelColor": "#293c55",
					"datazoomBackgroundColor": "rgba(47,69,84,0)",
					"datazoomDataColor": "rgba(47,69,84,0.3)",
					"datazoomFillColor": "rgba(167,183,204,0.4)",
					"datazoomHandleColor": "#a7b7cc",
					"datazoomHandleWidth": "100",
					"datazoomLabelColor": "#333333"
				},
				xlsdata:'',
				bl_Id:'',
				brName:'',
				brXb:'',
				type:'',
				csnyr:'',
				qzrq:'',
			}
		},
		methods:{
			async getbyqcanshu(){
			    const url = '/cgm/jinhongkaifa/Zhagangguocheng'
			    this.axios.get(url).then(response => {
			    //成功
			    const byqresult = response.data
			    
				// console.log(response.data)
			    }).catch(error => {
			    // console.log(error)
			    })
			},
			datarequest(){
				let response = ''
				request({
					url:'/jinhongkaifa/Zhagangguocheng',
					method:'get'
				}, res => {
					this.res = res.data
					this.span = this.res.zzsj
					// console.log(this.res)
				},err => {
					console.log(err)
				})
				// this.res = response
				// this.span = this.res.zzsj
			},
			dynamicChart(){
			     var obj = this.theme
			     var echarts = require('echarts');
				 echarts.registerTheme('vintage' , obj);
			     // echarts.registerTheme('custom' , obj);
			     var myChart3 = echarts.init(document.getElementById('checkchart'),'vintage');
			
			     // 初始 option
			     myChart3.showLoading();
			         // 绘制图表
			     myChart3.clear();
			     myChart3.hideLoading();
			     myChart3.setOption({
					title: {
						text: '血糖值(mmol/L)',
						textStyle: {
							color: '#35384D',
							fontSize: 20
						},
						top: '38px',
						left: '4%'
					},
					backgroundColor: 'transparent',
					color: ['#504DA6'],
					tooltip: {
						trigger: 'axis',
						backgroundColor: '#FFFFFF',
						textStyle: {
							color: '#35384D'
						},
						padding: [
						    10,  // 上
						    10, // 右
						    20,  // 下
						    10, // 左
						],
						extraCssText: 'box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);'
					},
					legend: {
						data: ['CGM预测', 'CGM记录'],
						bottom: '16px'
					},
					grid: {
						left: '4% ',
						top: 100,
						right: '87px',//吴佳琪修改百分数，将10%变为5%，将图表与颜色轴拉近
						bottom: '13%',
						containLabel: true,
						backgroundColor: 'transparent',
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						},
						top: '38px',
						right: '41px'
					},
					xAxis: {
						type: 'category',
						// 是否让线条和坐标轴有缝隙
						boundaryGap: true,
						data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'周一', '周二', '周三', '周四', '周五', '周六', '周日',],
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						}
					},
					yAxis: {
						type: 'value',
						// name: '血糖值(mmol/L)',
						nameTextStyle:{
							verticalAlign:'bottom',
							align:'left'
						},
						// 坐标轴刻度标签的相关设置
						axisLabel: {
							color: '#9E9EA8'
						},
						// 坐标轴轴线相关设置
						axisLine: {
							show: false
						},
						// 坐标轴刻度相关设置
						axisTick: {
							show: false
						},
						// 坐标轴在 grid 区域中的分隔线
						splitLine: {
							show: true,
							lineStyle: {
								color: '#F7F9FB'
							}
						},
						min: '0',
						max: '25',
						nameRotate: 0,
					},
					visualMap: {
						top: '100px',
						right: '32px',
						bottom: '20%',
						dimension: 6,
						min: 0,
						max: 25,
						itemHeight: 270,
						precision: 0.1,
						// text: ['明暗：二氧化硫'],
						textGap: 20,
						textStyle: {
							color: '#35384D'
						},
						inRange: {
							colorLightness: [1, 0.3]
						},
						outOfRange: {
							color: ['rgba(255,255,255,.2)']
						},
						controller: {
							inRange: {
								color: ['#ffaa00','#ff66a1','#0000ff','#00ff00'],
							},
							outOfRange: {
								color: ['#00ff00']
							}
						}
					},
					series: [
						{
							name: 'CGM记录',
							type: 'line',
							smooth:'true',
							data: [8.20, 9.32, 9.01, 9.34, 9.90, 3.0, 9.20,
							2.0, 7.3, 4.1, 6.32, 7.3, 5.2, 2.8,
							7.8, 6.9, 2.4, 3.4, 9.3, 6.8, 3.4,
							6.5, 9.32, 5.9, 3.4, 4.3, 9.30, 1.0,
							4.5, 9.32, 6.3, 9.34, 3.2, 3.7, 3.7,
							15.34, 9.32, 3.5, 7.7, 2.9, 6.7, 4.9,
							9.0, 13.0, 16.7,13.6, 12.6, 9.8, 7.8
							],
							// 标记的图形
							symbol: 'none',
							// 是否显示 symbol, 如果 false 则只有在 tooltip hover 的时候显示。
							showSymbol: false,
							// 线条样式
							lineStyle: {
								width: 5
							},
							// 区域填充样式。设置后显示成区域面积图
							areaStyle: {
								// color: 'linear-gradient(#ececf5 #FFFFFF)'
								color: '#ececf5'
							}
							
							// markArea: {
							// 	data: [ 
							// 		[{
							// 			name: '1',
							// 			yAxis: 0,
							// 			itemStyle : {
							// 				// color:'rgba(250, 136, 148, 127)',
							// 				opacity:0.5
							// 			}
							// 		},
							// 		{
							// 			yAxis: 3.9
							// 		}],
							// 		[{
							// 			name: '2',
							// 			yAxis: 3.9,
							// 			itemStyle : {
							// 				// color:'rgba(247, 216, 60, 0.5)',
							// 				opacity:0.5
							// 			}
							// 		},
							// 		{
							// 			yAxis: 4.4
							// 		}],
							// 		[{
							// 			name: 3,
							// 			yAxis: 4.4,
							// 			itemStyle : {
							// 				// color:'rgba(0, 255, 127, 127)',
							// 				opacity:0.5
							// 			}
							// 		},
							// 		{
							// 			yAxis: 9.5
							// 		}],
							// 		[{
							// 			name: '4',
							// 			yAxis: 9.5,
							// 			itemStyle : {
							// 				// color:'rgba(247, 216, 60, 0.5)',
							// 				opacity:0.5
							// 			}
							// 		},
							// 		{
							// 			yAxis: 10
							// 		}],
							// 		[{
							// 			name: '5',
							// 			yAxis: 10,
							// 			itemStyle : {
							// 				// color:'rgba(250, 136, 148, 127)',
							// 				opacity:0.5
							// 			}
							// 		},
							// 		{
							// 			yAxis: 25
							// 		}],
							// ]
							// }
						}
					]
			     });
				 // 让图表跟随屏幕自动的去适应
				 window.addEventListener("resize", function() {
				 	myChart3.resize();
				 })
			},
			//吴佳琪2021-5-8注释，因为不再使用这个报表
			/* xlsdatarequest(){
				let response = ''
				request({
					url:'/xuetangjiance/GetCGMForm/Br20001CGM',
					method:'get'
				}, res => {
					this.xlsdata = res.data
					for(var i=0;i<res.data.length;i++){
						this.xlsdata[i].jlsj = this.timestap_alert(this.xlsdata[i].jlsj)
					}
				},err => {
					console.log(err)
				})
				// this.res = response
				// this.span = this.res.zzsj
			}, */
			
			// 请求病人信息
			requestpatientmsg(){
			
					this.bl_Id = this.getCookie("bl_Id")
					// console.log(this.bl_Id);
					this.brName = this.getCookie("brName")
					this.brXb = this.getCookie("brXb")
					this.type = this.getCookie("type")
					this.csnyr = this.getCookie("csnyr") 
					this.qzrq = this.getCookie("qzrq")
			
			},
			getCookie(cname){
			  var name = cname + "=";
			  var ca = document.cookie.split(';');
			  for(var i=0; i<ca.length; i++)
			  {
			    var c = ca[i].trim();
			    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
			  }
			  return "";
			},
			timestap_alert(originaltime) {
			  let date = ''
			  if (originaltime.indexOf('/') >= 0) {
			  	let dataInfoList = originaltime.split(' ')[0].split('/')
			  	let timeInfo = originaltime.split(' ')[1]
			  	let dataInfo = dataInfoList[2] + '-' + dataInfoList[1] + '-' + dataInfoList[0] + ' ' + timeInfo
			  	date = new Date(dataInfo)
			  
			  				
			  } else {
			  	date = new Date(originaltime)
			  }
			  var Y = date.getFullYear() + '-';
			  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			  var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':'; //date.getMinutes() + ':';
			  var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
			
			  return (Y + M + D + h + m + s )
			},
			xlsdatatakeout(){
				if(this.xlsdata){
					this.export2Excel();
				}
				else{
					alert('正在请求数据,请稍后')
				}
			},
			export2Excel() {
				require.ensure([], () => {
					const tHeader = ['标签', '记录时间', '可靠性标签','阈值预警等级','阈值报警等级','规则预警等级','规则报警等级','CGM修正值','CGM预测值GRU','CGM记录','CGM预测值LR'] // 对应表格输出的中文title
					const filterVal = ['index','jlsj','kkxbq','yzyjdj','yzbjdj','gzyjdj','gzbjdj','cgmxzz','cgmyczGRU','cgmjl','cgmyczLR'] // 对应表格输出的数据
					// this.days.forEach(val => {
					// tHeader.push(val.name)
					// filterVal.push(val.value)
					// })
					const list = this.xlsdata // 表格data
					const data = this.formatJson(filterVal, list)
					export_json_to_excel(tHeader, data, '数据表') // 对应下载文件的名字
				})
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => v[j]))
			},

			
			

		},
		mounted() {
			// this.datarequest();
			this.dynamicChart();
			// this.getbyqcanshu();
			//this.xlsdatarequest();//吴佳琪注释2021-5-8
			this.requestpatientmsg();

			// console.log('father\'s mounted' )

			// 测试父组件调用子组件方法
			// this.$refs.child.child_method();

		}
	}
</script>

<style scoped="scoped" lang="less">
	.assessPageHeaderBanner {
		width: 95%;
		// height: 210px;
		margin: 90px auto 30px;
		padding: 0 30px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;
		font-weight: bold;
		color: #504DA6;
		.headerTitle {
			padding-top: 26px;
			font-size: 26px;
		}
		.headerPersonalMessage {
			margin-top: 16px;
			padding-bottom: 18px;
			.personalMessageItem {
				display: inline-block;
				margin-right: 45px;
				font-size: 20px;
				.itemTitle {
					font-weight: normal;
					color: #7B7B7B;
				}
				&:nth-child(6) {
					margin-right: 0;
				}
			}
		}
	}
	.assessPageChartBanner {
		width: 95%;
		height: 480px;
		margin: 0 auto;
		// padding: 10px 30px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;
		.valuecharts{
		  width: 100%;
		  height: 100%;
		}
	}
	.assessPageTableBanner {
		width: 95%;
		// height: 275px;
		margin: 30px auto;
		padding: 30px 40px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;
	}
	
</style>

<template>
	<div id="signin">
		<div class="signin-background">
			<div class="rightarea">
				<div class="databox">
					<div style="width: 55%;display: inline-block;border-right:#504da6 1px dashed;">
						<div class="nametype">患者信息管理系统</div>
						<!-- <input id="phone" v-model="phone" type="text" class="data" placeholder="请输入用户名ID"
							required /> -->
						<!-- 周浩辉2021/7/26添加矢量图标 -->
						<div class="inputBox idFont">
							<input class="inputStyle" id="phone" v-model="phone" type="text" placeholder="请输入您的手机号"
								required />
						</div>
						<div class="inputBox pwFont">
							<input class="inputStyle" id="psw" autocomplete="off" v-model="password" type="password"
								placeholder="请输入您的密码" required />
						</div>
						<!-- <input id="psw" v-model="password" type="password" class="data" placeholder="请输入您的密码"
							required /> -->
						<div style="height: 15px;color: red;font-size: 12px;font-family: 黑体;font-weight: 300;margin-top: 10px;"
							href="">{{tip}}</div>
						<div>
							<input type="button" @click="userlogin" :disabled="loading" class="button--green" value="登录"
								style="margin: 15px 0px; cursor: pointer;" />
							<!-- <input type="button" @click="requestLocalInfo" class="button--green" value="获取本地信息"
								style="margin: 15px 0px; cursor: pointer;" /> -->
						</div>
						<div style="margin-top: 5px;">
							<!-- <a style="color:#bcbcbc;margin-left: 5%;" href="">忘记密码</a> -->
							<!-- <a style="color:#504da6;float: right;margin-right: 15%;" href="">用户注册</a><br> -->
							<button type="button" style="color:#504da6;float: right;margin-right: 15%;" @click="register">用户注册</button><br>	
							<!-- 王昕卓2022/11/7添加用户注册界面 -->
						</div>
					</div>
					<div style="width: 40%;display: inline-block;float: right;margin-top: 8%;">
						<div style="width: 50%;display: inline-block;text-align: center;">
							<div style="color:#504da6;font-weight: 600;">android版</div>
							<img src="./assets/img/android.jpg" style="width: 90%;margin: 5px 5px;" />
						</div>
						<div style="width: 50%;display: inline-block;text-align: center;">
							<div style="color:#504da6;font-weight: 600;">ios版</div>
							<img src="./assets/img/ios.png" style="width: 90%;margin: 5px 5px;" />
						</div>
						<div style="text-align: center;margin-top: 2%;">/* 下载客户端请用浏览器扫描上方二维码 */</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		request
	} from './network/request.js'
	import register from "./components/register.vue"
	import {baseRoleList} from '../src/common/resource.js'
	import axios from 'axios';
	// 王昕卓2022/11/7添加用户注册界面
	export default {
		name: 'signin',
		components: {
			register
			// 王昕卓2022/11/7添加用户注册界面
		},
		data() {
			return {
				phone: '',
				password: '',
				tip: "        ",
				timer: null, //定时器
				loading: false,
			}
		},
		created() {
		},
		methods: {
            // put_register() {
            //     this.is_login = false;
            //     this.is_register = true;
            // },
            // close_register() {
            //     this.is_register = false;
            // }
			handledocID(docID){
					this.$store.commit('transfer_docID',docID); 
					return this.$store.state.doctorID
			},	
			register(){
				this.$router.push({
					path:'/register',
					})
			},
			// 2022.12.10
			// getphone(phone){
			// 		this.$store.commit('remphone',phone); 
			// 		return this.$store.state.phone
			// },	
			// 王昕卓2022/11/7添加用户注册界面
			// userlogin() {
			// 	this.tip = "           "
			// 	let obj = {
			// 		phone:this.phone,
			// 		password:this.password
			// 	}
			// 	request({
			// 		// url: '/xuetangjiance/xuetangjiance/sys-doctor-user/onloadweb/' + this.Username + '/' + this.Password,
			// 		url: '/newxuetangjiance/xuetangjiance/sys-doctor-user/onloadweb/' + this.phone + '/' + this.password,
			// 		method: 'get'
			// 	}, res => {
			// 		// 2023.3.21王昕卓添加返回doctorID
			// 		// this.$store.state.doctorID = res.data.data.DoctorID
			// 		this.handledocID(res.data.data.DoctorID)
			// 		if ((this.phone) && (this.password)) {
			// 			if (res.data.data.ststus.status === 1) {
			// 				this.$router.push({path: '/chart/precisesrch'})
			// 			} else {
			// 				this.tip = "/* 您输入的账户名或密码有误! */"
			// 				// alert('账号或密码错误')
			// 			}
			// 		} else {
			// 			this.tip = "/* 用户名和密码不能为空! */"
			// 			// alert('用户名和密码不能为空')
			// 		}
			// 		// setTimeout(()=>{
			// 		// 	this.$store.dispatch("tagsView/addView", '/chart/check')
			// 		// 	this.loading = false;
			// 		// },1000)
			// 	}, err => {
			// 		console.log(err)
			// 	})
			// 	// 2022.12.10
			// 	// this.getphone(this.phone)
			// },
			// userlogin() {
			// 	this.tip = ""
			// 	if ((this.phone) && (this.password)) {
			// 		this.loading = true;

			// 		let obj = {
			// 			phone: this.phone,
			// 			password: this.password
			// 		}

			// 		this.$store
			// 			.dispatch("user/login", obj)
			// 			.then(() => {
			// 				setTimeout(()=>{
			// 					this.$store.dispatch("tagsView/addView", '/chart/check')
			// 					this.loading = false;
			// 				},1000)


			// 			})
			// 			.catch(() => {
			// 				this.loading = false;
			// 			});
			// 	} else {
			// 		this.tip = "/* 用户名和密码不能为空! */"

			// 	}
			// 	return

			// },
      userlogin() {
        // 检查用户输入
        if (!this.phone || !this.password) {
          this.tip = "用户名和密码不能为空!";
          return;
        }

        // 发送请求到后端进行验证
        axios.post('http://js.aps.ink:8090/login', {
          phone: this.phone,
          password: this.password

      }).then(response => {
              if (response.data.success) {
                // 如果后端返回成功，则跳转页面
                this.$router.push({ path: '/chart/precisesrch' });
              } else {
                // 如果登录失败，显示提示信息
                this.tip = "用户名或密码错误!";
              }
            })
            .catch(error => {
              console.error("登录请求失败:", error);
              this.tip = "登录失败，请稍后重试!";
            });
      }


    },
	}
</script>

<style scoped="scoped">
	#sign-in {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		padding-bottom: 15%;
	}

	.signin-background {
		/* width: 100%; */
		/* display: flex; */
		/* height: 720px; */
	}

	.rightarea {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		/* flex: 1; */
		/* float: left; */
		background-color: #504da6;
	}

	.databox {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 380px;
		margin: 0;
		transform: translateX(-50%) translateY(-50%);
		/* margin: 0 auto; */

	}

	.inputBox {
		/* position: relative; */
		display: flex;
		width: 90%;
		height: 48px;
		margin: 12px 0;
		border: 1px solid #504da6;
		border-radius: 25px;
		box-sizing: border-box;
	}

	.idFont::before {
		font-family: "iconfont";
		content: "\e6dc";
		margin-left: 20px;
		line-height: 48px;
		color: #b9bed1;
		/* height: 20px; */
	}

	.pwFont::before {
		font-family: "iconfont";
		content: "\e6dd";
		margin-left: 20px;
		line-height: 48px;
		color: #b9bed1;
		/* height: 20px; */
	}

	.inputStyle {
		flex: 1;
		margin-left: 12px;
		width: 80%;
		height: 46px;
		background-color: #f2f2f2;
		border: none;
		outline: none;
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		overflow: hidden;
	}
</style>

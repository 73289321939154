<template>
	<div id="indexview">
		<div id="nav-index">
			<ul class="layui-nav layui-nav-tree layui-nav-side" lay-filter="test">
				<!-- 周浩辉修改start -->
				<li class="layui-nav-title" style="cursor: default;">数据质量分析系统</li>
				<!-- 周浩辉修改end -->
				<!-- 2022.11.29王昕卓修改接口 -->
				<!-- <div v-for="(item ,index) in roleList" :key="index">  <!-- 遍历对象item ,index。n是每次遍历的值，index是遍历的索引值从0开始。 --> -->
					<!-- <li @click="getItemTitle(item)" class="layui-nav-item"> -->
						<!-- <router-link :class="item.icon" :to="item.path">{{item.label}}</router-link>  <!-- 使用router-link组件来导航 --> -->
					<!-- </li> -->
				<!-- </div> -->
				 
				<li @click="getItemTitle2()" class="layui-nav-item "><router-link class="manageIcon" to="/chart/precisesrch">患者信息管理</router-link></li>
				<li @click="getItemTitle1()" class="layui-nav-item "><router-link class="cgmIcon" to="/chart/check">数据质量分析</router-link></li>
                <li @click="getItemTitle3()" class="layui-nav-item "><router-link class="assesIcon" to="/chart/kongfu">空腹血糖分析</router-link></li><!--  杨振宇2023/4/5添加      -->
		        <li @click="getItemTitle4()" class="layui-nav-item "><router-link class="assesIcon" to="/chart/shiyan">AGP图谱分析</router-link></li><!--  杨振宇2023/4/5添加      -->

				
			</ul>
			<nav class="header">
				<!-- <div class="header_title">{{itemTitle}}</div> -->
				<div class="header_title" v-if="!this.newflag">{{itemTitle}}</div>
				<div class="header_title" v-else>数据质量分析</div> 
				<!-- lhh -->
				<div class="header_right">
					<span class="iconfont icon-icon_bell"></span>
					<span class="header_user">
						<img class="userImgStyle" src="../assets/img/user.png" />
						{{ysName}}
						<img @click="logoutClick" class="logoutImgStyle" src="../assets/img/logout.png" />
					</span>
				</div>
			</nav>
		</div>
		<div style="background-color: #EFF1F7; box-sizing: border-box;">
			<router-view />
		</div>
		<!-- <div style="width: 100%; padding-left: 220px; background-color: #EFF1F7; box-sizing: border-box;">
			<router-view />
		</div> -->
		<!-- <div style="width: 100%;  height: 50%;: 220px; background-color: #EFF1F7; box-sizing: border-box;">
			<router-view />
		</div> -->
	</div>
	
</template>

<script>
	import { mapState,mapMutations } from 'vuex'   //lhh
	import {
		request
	} from '../network/request.js'
	import {
		baseRoleList
	} from '../common/resource.js'

	export default {
		name: 'indexview',
		data() {
			return {
				ysName: '',
				itemTitle: '',
				roleList: [],
				selectedObj: {},
				newflag:false,    //lhh
			}
		},
		beforeCreate() {

		},

		created() {

			// this.getItemTitle(this.$store.state.tagsView.visitedViews);

			let userObj = this.$store.state.user.userObj;
			this.ysName = userObj.name;
			this.roleList = JSON.parse(JSON.stringify(baseRoleList))
			this.roleList=baseRoleList.filter(item=>{
				return item.roleName.indexOf(userObj.roleName)>=0
			})
			
		},
		computed: {   //lhh
		    ...mapState(["titleflag"]),
			...mapMutations(["changetitleflag"])
			},
		watch : {   //lhh
			titleflag(newdata,olddata){
				// console.log("修改title哈哈哈哈哈哈哈哈哈",this.titleflag)
				if(newdata){
					// var changedom = document.getElementById("changetitle");
					// changedom.innerHTML="CGM数据质量分析"
					this.handlechangetitleflag()
					// console.log("复位titleflag",this.titleflag)
					this.newflag=true    //lhh
				}
			}
		},
		methods: {
			handlechangetitleflag(){   //lhh
					return this.changetitleflag;
			},
			// 2022.11.29王昕卓修改接口
			getItemTitle1() {
				this.itemTitle = "数据质量分析";
				this.newflag=false   //lhh
			},
			getItemTitle2() {
				this.itemTitle = "患者信息管理";
				this.newflag=false   //lhh
			},
      getItemTitle3() {
        this.itemTitle = "空腹血糖异常";
        this.newflag=false   //lhh
      },
			
	  getItemTitle4() {
        this.itemTitle = "AGP图谱分析";
        this.newflag=false   //lhh
      },
			getItemTitle(item) {
				this.selectedObj = item;
				this.itemTitle = item.label;
				// this.newflag=false,  //lhh
				this.$store.dispatch("tagsView/addView", item.path)  //当操作行为中含有异步操作:比如向后台发送请求获取数据，就需要使用action的dispatch去完成了。其他使用commit即可。
			},

			// checkToken() {
			// 	return
			// 	request({
			// 		url: '/xuetangjiance/xuetangjiance/sys-doctor-user/webstatus',
			// 		method: 'get'
			// 	}, res => {
			// 		if (res.data.success) {

			// 		} else {
			// 			alert('您需要登录！')
			// 			this.$router.push('/')
			// 		}
			// 	}, err => {
			// 		console.log(err)
			// 	})
			// },
			// 吴佳琪2021-5-10 添加，requestdoctor函数 并修改接口，因为进入页面过快无法获得当时的cookie信息，所以利用直接请求。
			requestDoctor() {
				request({
					url: '/xuetangjiance/xuetangjiance/sys-doctor-user/webstatus',
					method: 'get'
				}, res => {
					let requestData = res.data.data
					let ysName = requestData.session
						.name /* 这里修改 吴佳琪2021.4.16 使名字与ID相同，2021-5-7吴佳琪，修改名字，将病人的名字进行获取*/
					this.ysName = ysName
				}, err => {
					console.log(err)
				})
			},

			// 退出登录 此处进行接口替换，利用success进行判断 吴佳琪2021-4-16
			logoutClick() {
				this.$confirm("您确定要退出吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(async () => {
					this.$router.replace('/');
					this.$message({
						type: "success",
						message: "退出成功!",
					});
				});


			}
		},
		mounted() {
			// this.checkToken();
			// this.requestDoctor();
		}
	}
</script>

<style scoped="scoped" lang="less">
	#indexview {
		height: 93vh;
		width:  100vw;
		// height: 100%;
		/* height: 930px; */
		// width:99%;
		// overflow-x: hidden;
		// background-image: radial-gradient(#fef8ef, #fcf4ec);
		// text-align: center;
		background-color: #EFF1F7;
// background-color: green;

	}

	#nav-index {
		padding: 0px;

		.layui-nav-side {
			// width: 220px;
			width: 13vw;
			background-color: #504da6;
			// box-sizing: border-box;
			 z-index: 999;

			//<--周浩辉修改start
			.layui-nav-title {
				display: block;
				width: 100%;
				margin-bottom: 30px;
				line-height: 100px;
				font-size:1.3rem;
				font-weight: normal;
				color: #FFFFFF;
				text-align: center;
			}

			.layui-nav-item {
				// width: 222px;
				margin-left: 0.7vw;

				a {
					// width: 200px;
					padding: 10px 0 10px 22px;
					font-size:1rem;
					color: #DCDBED;

					&:hover {
						background-color: #6057a6;
						border-top-left-radius: 7px;
						border-bottom-left-radius: 7px;
					}

					&.router-link-exact-active {
						background-color: #EFF1F7;
						color: #504DA6;
						border-top-left-radius: 7px;
						border-bottom-left-radius: 7px;
						// background: transparent  url(../assets/img/患者信息管理1.png) no-repeat center center;
						// background-size: 103% 114%;
					}
				}
			}

			.predictionIcon::before {
				font-family: "iconfont";
				content: "\e611";
				margin-right: 5px;
			}

			.cgmIcon::before {
				font-family: "iconfont";
				content: "\e600";
				margin-right: 5px;
			}

			.assesIcon::before {
				font-family: "iconfont";
				content: "\e6d5";
				margin-right: 5px;
			}

			.manageIcon::before {
				font-family: "iconfont";
				content: "\e602";
				margin-right: 5px;
			}

			.searchIcon::before {
				font-family: "iconfont";
				content: "\e601";
				margin-right: 5px;
			}

			.login-logout-style {
				/* 吴佳琪修改2021-04-14，注释掉top 加了三句，令欢迎xxx退出登录在最底下*/
				/* margin-top: 200px; */
				position: absolute;
				bottom: 0;
				left: 20%;
				/* 吴佳琪over*/
				font-weight: bold;
				color: #d2d2d2;
			}
		}

		.header {
			position: fixed;
			top: 0;
			right: 0;
			width: 100%;
			// height: 60px;
			height: 7vh;
			background-color: #FFFFFF;
			z-index: 20;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: nowrap;
			box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);

			.header_title {
				margin-left: 14vw;
				// margin-left: 240px;
				padding-left: 20px;
				// background-color: pink;
				box-sizing: border-box;
				border-left: 2px solid #504DA6;
				font-size: 20px;
				color: #707070;
			}

			.header_right {
				.icon-icon_bell {
					font-size: 20px;
					color: #919BAC;
					cursor: pointer;
				}

				.header_user {
					margin-right: 30px;
					font-size: 19px;
					color: #5E6A7A;
					cursor: pointer;

					.userImgStyle {
						width: 40px;
						height: 40px;
						margin-left: 20px;
						margin-right: 7px;
					}

					.sort_downImgStyle {
						margin-left: 13px;
						width: 13px;
						height: 6.5px;
					}

					.logoutImgStyle {
						margin-left: 13px;
						width: 25px;
						width: 25px;
					}
				}
			}
		}
	}
</style>

import {
	baseRoleList
} from '@/common/resource.js'
const VIEW_KEY = 'VIEW_key'
import router from '@/router'
import {
	Message
} from 'element-ui'

const state = {
	baseRoleList: baseRoleList,
	visitedViews: JSON.parse(localStorage.getItem(VIEW_KEY)) || baseRoleList[0],
}

const mutations = {
	ADD_VISITED_VIEW: (state, viewPath) => {
		
		let index = state.baseRoleList.findIndex(item => {
			return item.path == viewPath
		})

		if (index >= 0) {

			router.push(viewPath)
			state.visitedViews = baseRoleList[index]
			localStorage.setItem(VIEW_KEY, JSON.stringify(state.visitedViews));
		} else {

			Message({
				message: '页面地址信息错误!',
				type: 'error',
				duration: 5 * 1000
			})
		}

	},
}

const actions = {

	addView({
		commit
	}, view) {
		
		commit('ADD_VISITED_VIEW', view)
	},


}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}

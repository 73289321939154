<template>
    <!-- 控制评估那页下面的表格 -->
	<div>
    <el-table
        :data="tableData"
        class="eltableStyle"
        border
		:header-row-style="setHeaderRowStyle"
		:row-style="setRowStyle"
		:header-cell-style="setHeaderCellStyle"
		:cell-style="setCellStyle"
    >
        <el-table-column prop="pingguzhibiao" label="评估指标" align="center" fixed width="104">
        </el-table-column>

        <el-table-column prop="cgm_percent" label="CGM处于活动状态的时间百分比" align="center" width="180">
        </el-table-column>

        <el-table-column prop="address" label="血糖大概情况" align="center"> 
            <el-table-column label="平均血糖值" prop="mean_sugar" align="center"></el-table-column>
            <el-table-column label="夜间血糖平均值" prop="eve" align="center"></el-table-column>
            <el-table-column label="白天血糖平均值" prop="day" align="center"></el-table-column>
            <el-table-column label="中值绝对偏差" prop="" align="center"></el-table-column>
        </el-table-column>

        <el-table-column label="血糖范围评估" prop="" align="center">
            <el-table-column label="大于250mg/dl" prop="range250" align="center"></el-table-column>
            <el-table-column label="大于180mg/dl(TAR)" prop="range180" align="center"></el-table-column>
            <el-table-column label="70-180mg/dl(TIR)" prop="range70" align="center"></el-table-column>
            <el-table-column label="小于70mg/dl(TBR)" prop="range54" align="center"></el-table-column>
            <el-table-column label="小于54mg/dl的时间" prop="range0" align="center"></el-table-column>
        </el-table-column>

        <el-table-column label="低血糖事件(<70)评估" prop="" align="center">
            <el-table-column label="总次数" prop="low_sugar_times" align="center"></el-table-column>
            <el-table-column label="每天平均时间" prop="low_sugar_day_mean" align="center"></el-table-column>
            <el-table-column label="每天平均次数" prop="low_sugar_timesmean" align="center"></el-table-column>
            <el-table-column label="平均每次持续时间(min)" prop="lowSugar_meanTime" align="center"></el-table-column>
        </el-table-column>

        <el-table-column label="AUC" prop="" align="center">
            <el-table-column label="白天" prop="" align="center"></el-table-column>
            <el-table-column label="夜间" prop="" align="center"></el-table-column>
            <el-table-column label="全天" prop="" align="center"></el-table-column>
        </el-table-column>

        <el-table-column label="事件后血糖情况" prop="" align="center">
            <el-table-column label="餐食事件" prop="" align="center">
                <el-table-column label="餐前一小时平均血糖" prop="" align="center"></el-table-column>
                <el-table-column label="餐后一小时平均血糖" prop="" align="center"></el-table-column>
            </el-table-column>
            <el-table-column label="运动事件" prop="" align="center">
                <el-table-column label="运动后一小时平均血糖" prop="" align="center"></el-table-column>
                <el-table-column label="运动后两小时平均血糖" prop="" align="center"></el-table-column>
            </el-table-column>
        </el-table-column>

        <el-table-column label="血糖波动情况评估" prop="" align="center">
            <el-table-column label="高血糖指数(HBGI)" prop="" align="center"></el-table-column>
            <el-table-column label="底血糖指数(LBGI)" prop="" align="center"></el-table-column>
            <el-table-column label="日平均血糖风险范围(ADRR)" prop="" align="center"></el-table-column>
            <el-table-column label="血糖变异性系数(CV)" prop="" align="center"></el-table-column>
            <el-table-column label="平均血糖波动幅度(MAGE)" prop="" align="center"></el-table-column>
        </el-table-column>

        <el-table-column label="Q-score" prop="" align="center"></el-table-column>

        <el-table-column label="血糖评估糖尿病方程(GRADE)" prop="" align="center"></el-table-column>


    </el-table>
</div>

</template>

<script>
export default {
    props:{
        bl_Id: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            tableData: [
                {
                    pingguzhibiao: "值：",
                    mean_sugar: '',
                    cgm_percent: '',
                    eve: '',
                    day: '',
                    lowSugar_meanTime: '',
                    low_sugar_day_mean: '',
                    low_sugar_times: '',
                    low_sugar_timesmean: '',
                    range0: '',
                    range54: '',
                    range70: '',
                    range180: '',
                    range250: '',
                },
            ],
        };
    },

    watch: {
        bl_Id(newVal, oldVal) {
            // console.log('newVal = ', newVal)
            // console.log('oldVal = ', oldVal)
            this.get_tableData();

			// console.log('table_data', this.table_data)
        }
    },

    methods: {
        // 设置表头行的样式
		setHeaderRowStyle({row, rowIndex}) {
			switch (rowIndex) {
				case 0:
					return 'font-size: 16px; color: #504DA6;';
				default:
					return 'color: #333333';
			}
		},
        		
		// 设置表格行的样式
		// setRowStyle({row, rowIndex}) {
		// 	if(rowIndex == 0) {
		// 		return 'background-color: pink; font-size: 30px;';
		// 	}else {
		// 		return '';
		// 	}
		// },
        		
		// 设置表头单元格的样式
		setHeaderCellStyle({row, column, rowIndex, columnIndex}) {
			if (rowIndex == 0 && columnIndex == 0) {
				return 'background-color: #504DA6; font-size: 14px; color: #FFFFFF;';
			} else if(rowIndex == 0 && columnIndex == 1) {
				return 'font-size: 14px; color: #333333';
			} else if(rowIndex == 0 && columnIndex == 8) {
				return 'font-size: 14px; color: #333333';
			} else if(rowIndex == 0 && columnIndex == 9) {
				return 'font-size: 14px; color: #333333';
			} else {
				return '';
			}
		},
        		
		// 设置表格单元格的样式
		setCellStyle({row, column, rowIndex, columnIndex}) {
			if(rowIndex == 0 && columnIndex == 0){
				return 'background-color: #504DA6; font-size: 14px; color: #FFFFFF;';
			}
		},

        // 获取表格中的数据
        async get_tableData() {
            // console.log('触发get_tableData')
            // 1.计算CGM处于活动状态的时间百分比
            let res =  await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/cgmavtiveper/' + this.bl_Id
            )
            this.tableData[0].cgm_percent = res.data.res
            
            // 2.计算平均血糖值
            res = await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/cgmmean/' + this.bl_Id
            )
            this.tableData[0].mean_sugar = res.data.res



            // 3.计算白天夜晚雪糖平均值
            res = await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/getdifferentpartcgm/' + this.bl_Id
            )
            this.tableData[0].eve = res.data.eve
            this.tableData[0].day = res.data.day


            // 4.血糖范围评估
            res = await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/getcgmrange/' + this.bl_Id
            )
            let arr = Object.values(res.data)
            // console.log('arr', arr)
            this.tableData[0].range0 = arr[0]
            this.tableData[0].range54 = arr[1]
            this.tableData[0].range70 = arr[2]
            this.tableData[0].range180 = arr[3]
            this.tableData[0].range250 = arr[4].toFixed(2)  // 保留小数后两位



            // 5.低血糖事件评估
            res = await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/lowbloodsugar/' + this.bl_Id
            )
            this.tableData[0].low_sugar_day_mean = res.data.daymean
            this.tableData[0].low_sugar_times = res.data.times
            this.tableData[0].low_sugar_timesmean = res.data.timesmean
            

            // 6.低血糖每次平均时间
            res = await this.$myAjax(
                '/xuetangjiance/xuetangjiance/syscontrolassess/getdurationmean/' + this.bl_Id
            )
            this.tableData[0].lowSugar_meanTime = res.data.res

        }
    },
    

    mounted(){
        // console.log('child\'s mounted')
        // console.log('bl_Id = ', this.bl_Id)
    }
};
</script>

<style scoped="scoped" lang="less">
.eltableStyle {
	width: 100%;
	font-size: 14px;
	font-weight: normal;
	color: #333333;
}
</style>
<template>
	<div id="popupMask">
		<div class="popupContent">
			<div class="modifyTitleStyle">
				用户ID:{{patientID}}
			</div>
			<div class="modifyContentStyle">
				<div class="formbox">
					<div class="formtext">姓名</div>
					<div class="forminputBox"><input type="text" v-model="xm" class="forminput"/></div>
					<div class="formtext">性别:</div>
					<div class="forminputBox"><input type="text" v-model="xb" class="forminput"/></div>
					<div class="formtext">患病类型:</div>
					<div class="forminputBox"><input type="text" v-model="hblx" class="forminput"/></div>
					<div class="formtext">身高:</div>
					<div class="forminputBox"><input type="text" v-model="sg" class="forminput"/></div>
					<div class="formtext">体重:</div>
					<div class="forminputBox"><input type="text" v-model="tz" class="forminput"/></div>
					<div class="formtext">BMI:</div>
					<div class="forminputBox"><input type="text" v-model="bmi2" class="forminput"/></div>
					<div class="formtext">出生日期:</div>
					<div class="forminputBox"><input type="date" v-model="csnyr" class="forminput" style="margin-bottom: 10%;"/></div>
				</div>
				<div class="formbox">
					<div class="formtext">治疗方案:</div>
					<div class="forminputBox"><input type="text" v-model="zlfaxz" class="forminput"/></div>
					<div class="formtext">CGM类型:</div>
					<div class="forminputBox"><input type="text" v-model="cgmlx" class="forminput"/></div>
					<div class="formtext">控制要求:</div>
					<div class="forminputBox"><input type="text" v-model="xtkzyqxz" class="forminput"/></div>
					<div class="formtext">是否饮酒:</div>
					<div class="forminputBox"><input type="text" v-model="sfyj" class="forminput"/></div>
					<div class="formtext">并发症:</div>
					<div class="forminputBox"><input type="text" v-model="bfz" class="forminput"/></div>
					<div class="formtext">电话:</div>
					<div class="forminputBox"><input type="text" v-model="phone" class="forminput"/></div>
					<div class="formtext">确诊日期:</div>
					<div class="forminputBox"><input type="date" v-model="qzrq" class="forminput" style="margin-bottom: 10%;"/></div>
				</div>
			</div>
			<div class="modifyButtonStyle">
				<button @click="modifyData" class="srchbuttonm">确认</button>
				<button @click="goback" class="srchbuttonm">返回</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import { request } from '../network/request.js'
	export default{
		name:'modify',
		data(){
			return{
				patientID:'',
				xm:'',
				password:'',
				xb:'',
				hblx:'',
				sg:'',
				tz:'',
				bmi:'',
				zlfaxz:'',
				cgmlx:'',
				xtkzyqxz:'',
				sfyj:'',
				bfz:'',
				phone:'',
				qzrq:'2020-11-11',
				csnyr:'2020-11-11',
				rcyy:''
			}
		},
		computed:{
			bmi2: function () {
				if(this.bmi !== ''){
					return this.bmi
				}else{
					var data = this.tz / ((this.sg/100)*(this.sg/100))
					data = JSON.stringify(data)
					var result = '';
					for (var i = 0; i < data.length; i++) {
					  if (data[i] === '.') {
						result = result + data[i];
						result = result + data[i + 1];
						return result;
					  } else {
						result = result + data[i]
					  }
					}
					return result;		
				}
			}
		},
		methods:{
			getPatientID(){
				this.patientID = this.$route.params.id
			},
			//吴佳琪2021-5-8添加，令出生年月日与确诊日期进行显示。
			dateFormat(originaltime){
			  const date = new Date(originaltime)
			     var Y = date.getFullYear() + '-';
			     var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
				 var D2 = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
				 return (Y + M + D2)
			},
			patientdatarequest(){
				if(this.patientID){
					let response = ''
					request({
						url:'/xuetangjiance/xuetangjiance/sys-patient-user/cxbrxx/' + this.patientID,/* 修改新的接口框架，并将接口的数据地址改进 2021-4-16 吴佳琪*/
						method:'get'
					}, res => {
							// console.log(res.data.qzrq)
							// console.log(res.data.csnyr)
							this.xm = res.data.data.res.xm
							this.xb = res.data.data.res.xb
							this.hblx = res.data.data.res.hblx
							this.sg = res.data.data.res.sg
							this.tz = res.data.data.res.tz
							// this.bmi = res.data.bmi
							this.zlfaxz = res.data.data.res.zlfaxz
							this.cgmlx = res.data.data.res.cgmlx
							this.xtkzyqxz = res.data.data.res.xtkzyqxz
							this.sfyj = res.data.data.res.sfyj
							this.bfz = res.data.data.res.bfz
							this.phone = res.data.data.res.phone
							this.qzrq=this.dateFormat(res.data.data.res.qzrq)
							this.csnyr= this.dateFormat(res.data.data.res.csnyr)//吴佳琪2021-5-8 修改，进行年月日的修改，去除时分秒。
							this.rcyy = res.data.data.res.rcyy 
							this.password = res.data.data.res.passWord
							// for(var i=0;i<res.data.length;i++){
							// 	res.data[i].csnyr = this.timestap_ageCac(this.xlsdata[i].csnyr)
							// 	res.data[i].qzrq = this.timestap_bcCac(this.xlsdata[i].qzrq)
							// }

					},err => {
						console.log(err)
					})
					// this.res = response
					// this.span = this.res.zzsj
				}
				else{
					// console.log('未成功获取到病人ID')
				}
			},
			modifyData(){
				const url = '/xuetangjiance/xuetangjiance/sys-patient-user/updatagrxx'/*  修改新的接口框架，并将接口的数据地址改进 2021-4-16 吴佳琪*/
				// console.log(url)
				let chushengrq = new Date(this.csnyr)
				chushengrq = new Date(chushengrq)
				
				let quezhenrq = new Date(this.qzrq)
				quezhenrq = new Date(quezhenrq)
				
				let postdata = {
					"brID":this.patientID,
					"xm": this.xm,
					"passWord":this.password,
					"phone":this.phone,
					"csnyr":chushengrq.getTime(),
					"sg":this.sg,
					"tz":this.tz,
					"bmi":this.bmi2,
					"xb":this.xb,
					"hblx":this.hblx,
					"cgmlx":this.cgmlx,
					"qzrq":quezhenrq.getTime(),
					"zlfaxz":this.zlfaxz,
					"xtkzyqxz":this.xtkzyqxz,
					"sfyj": this.sfyj,
					"bfz": this.bfz,
					"rcyy": this.rcyy,
					//新加的变量
					 "jtzl": "否",
					 "zlfasfbh": "否",
				}
				// console.log('ppp')
				axios({
				       url,
				       method : 'post',
				       ContentType : 'application/json',
				       data : postdata
				    }).then(response => {
						// console.log(response.data)
						if(response.data.success === true){   /* 此处的获取值进行书写*/
							alert('修改成功，可前往用户查询页面查看')
						}
				    }).catch(error => {
				      console.log(error)
				    });
			},
			goback(){
				this.$router.push('/chart/manage') 
			}
		},
		mounted() {
			this.getPatientID();
			this.patientdatarequest();
		}
	}
</script>

<style scoped="scoped" lang="less">
	#popupMask {
		position: relative;
		top: 60px;
		// left: 13%;
		right: 0;
		bottom: 0;
		background-color: rgba(185, 185, 185, 0.8);
		z-index: 999;
		.popupContent {
			width: 80%;
			height: 1435px;
			margin: 110px auto;
			background-color: #FFFFFF;
			border: 1px solid #b9b9b9;
			.modifyTitleStyle {
				width: 100%;
				padding: 57px 0 39px 0;
				text-align: center;
				font-size: 36px;
				font-weight: bold;
				color: #504DA6;
			}
			.modifyContentStyle {
				text-align: center;
				.formbox{
					display: inline-block;
					width: 40%;
					text-align: left;
					font-size: 20px;
					color: #35384D;
					border: 1px solid #504DA6;
					box-sizing: border-box;
					&:nth-of-type(1) {
						border-right: none;
					}
					.formtext {
						height: 79px;
						padding-left: 27px;
						line-height: 79px;
						background-color: #EDEDF6;
					}
					.forminputBox{
						position: relative;
						height: 79px;
						background-color: #FAFAFA;
						.forminput {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translateX(-50%) translateY(-50%);
							width: 85%;
							height: 50px;
							padding-left: 15px;
							border: 1px solid #504DA6;
							border-radius: 8px;
						}
					}
				}
			}
			.modifyButtonStyle {
				margin-top: 29px;
				text-align: center;
				.srchbuttonm{
					width: 167px;
					height: 40px;
					margin-left: 29px;
					margin-bottom: 75px;
					background-color: #504da6;
					font-size: 20px;
					color: #FAFAFA;
					border: 1px solid #707070;
					border-radius: 17px;
					cursor: pointer;
					&:nth-of-type(2) {
						background-color: #FFFFFF;
						color: #35384D;
					}
				}
			}
		}
	}
</style>

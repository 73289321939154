 <template>
	<div id="app">
		<div style="width: 100%;">
			<router-view />
		</div>
	</div>
</template>
<script>
	//import HelloWorld from './components/HelloWorld.vue'

	export default {
		name: 'App',
		// 2023.3.22王昕卓修改监听
		created () {
			    // 在页面加载时读取sessionStorage
			    if (sessionStorage.getItem('store')) {
			      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
			    }
			    // 在页面刷新时将store保存到sessionStorage里
			    window.addEventListener('beforeunload', () => {
			      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			    })
			  },
		components: {
			// HelloWorld
		}
	}
</script>

<style lang="less">
	html,
	body,
	#app {
	}
	@import url("styles/link_Style.css");

	.tableBottomTool {
		margin: 30px 0px 40px 0px;
	}

	.intervalCondition-center {
		width: 100%;
		margin: 1.5vh 0 1.5vh 0;
		color: #8181A5;

		.textStyle1 {
			font-size: 16px;
			font-weight: bold;
			color: #504DA6;
		}

		.searchSelect {
			width: 150px;
			height: 30px;
			margin: 0 10px;
			padding-left: 8px;
			background: #F5F5FA;
			border: 1px solid #504DA6;
			border-radius: 4px;
			box-sizing: border-box;
			font-size: 14px;
			color: #8181A5;
			font-weight: normal;
		}

		.textStyle2 {
			color: #707070;
		}

		.queryResetButtonStyle {
			margin-right: 10px;
			width: 80px;
			height: 30px;
			background: #504DA6;
			border: 1px solid #504DA6;
			border-radius: 4px;
			font-size: 14px;
			font-weight: normal;
			color: #DCDBED;
			cursor: pointer;

			&:nth-of-type(3) {
				margin: 0 20px;
			}
		}
	}

	.tool-style {
		margin: 0vh 1vw 1.5vh 0px;

		.tool-ButtonStyle {
			padding: 0px 1vw 0px 1vw;
			margin-right: 10px;
			height: 40px;
			background-color: #504da6;
			font-size: 1rem;
			color: #FAFAFA;
			border-radius: 5px;
			// border: 1px solid #707070;
		}

	}

	//深度更改element下拉框样式
	::v-deep .el-input__inner {
		border: none;
		box-shadow: none;
		// background-color: red;
		background-color: rgba(220, 38, 38, 0);
	}

	// 弹窗start
	#popupMask {
		// display: none;
		position: fixed;
		top: 60px;
		left: 220px;

		right: 0;
		bottom: 0;
		background-color: rgba(185, 185, 185, 0.8);
		z-index: 99;

		.popupContent {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 80%;
			// height: 90%;
			// margin: 110px auto;
			background-color: #FFFFFF;
			border: 1px solid #b9b9b9;
			overflow-y: auto;

			.modifyTitleStyle {
				width: 100%;
				padding: 57px 0 39px 0;
				text-align: center;
				font-size: 36px;
				font-weight: bold;
				color: #504DA6;
			}


			.modifyButtonStyle {
				// margin-top: 29px;
				// text-align: center;

				.srchbuttonm {
					width: 167px;
					height: 40px;
					margin-left: 29px;
					// margin-bottom: 75px;
					background-color: #504da6;
					font-size: 20px;
					color: #FAFAFA;
					border: 1px solid #707070;
					border-radius: 17px;
					cursor: pointer;

					&:nth-of-type(2) {
						background-color: #FFFFFF;
						color: #35384D;
					}
				}
			}
		}
	}

	// 弹窗end
	
	.maincontain{
		margin-top: 7vh;
		 height: 92vh;
		width: 85vw;
		margin-left: 14vw;

			
	}

	// 工具栏2
	.chaxunPageSearchBanner {

		width: 100%;
		padding: 0 30px;
margin-top: 7vh;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;
		color: #504DA6;
		overflow: hidden;

		.inputConditions {
			width: 100%;
			height: 70px;

			.searchInputConditions {
				padding-top: 25px;
				font-size: 16px;
				font-weight: bold;

				.searchStyle {
					display: inline-block;
					box-sizing: border-box;
					border-radius: 15px;
					background-color: #EFF0F2;

					&::before {
						font-family: 'iconfont';
						content: '\e6d6';
						margin: 0 15px;
						font-size: 16px;
						font-weight: bold;
						color: #747678;
					}

					.searchInputStyle {
						width: 200px;
						height: 30px;
						border: none;
						background-color: #EFF0F2;
						font-size: 14px;
						font-weight: normal;
						color: #747678;
					}

					.searchButtonStyle {
						width: 70px;
						height: 30px;
						background: #504DA6;
						border: 1px solid #504DA6;
						border-radius: 0px 15px 15px 0px;
						font-size: 14px;
						font-weight: normal;
						color: #FFFFFF;
						cursor: pointer;
					}
				}
			}
		}

		.dashedStyle {
			width: 100%;
			height: 1px;
			background: linear-gradient(to right, #707070, #707070 7.5px, transparent 7.5px, transparent);
			background-size: 15px 100%;
		}

		.selectionConditions {
			width: 100%;
			margin: 18px 0 42px 0;

			&>div {
				font-size: 16px;
				font-weight: bold;
			}

			ul {
				width: 100%;
				// height: 120px;
				margin-top: 14px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				li {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: flex-start;
					width: 50%;

					.selectionTitleStyle {
						display: inline-block;
						width: 30%;
						margin: 8px 0;
						padding-right: 20px;
						text-align: right;
						box-sizing: border-box;
						font-weight: bold;
					}

					.selectionContentStyle {
						display: inline-block;
						width: 70%;
						margin: 8px 0;

						.checkboxStyle {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							-webkit-appearance: none;
							border: 1px solid #504DA6;
							border-radius: 4px;
							outline: none;
							cursor: pointer;

							&:checked {
								background-color: #504DA6;
							}

							&:checked::before {
								font-family: 'iconfont';
								content: '\e65d';
								color: #FFFFFF;
								font-size: 16px;
							}
						}

						label {
							margin-right: 33px;
							padding-left: 13px;
							color: #707070;
							cursor: pointer;

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}

		}


	}

	//table样式
	.chaxunPageArticalBanner {
		width: 100%;
margin-top: 1vh;
		padding: 10px 16px 10px 30px;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		box-sizing: border-box;

	}


	.modifyContentStyle {
		text-align: center;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		max-height: 500px;

		.formbox {
			display: inline-block;

			width: 40%;
			text-align: left;
			font-size: 20px;
			color: #35384D;
			border: 1px solid #504DA6;
			box-sizing: border-box;

			&:nth-of-type(1) {
				border-right: none;
			}

			.formtext {
				height: 60px;
				padding-left: 27px;
				line-height: 60px;
				background-color: #EDEDF6;
			}

			.forminputBox {
				position: relative;
				height: 79px;
				background-color: #FAFAFA;

				.forminput {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 85%;
					height: 50px;
					padding-left: 15px;
					border: 1px solid #504DA6;
					border-radius: 8px;
				}
			}

			.el-inputBox {
				position: relative;
				height: 60px;
				background-color: #FAFAFA;

				.el-input {
					position: absolute;
					left: 50%;
					top: 40px;

					// top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 85%;
					height: 40px;
					padding-left: 15px;
					border: 1px solid #504DA6;
					border-radius: 8px;
				}
			}

			.el-selectedBox {
				position: relative;
				height: 60px;
				background-color: #FAFAFA;

				.el-selected {
					position: absolute;
					left: 50%;
					top: 40px;

					// top: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 90%;
					height: 40px;

					border: 1px solid #504DA6;
					border-radius: 8px;
				}
			}
		}
	}

	#app {
		font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-x: hidden;
		// text-align: center;
	}

	#nav {
		padding: 0px;

		a {
			font-weight: bold;
			color: #d2d2d2;
			font-size: 20px;

			&.router-link-exact-active {
				color: #42b983;
				font-size: 23px;
			}
		}
	}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import axios from 'axios'
import signin from './Sign-in.vue'
// import {request} from './network/request'
import '../public/css/commen.css'
import '../public/fonts/iconfont.css'
// Vue.use(request)
// Vue.prototype.request = request;
Vue.prototype.$axios = axios;
axios.defaults.baseURL = '/'              // 设置axios的baseurltianjia


// 添加了两行代码，ajax---------------
import myAjax from '@/api/ajax.js'
Vue.prototype.$myAjax = myAjax

// 引入element-ui 添加了3行
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VCalendar from 'v-calendar';
// import 'v-calendar/lib/v-calendar.min.css';
Vue.use(VCalendar);

Vue.config.productionTip = false

new Vue({
	el:'#app',
	router:router,
	store,
	data: {
		TWOname: '张三丰', // 示例姓名
	},

	// axios:axios,
	render: h => h(App),
}).$mount('#app')

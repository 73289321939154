import axios from 'axios'
import Vuex from 'vuex'
import {Message,MessageBox} from 'element-ui'
import store from '@/store'
import router from '@/router'
import {getToken} from '@/utils/auth'

//创建一个AXIOS实例
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,  // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests跨域请求时发送cookies
	// timeout: 60000 // request timeout请求超时
	timeout: 600000000 // request timeout请求超时
})

// request interceptor 请求拦截器
service.interceptors.request.use(
	config => {
		// console.log("store.getters.token", store.getters.token);
		config.headers['Authorization'] = `Bearer ${getToken()}`
		// if (store.getters.token) {
		// 	config.headers['Authorization'] = `Bearer ${getToken()}`
		// 	// config.headers['Access-Control-Allow-Origin'] = `*`
		// }
		return config
	},
	error => {

		// do something with request error处理请求错误
		console.log(error) // for debug
		return Promise.reject(error)
	}
)
// response interceptor 响应拦截器
service.interceptors.response.use(
	response => {
		const res = response.data
		// 2023.6.7wxz注释
		// console.log(response)
		if (response.status == 200) {
			return res;
		}

		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 0) {
			Message({
				message: res.message || 'Error',
				type: 'error,code值不匹配',
				duration: 5 * 1000
			})

			// 50008: Illegal token非法token; 50012: Other clients logged in其他客户端登录; 50014: Token expired过期;
			if (res.code === 500080 || res.code === 500120 || res.code === 500140) {
				// to re-login
				var r = confirm("You have been logged out, you can cancel to stay on this page, or log in again");
				if (r == true) {
					store.dispatch('user/resetToken').then(() => {
						location.reload()
					})
				} else {

				}

			}
			return Promise.reject(new Error(res.message || 'Error'))
		} else {
			return res
		}
	},
	error => {

		// 2023.6.7wxz注释
		// console.log('err' + error) // for debug
		if (error.response) {
			let res = error.response
			if (res.status === 401) {
				// Message({
				// 	message:'登录过期请重新登录',
				// 	type: 'error',
				// 	duration: 3 * 1000
				// })

				// 	store.dispatch('user/logout').then(() => {
				// 		router.replace('/');
				// 	})
				MessageBox.confirm('登录过期请重新登录', '提示', {
					confirmButtonText: '重新登录',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					store.dispatch('user/logout').then(() => {
						router.replace('/');
					})
					Promise.reject(error)
				}).catch(() => {
					console.log('取消');
					Promise.reject(error)
				});
			} else if (res.status === 400) {
				Message({
					message: res.msg || '客户端错误',
					type: 'error',
					duration: 3 * 1000
				})
			} else if (res.status === 403) {
				Message({
					message: res.msg || '您的权限不足',
					type: 'error',
					duration: 3 * 1000
				})
			} else if (res.status === 404) {
				Message({
					message: '网络请求不存在',
					type: 'error',
					duration: 3 * 1000
				})
			} else if (res.status === 404) {
				Message({
					message: '网络请求出错',
					type: 'error',
					duration: 3 * 1000
				})
			} else if (res.status === 500) {

				Message({
					message: res.msg || '服务器异常',
					type: 'error',
					duration: 3 * 1000
				})
			} else if (res.status === 501) {
				Message({
					message: res.msg || '您的操作被取消或不允许提交',
					type: 'warning',
					duration: 3 * 1000
				})
			} else {
				Message({
					message: '服务器正在开小差。。。。',
					type: 'error',
					duration: 3 * 1000
				})
			}
		} else if (error.message.includes('timeout')) {
			Message({
				message: '请求超时，请检查网络连接!',
				type: 'error',
				duration: 3 * 1000
			})
		}
		return Promise.reject(error)
	}
)

export default service
